import React, { useState, useEffect, useMemo, createContext, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { useMutation, useLazyQuery, gql, useSubscription } from '@apollo/client';
import localStorageService from '../../../services/localStorageService';
import axios from 'axios';

import {
    updateOrdersTableInUseToRight,
    updateOrdersTableInUseToLeft,
    insertRoute,
    deleteOrderInVehicleTemp,
    updateSortPositionOrdersInVehicleTemp,
    deleteOrdersTableInUseToLeft,
    updateRouteIfError,
    subscriptionOrdersInVehicleTempCreateByUserId,
    subscriptionFetchOrders,
    updateOrdeLineOrdersInVehicleTemp,
    fetchUsersVehicles
} from '../queries/schedulingQueries';

import loggerCooltrack from '../../../services/logger-cooltrack';
const SchedulingContext = createContext();


export const SchedulingProvider = (props) => {
    const [fetching, setFetching] = useState(false);
    const [user, setUser] = useState({});
    const [usersVehicles, setUsersVehicles] = useState([]);
    const [ordersInVehicleTemp, setOrdersInVehicleTemp] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedUserVehicle, setSelectedUserVehicle] = useState({});
    const [selectedRightRow, setSelectedRightRow] = useState([]);
    const [selectedLeftRow, setSelectedLeftRow] = useState([]);
    const [toLeftButtonDisabled, setToLeftButtonDisabled] = useState(true);
    const [toRightButtonDisabled, setToRightButtonDisabled] = useState(true);
    const [optimalRoute] = useState([]);
    const [optimalRouteQuery, setOptimalRouteQuery] = useState();
    const [selectedWarehouse, setSelectedWarehouse] = useState(
        JSON.parse(localStorageService.get(
          "userDistributionsCenters"
        ))
      );
    const [flag, setFlag] = useState(0);
    

    //const [getOrdersInVehicleTemp, dataOrdersInVehicleTemp] = useLazyQuery(subscriptionOrdersInVehicleTempCreateByUserId, { fetchPolicy: 'no-cache' });
    // [getOrders, dataOrders] = useLazyQuery(fetchOrders, { fetchPolicy: 'no-cache' });
    const dataOrdersInVehicleTemp = useSubscription(subscriptionOrdersInVehicleTempCreateByUserId, { fetchPolicy: 'no-cache'});
    let dataOrders = useSubscription(subscriptionFetchOrders, {variables: {distributionCenterId: selectedWarehouse?.length > 0 ? selectedWarehouse : ["00000000-0000-0000-0000-000000000000"]}, fetchPolicy: 'no-cache'});
    

    
    const [updateOrdersInUseToRight] = useMutation(updateOrdersTableInUseToRight, { fetchPolicy: 'no-cache' });
    const [updateOrdersInUseToLeft] = useMutation(updateOrdersTableInUseToLeft, { fetchPolicy: 'no-cache' });
    const [updateOrdersLine] = useMutation(updateOrdeLineOrdersInVehicleTemp, { fetchPolicy: 'no-cache' });
    const [deleteOrdersInUseToLeft] = useMutation(deleteOrdersTableInUseToLeft, { fetchPolicy: 'no-cache' });
    const [addRoute] = useMutation(insertRoute, { fetchPolicy: 'no-cache' });
    const [removeOrderInVehicleTemp] = useMutation(deleteOrderInVehicleTemp, { fetchPolicy: 'no-cache' });
    const [updRouteIfError] = useMutation(updateRouteIfError, { fetchPolicy: 'no-cache' });
    const [getOptimalRoute, dataOptimalRoute] = useLazyQuery(optimalRouteQuery ? optimalRouteQuery : gql`query tsp { tsp(locations: [{x:0,y:0,id:"0"}]) { optimalRoute } }`, { fetchPolicy: 'no-cache' });

    const [updateSortPosition] = useMutation(updateSortPositionOrdersInVehicleTemp, { fetchPolicy: 'no-cache' });

    useEffect(() => {
        const usr = JSON.parse(sessionStorage.getItem('userData'));
        setUser(usr);
    }, [fetching]);

    useEffect(() => {
        const warehouses = JSON.parse(localStorageService.get("selectedWarehouses"))?.warehouses;
        setSelectedWarehouse(warehouses);
    }, [flag]);

    useEffect(() => {
        if (Object.keys(selectedUserVehicle).length > 0) {
            setOrdersInVehicleTemp([]);
            if (dataOrdersInVehicleTemp.data && dataOrdersInVehicleTemp.data.ordersInVehicleTemp.length > 0) {
                let records = [];
                
                for (let i = 0; i < dataOrdersInVehicleTemp.data.ordersInVehicleTemp.length; i++) {
                    const item = dataOrdersInVehicleTemp.data.ordersInVehicleTemp[i];
                    if(item && item.order && item.userId === selectedUserVehicle.userId){
                        records.push({
                            key: item?.id,
                            id: item?.id,
                            createByUserId: item?.createByUserId,
                            orderTableId: item?.orderTableId,
                            vehicleId: item?.vehicleId,
                            licencePlate: item?.vehicle?.licencePlate,
                            orderNumber: item?.order?.orderNumber,
                            color: item?.order?.sector?.color,
                            sector: item?.order?.sector?.name,
                            destination: item?.order?.destination,
                            address: item?.order?.address,
                            addressComplement: item?.order?.addressComplement,
                            orderTotalCubicMeters: item?.order?.totalCubicMeters,
                            orderWeight: item?.order?.weight,
                            orderEnableDeliveryWindow: item?.order?.enableDeliveryWindow,
                            orderExpectedDeliveryStartTime: item?.order?.expectedDeliveryStartTime,
                            orderExpectedDeliveryEndTime: item?.order?.expectedDeliveryEndTime,
                            custFullName: item?.order?.custFullName,
                            custPhoneNumber: item?.order?.custPhoneNumber,
                            custIdentificationNumber: item?.order?.custIdentificationNumber,
                            custEmail: item?.order?.custEmail,
                            priority: item?.order?.priority,
                            consecutiveBill: item?.order?.consecutiveBill,
                            consecutiveRemission: item?.order?.consecutiveRemission,
                            consecutiveSaleOrder: item?.order?.consecutiveSaleOrder,
                            ordersLines: item?.order?.ordersLines,
                            route: item?.order?.route && item?.order?.route?.status ? item?.order?.route?.status : 'none',
                            statusRoute: item?.order?.route?.status === "partial_delivery" ? "partial_delivery" : 'none'
                        });
                    }
                };

                setOrdersInVehicleTemp(records);
            }
        }
    }, [dataOrdersInVehicleTemp.data, selectedUserVehicle]);

    useEffect(() => {
        if (dataOrders.data) {
            console.log(dataOrders.data.ordersTable)
            let records = [];
            setOrders([]);
            dataOrders.data.ordersTable.map((item) => {
                if(item.onSiteDelivery === null || item.onSiteDelivery === "partial_delivered"){
                    return records.push({
                        key: item.id,
                        id: item.id,
                        color: item.sector.color,
                        sectorId: item.sectorId,
                        orderNumber: item.orderNumber,
                        address: item.address,
                        addressComplement: item.addressComplement,
                        sector: item.sector.name,
                        destination: item.destination,
                        custFullName: item.custFullName,
                        custPhoneNumber: item.custPhoneNumber,
                        custEmail:item.custEmail,
                        custIdentificationNumber: item.custIdentificationNumber,
                        totalCubicMeters: item.totalCubicMeters,
                        weight: item.weight,
                        enableDeliveryWindow: item.enableDeliveryWindow,
                        expectedDeliveryStartTime: item.expectedDeliveryStartTime,
                        expectedDeliveryEndTime: item.expectedDeliveryEndTime,
                        priority: item.priority, 
                        type: item.type,
                        consecutiveBill: item.consecutiveBill,
                        consecutiveRemission: item.consecutiveRemission,
                        consecutiveSaleOrder: item.consecutiveSaleOrder,
                        consecutiveBurden: item.consecutiveBurden,
                        distributionCenter: item.distributionCenter?.name,
                        distributionCenterid: item.distributionCenter?.id,
                        ordersLines: item.ordersLines,
                        routeComplet: item.route,
                        consecutiveShipping: item.consecutiveShipping,
                        paymentMethod: item.paymentMethod,
                        route: item.route === null ? "none" : item.route.status === "rescheduled_delivery" ? "rescheduled_delivery": item.route.status === "partial_delivered" ? "partial_delivered": "" ,
                    });
                }
            });
            setOrders(records);
          
        }
    }, [dataOrders.data]);

    useEffect(() => {
        if (!dataOptimalRoute.loading && dataOptimalRoute.data && dataOptimalRoute.data.tsp.optimalRoute.length > 0) {
            let records = [];
            let i = 0;

            // eslint-disable-next-line
            dataOptimalRoute.data.tsp.optimalRoute.map((item) => {

                const record = ordersInVehicleTemp.filter((o) => o.id === item.id);

                if (record.length > 0) {
                    records.push({
                        key: record[0].id,
                        id: record[0].id,
                        createByUserId: record[0].createByUserId,
                        orderTableId: record[0].orderTableId,
                        vehicleId: record[0].vehicleId,
                        licencePlate: record[0].licencePlate,
                        orderNumber: record[0].orderNumber,
                        color: record[0].color,
                        destination: record[0].destination,
                        address: record[0].address,
                        addressComplement: record[0].addressComplement,
                        orderTotalCubicMeters: record[0].orderTotalCubicMeters,
                        orderWeight: record[0].orderWeight,
                        orderEnableDeliveryWindow: record[0].orderEnableDeliveryWindow,
                        orderExpectedDeliveryStartTime: record[0].orderExpectedDeliveryStartTime,
                        orderExpectedDeliveryEndTime: record[0].orderExpectedDeliveryEndTime,
                        priority: record[0].priority,

                    });
                    delaySort(i, record[0]);
                    i++;
                }
            });
            setOrdersInVehicleTemp(records);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataOptimalRoute]);

    const delaySort = (i, item) => {
        setTimeout(async () => {
            await updateSortPosition({
                variables: {
                    id: item.id,
                    orderPosition: new Date()
                }
            });
        }, i * 10);
    };

    const onClickToRightButton = async (e) => {
        setFetching(true);

        setToRightButtonDisabled(true);

        for (const item of selectedLeftRow) {
            
            try {
                const res = await updateOrdersInUseToRight({
                    variables: {
                        id: item.orderTableId,
                        inUse: false,
                        ordersInVehicleTempId: item.id
                    },
                    fetchPolicy: 'no-cache',
                });

                if (res.data.update_ordersTable.affected_rows > 0 && res.data.delete_ordersInVehicleTemp.affected_rows > 0) {
                    setOrdersInVehicleTemp((ordersInVehicleTemp) => ordersInVehicleTemp.filter((order) => order.key !== item.key), ...ordersInVehicleTemp);
                }
            } catch (error) {
                console.error(error);
            }

        }
        setFetching(false);
    };
    const onClickDeleteToLeftButton = (e) => {
        setFetching(true);
        setToLeftButtonDisabled(true);

        selectedRightRow.map(async (item) => {
            
            try {
                const res = await deleteOrdersInUseToLeft({
                    variables: {
                        id: item.id,
                    },
                    fetchPolicy: 'no-cache',
                });            

            } catch (error) {
                console.error(error);
            }
        });
        setFetching(false);
    };


    const onItemToRightButton = async (item) => {
        setFetching(true);
    
            try {
                const res = await updateOrdersInUseToRight({
                    variables: {
                        id: item.orderTableId,
                        inUse: false,
                        ordersInVehicleTempId: item.id
                    },
                    fetchPolicy: 'no-cache',
                });

                if (res.data.update_ordersTable.affected_rows > 0 && res.data.delete_ordersInVehicleTemp.affected_rows > 0) {
                    setOrdersInVehicleTemp((ordersInVehicleTemp) => ordersInVehicleTemp.filter((order) => order.key !== item.key), ...ordersInVehicleTemp);

                }
            } catch (error) {
                console.error(error);
            }

        
        setFetching(false);
    };

    const onClickToLeftButton = (e) => {
        setFetching(true);
        setToLeftButtonDisabled(true);

        selectedRightRow.map(async (item) => {
            try {
                const res = await updateOrdersInUseToLeft({
                    variables: {
                        id: item.id,
                        inUse: true,
                        createByUserId: user.id,
                        orderTableId: item.id,
                        userId: selectedUserVehicle.userId,
                        vehicleId: selectedUserVehicle.vehicleId,
                        orderPosition: new Date()
                    },
                    fetchPolicy: 'no-cache',
                });
                
                if (res.data.update_ordersTable.affected_rows > 0 && res.data.insert_ordersInVehicleTemp.affected_rows > 0) {
                    setOrders((orders) => orders.filter((order) => order.key !== item.key), ...orders);
                    
                }

            } catch (error) {
                console.error(error);
            }
        });
        setFetching(false);
    };

    const onItemToLeftButton = async (item) => {
        setFetching(true);
            
            try {
                const res = await updateOrdersInUseToLeft({
                    variables: {
                        id: item.id,
                        inUse: true,
                        createByUserId: user.id,
                        orderTableId: item.id,
                        userId: selectedUserVehicle.userId,
                        vehicleId: selectedUserVehicle.vehicleId,
                        orderPosition: new Date()
                    },
                    fetchPolicy: 'no-cache',
                });

                if (res.data.update_ordersTable.affected_rows > 0 && res.data.insert_ordersInVehicleTemp.affected_rows > 0) {
                    setOrders((orders) => orders.filter((order) => order.key !== item.key), ...orders);
                    
                }

            } catch (error) {
                console.error(error);
            }
        setFetching(false);
    };

    const onInsertRoute = (callback) => {
        let resultOK = false;

        setFetching(true);
        const batch = uuidv4();

        Swal.fire({
            title: '¿Seguro desea cerrar la ruta?',
            text: "Este proceso es irreversible!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--primaryColor)',
            cancelButtonColor: '#B6B6B6',
            confirmButtonText: 'Sí, cerrar ruta',
            cancelButtonText: 'No, cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let routeOrder = 0;
                const { userId } = selectedUserVehicle;

                resultOK = true;

                ordersInVehicleTemp.map(async (item) => {
                    const { id, orderTableId, createByUserId } = item;

                    routeOrder = routeOrder + 1;

                    // al insertar una ruta (routes) se dispara también un trigger (trigger_add_route_process) que 
                    // llama a la postgre function trigger_on_add_route_process() que realiza otras actualizaciones a otras tablas.
                    addRoute({
                        variables: {
                            userId,
                            batch,
                            routeOrder,
                            orderTableId,
                            createByUserId,
                            orderInVehicleTempId: id
                        },
                        fetchPolicy: 'no-cache',
                    }).catch((error) => {
                        resultOK = false;
                        console.error(error);
                    });
                    if(item.route === "partial_delivered"){
                        for (let i = 0; i < item.ordersLines.length; i++) {
                            const element = item.ordersLines[i];
                            
                            const orderQuantityModifid = element.orderedQuantity - element.deliveredQuantity;
                            //const summationQuantityModifid = parseInt(element.summationQuantity) + parseInt(element.deliveredQuantity);
                            updateOrdersLine({
                                variables: {
                                    id:element.id,
                                    orderedQuantity: orderQuantityModifid,
                                    deliveredQuantity: 0
                                },
                                fetchPolicy: 'no-cache',
                            }).catch((error) => {
                                console.error(error);
                            });
                        }
                       
                    }
                    
                });

                if (resultOK) {
                    const { userId, userVehicleId, licencePlate } = selectedUserVehicle;

                    ordersInVehicleTemp.map(async (item) => {
                        const { id } = item;

                        removeOrderInVehicleTemp({
                            variables: {
                                id,
                                batch,
                                userId,
                                licencePlate,
                                userVehicleId
                            }
                        }).then((res) => {
                            
                            if (item.custEmail !== '') {
                                const { custFullName, address, addressComplement, custIdentificationNumber, custEmail, orderNumber, priority } = item;

                                const companyBrand = JSON.parse(localStorageService.get('companyDetails'));

                                axios({
                                    method: 'post',
                                    url: process.env.REACT_APP_FUNCTION_TRACKING_ORDER_URL,
                                    data: {
                                        id,
                                        email: custEmail,
                                        displayName: custFullName,
                                        address,
                                        addressComplement,
                                        custIdentificationNumber,
                                        orderNumber,
                                        priority,
                                        origin: window.location.origin,
                                        bigBrand: companyBrand.bigBrand,
                                        companyName: localStorageService.get('company'),
                                        primaryColor: companyBrand.primaryColor
                                    }
                                }).then((res) => {
                                    if (res.status !== 200) {
                                        console.error(`Error al enviar email a ${custEmail}. ${res}`)
                                    }

                                    callback(selectedUserVehicle);
                                    setOrdersInVehicleTemp((ordersInVehicleTemp) => ordersInVehicleTemp.filter((order) => order.key !== item.key), ...ordersInVehicleTemp);

                                }).catch(error => {
                                    console.error(`Error al enviar email a ${custEmail}. ${error}`);

                                    callback(selectedUserVehicle);
                                    setOrdersInVehicleTemp((ordersInVehicleTemp) => ordersInVehicleTemp.filter((order) => order.key !== item.key), ...ordersInVehicleTemp);

                                });
                            }

                        }).catch((error) => {
                            console.error(error)
                        });
                    });

                    
                    let mUsersVehicles = usersVehicles.map(item => item);
                   

                    for (let i = 0; i < mUsersVehicles.length; i++) {
                        const element = mUsersVehicles[i];
                        
                        if(element.userVehicleId === userVehicleId){
                            mUsersVehicles.splice(i, 1);
                            break;
                        }
                    }
                    
                    Swal.fire({
                        title: 'Ruta cerrada!',
                        text: 'La ruta ha sido cerrada satisfactoriamente',
                        icon: 'success',
                        confirmButtonText: 'Cerrar'
                    });
                    loggerCooltrack({
                        module: "Scheduling",
                        operation: "RouteClosed",
                        target: userVehicleId,
                    });
                    //El user vehicle pues está en UserVehicle y es el ID del conductor, ese es el target
                    setSelectedUserVehicle({});
                    setUsersVehicles(mUsersVehicles);
                } else {
                    ordersInVehicleTemp.map(async (item) => {
                        const { id, orderTableId } = item;

                        updRouteIfError({
                            variables: {
                                orderInVehicleTempId: id,
                                batch,
                                orderTableId
                            }
                        }).then(() => { });
                    });

                    Swal.fire({
                        title: 'Ocurrió un error inesperado!',
                        text: 'Error inesperado al intentar enviar la ruta',
                        icon: 'error',
                        confirmButtonText: 'Cerrar'
                    });
                }
            }

            setFetching(false);
        });
    };

    const onCalcRoute = () => {
        let strLocations = '';

        if (Object.keys(selectedUserVehicle).length > 0) {
            const latLng = selectedUserVehicle.schedulingStartLocation.split(',');

            if (latLng.length === 2) {
                strLocations = `{x: "${latLng[0]}", y: "${latLng[1]}", id: "${selectedUserVehicle.key}"}\r`;
            }
        }

        // eslint-disable-next-line
        ordersInVehicleTemp.map((item) => {
            strLocations = strLocations + `{x: "${item.destination.split(',')[0]}", y: "${item.destination.split(',')[1]}", id: "${item.id}"}\r`;
        });

        const query = strLocations ? `query tsp { tsp(locations: [${strLocations}]) { optimalRoute } }` : `query tsp { tsp(locations: [{x:0,y:0,id:"0"}]) { optimalRoute } }`       

        setOptimalRouteQuery(
            gql`${query}`
        );

        getOptimalRoute();
    }

    const value = useMemo(() => {
        return ({
            fetching,
            setFetching,
            usersVehicles,
            setUsersVehicles,
            ordersInVehicleTemp,
            setOrdersInVehicleTemp,
            orders,
            setOrders,
            selectedUserVehicle,
            setSelectedUserVehicle,
            selectedRightRow,
            setSelectedRightRow,
            setSelectedLeftRow,
            toLeftButtonDisabled,
            toRightButtonDisabled,
            onClickToRightButton,
            onClickToLeftButton,
            onClickDeleteToLeftButton,
            onInsertRoute,
            dataOrders,
            dataOrdersInVehicleTemp,
            setToLeftButtonDisabled,
            setToRightButtonDisabled,
            onCalcRoute,
            optimalRoute,
            selectedLeftRow,
            onItemToLeftButton,
            onItemToRightButton,
            setFlag,
            selectedWarehouse
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetching, usersVehicles, ordersInVehicleTemp, orders, selectedUserVehicle, selectedRightRow, toLeftButtonDisabled, toRightButtonDisabled, dataOrders, optimalRoute, selectedLeftRow, setFlag]);

    return <SchedulingContext.Provider value={value} {...props} />;
};

export const useScheduling = () => {
    const context = useContext(SchedulingContext);

    if (!context) {
        throw new Error('useScheduling debe estar dentro del proveedor SchedulingContext.');
    }

    return context;
}

import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Switch,
  Descriptions,
  Input,
  Card,
  Button,
  Space,
  Skeleton,
  Tooltip,
  List,
  Col,
  Row,
  Modal,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  FieldTimeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import {
  CarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import localStorageService from "../../../../../services/localStorageService";

import {
  fetchTransportCompanies,
  insertTransportCompany,
  updateTransportCompany,
  deleteTransportCompany,
  fetchTimetables,
} from "../../queries/transportCompaniesQueries";
import { ConfigurationMoreSettings } from "../../../ConfigurationMoreSettings";

import { Timetables } from "./Timetables";

import "../styles/transportCompanies.css";

import { TransportCompaniesIndicator } from "./TransportCompaniesIndicator";

const companyId = localStorageService.get("companyId");

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const getColumnSearchPropsText = (title, dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Buscar ${title}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Restablecer
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : "",
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      /*if(Object.keys(searchText).length > 0) {
                setTimeout(() => searchInput.select(), 100);
            }*/
    }
  },
});

export const TransportCompaniesScreen = () => {
  const client = useApolloClient();

  const [getTransportCompanies, dataTransportCompanies] = useLazyQuery(
    fetchTransportCompanies,
    { variables: { companyId }, fetchPolicy: "no-cache" }
  );
  const [insTransportCompany] = useMutation(insertTransportCompany, {
    variables: { companyId },
    fetchPolicy: "no-cache",
  });
  const [updTransportCompany] = useMutation(updateTransportCompany, {
    fetchPolicy: "no-cache",
  });
  const [delTransportCompany] = useMutation(deleteTransportCompany, {
    fetchPolicy: "no-cache",
  });

  const [transportCompanies, setTransportCompanies] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [timetables, setTimetables] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [transportCompanyId, setTransportCompanyId] = useState("");
  const [clickedRow, setClickedRow] = useState("");

  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getTransportCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTransportCompanies([]);

    if (
      !dataTransportCompanies.loading &&
      dataTransportCompanies.data &&
      dataTransportCompanies.data.transportCompanies.length > 0
    ) {
      dataTransportCompanies.data.transportCompanies.map((item) => {
        const deleteable = item.vehicles_aggregate.aggregate.count === 0;
        const {
          id,
          nit,
          name,
          phoneNumber,
          cellPhoneNumber,
          email,
          address,
          contactName,
          ground,
          maritime,
          air,
          enabled,
          timetables,
        } = item;

        return setTransportCompanies((current) => [
          ...current,
          {
            id,
            nit,
            name,
            phoneNumber: phoneNumber ?? "",
            cellPhoneNumber: cellPhoneNumber ?? "",
            email: email ?? "",
            address: address ?? "",
            contactName: contactName ?? "",
            ground,
            maritime,
            air,
            enabled,
            deleteable,
            timetables,
          },
        ]);
      });
    }
  }, [dataTransportCompanies.loading, dataTransportCompanies.data]);

  const columns = [
    {
      title: "NIT",
      key: "nit",
      dataIndex: "nit",
      sorter: (a, b) => a.nit.localeCompare(b.nit),
      ...getColumnSearchPropsText("NIT", "nit"),
    },
    {
      title: "Nombre",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchPropsText("Nombre", "name"),
    },
    {
      title: "Teléfono",
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      ...getColumnSearchPropsText("Teléfono", "phoneNumber"),
    },
    {
      title: "Celular",
      key: "cellPhoneNumber",
      dataIndex: "cellPhoneNumber",
      sorter: (a, b) => a.cellPhoneNumber.localeCompare(b.cellPhoneNumber),
      ...getColumnSearchPropsText("Celular", "cellPhoneNumber"),
    },
    {
      title: "Correo electrónico",
      key: "email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...getColumnSearchPropsText("Email", "email"),
    },
    /*{
            title: 'Dirección',
            key: 'address',
            dataIndex: 'address',
            sorter: (a, b) => a.address.localeCompare(b.address),
            ...getColumnSearchPropsText("Dirección", "address"),
            responsive: ['xl'],
        },*/
    {
      title: "Contacto",
      key: "contactName",
      dataIndex: "contactName",
      sorter: (a, b) => a.contactName.localeCompare(b.contactName),
      ...getColumnSearchPropsText("Contacto", "contactName"),
      responsive: ["xl"],
    },
    {
      title: "Terrestre",
      key: "ground",
      dataIndex: "ground",
      align: "center",
      sorter: {
        compare: (a, b) => a.ground - b.ground,
      },
      responsive: ["xl"],
      render: (value) => <Switch checked={value} disabled size="small" />,
    },
    {
      title: "Habilitado",
      key: "enabled",
      dataIndex: "enabled",
      sorter: {
        compare: (a, b) => a.enabled - b.enabled,
      },
      align: "center",
      render: (value) => <Switch checked={value} disabled size="small" />,
    },
  ];

  const handleSave = async (values) => {
    const {
      id,
      nit,
      name,
      phoneNumber,
      cellPhoneNumber,
      email,
      address,
      contactName,
      ground,
      maritime,
      air,
      enabled,
      deleteable,
      timetables,
    } = values;

    try {
      if (!isEditing) {
        const res = await insTransportCompany({
          variables: {
            nit,
            name,
            phoneNumber,
            cellPhoneNumber,
            email,
            address,
            contactName,
            ground,
            maritime,
            air,
            enabled,
          },
        });

        if (res.data.insert_transportCompanies.affected_rows > 0) {
          const { id } = res.data.insert_transportCompanies.returning[0];

          setTransportCompanies((current) => [
            ...current,
            {
              id,
              nit,
              name,
              phoneNumber,
              cellPhoneNumber,
              email,
              address,
              contactName,
              ground,
              maritime,
              air,
              enabled,
              deleteable: true,
              timetables: [],
            },
          ]);
        }
      } else {
        const res = await updTransportCompany({
          variables: {
            id,
            nit,
            name,
            phoneNumber,
            cellPhoneNumber,
            email,
            address,
            contactName,
            ground,
            maritime,
            air,
            enabled,
          },
        });

        if (res.data.update_transportCompanies.affected_rows > 0) {
          const records = transportCompanies;
          const indexToReplace = transportCompanies.findIndex(
            (item) => item.id === id
          );

          records.splice(indexToReplace, 1, {
            id,
            nit,
            name,
            phoneNumber,
            cellPhoneNumber,
            email,
            address,
            contactName,
            ground,
            maritime,
            air,
            enabled,
            deleteable,
            timetables,
          });
          setTransportCompanies([...records]);
        }
      }
    } catch (error) {
      console.error(error);
    }

    setIsEditing(false);

    form.setFieldsValue({
      id: "",
      nit: "",
      name: "",
      phoneNumber: "",
      cellPhoneNumber: "",
      email: "",
      address: "",
      contactName: "",
      ground: true,
      maritime: false,
      air: false,
      enabled: true,
      deleteable: true,
      timetables: [],
    });
  };

  const handleDelete = (record) => {
    const id = record;

    Swal.fire({
      title: "¿Seguro desea eliminar?",
      text: "Este proceso es irreversible!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "var(--primaryColor)",
      cancelButtonColor: "#B6B6B6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "No, cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await delTransportCompany({
            variables: {
              id,
            },
            fetchPolicy: "no-cache",
          });

          if (res.data.delete_transportCompanies.affected_rows > 0) {
            setTransportCompanies(
              transportCompanies.filter((item) => item.id !== id)
            );

            Swal.fire({
              title: "Registro eliminado",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            });
            setIsEditing(false);
            setClickedRow("");
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const handleEdit = (record) => {
    const {
      id,
      nit,
      name,
      phoneNumber,
      cellPhoneNumber,
      email,
      address,
      contactName,
      ground,
      maritime,
      air,
      enabled,
      deleteable,
      timetables,
    } = record;

    setIsEditing(true);

    form.setFieldsValue({
      id,
      nit,
      name,
      phoneNumber,
      cellPhoneNumber,
      email,
      address,
      contactName,
      ground,
      maritime,
      air,
      enabled,
      deleteable,
      timetables,
    });
  };

  const handleClear = () => {
    setIsEditing(false);
    setClickedRow("");
    handleCancel();

    form.setFieldsValue({
      id: "",
      nit: "",
      name: "",
      phoneNumber: "",
      cellPhoneNumber: "",
      email: "",
      address: "",
      contactName: "",
      ground: true,
      maritime: false,
      air: false,
      enabled: true,
      deleteable: true,
      timetables: [],
    });
  };
  const formItemLayout = {
    labelCol: {
      lg: { span: 5 },
    },
    wrapperCol: {
      lg: { span: 15 },
    },
  };
  return (
    <Col className="children-inner-container-with-bg-col">
      <ConfigurationMoreSettings />
      <Row className="children-inner-row">
        <Col span={5} className="children-inner-col">
          <TransportCompaniesIndicator
            value={transportCompanies.length}
            label={"Total Empresas"}
          >
            <CarOutlined
              style={{ backgroundColor: "var(--primaryColor)" }}
              className="report-indicator-icon"
            />
          </TransportCompaniesIndicator>
        </Col>
        <Col span={5} className="children-inner-col">
          <TransportCompaniesIndicator
            value={transportCompanies.filter((item) => item.enabled).length}
            label={"Empresas Activas"}
          >
            <CheckOutlined
              style={{ backgroundColor: "#9ccc65" }}
              className="report-indicator-icon"
            />
          </TransportCompaniesIndicator>
        </Col>
        <Col span={5} className="children-inner-col">
          <TransportCompaniesIndicator
            value={transportCompanies.filter((item) => !item.enabled).length}
            label={"Empresas Inactivas"}
          >
            <ClockCircleOutlined
              style={{ backgroundColor: "#ffba57" }}
              className="report-indicator-icon"
            />
          </TransportCompaniesIndicator>
        </Col>
        <Col span={3} className="children-inner-col">
          <Button
            type="primary"
            size={"large"}
            className="children-inner-button"
            icon={<EditOutlined />}
            onClick={showModal}
          >
            {clickedRow.length === 0 ? "Crear" : "Editar"}
          </Button>
        </Col>
        <Col span={3} className="children-inner-col">
          <Button
            type="primary"
            size={"large"}
            className="children-inner-button"
            icon={<DeleteFilled />}
            onClick={() => handleDelete(clickedRow)}
            disabled={
              clickedRow.length === 0 ||
              (transportCompanies.length > 0 &&
                transportCompanies.filter(
                  (item) => item.id === clickedRow
                )[0] &&
                !transportCompanies.filter((item) => item.id === clickedRow)[0]
                  .deleteable)
            }
          >
            Eliminar
          </Button>
        </Col>
        <Col span={3} className="children-inner-col">
          <Button
            type="primary"
            size={"large"}
            className="children-inner-button"
            icon={<FieldTimeOutlined />}
            onClick={() => {
              client
                .query({
                  query: fetchTimetables,
                  variables: {
                    transportCompanyId: clickedRow,
                  },
                  fetchPolicy: "no-cache",
                })
                .then(({ data, loading }) => {
                  setTimetables([]);

                  if (!loading && data.timetables.length > 0) {
                    const records = [];
                    data.timetables.map((item) => {
                      const {
                        id,
                        day,
                        startTime,
                        endTime,
                        enabled,
                        transportCompanyId,
                      } = item;

                      return records.push({
                        id,
                        day,
                        startTime,
                        endTime,
                        enabled,
                        transportCompanyId,
                      });
                    });
                    setTimetables(records);
                  }
                  setTransportCompanyId(clickedRow);
                  setCompanyName(
                    transportCompanies.filter(
                      (item) => item.id === clickedRow
                    )[0].name
                  );
                  setShowDrawer(true);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
            disabled={clickedRow.length === 0}
          >
            Horario
          </Button>
        </Col>
      </Row>
      <Row>
        <Skeleton loading={dataTransportCompanies.loading} active>
          <Table
            columns={columns}
            dataSource={transportCompanies}
            className="children-table-global"
            size="small"
            rowKey="id"
            pagination={{ pageSize: 13 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (clickedRow.length === 0 || clickedRow !== record.id) {
                    setClickedRow(record.id);
                    handleEdit(record, setIsEditing, form);
                  } else {
                    handleClear(setIsEditing, form);
                    setClickedRow("");
                  }
                },
              };
            }}
            rowClassName={(record) => [
              "mouse-row-enter",
              record.id === clickedRow ? "table-row-color" : "",
            ]}
          />
        </Skeleton>
      </Row>
      <Modal
        className="children-modal"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[]}
        title={
          clickedRow.length === 0
            ? "Crear Empresa Transportista"
            : "Editar Empresa Transportista"
        }
        width={1000}
      >
        <Form
          form={form}
          {...formItemLayout}
          name="configuration"
          onFinish={(values) => {
            handleSave(values);
            setClickedRow("");
          }}
          initialValues={{
            id: "",
            nit: "",
            name: "",
            phoneNumber: "",
            cellPhoneNumber: "",
            email: "",
            address: "",
            contactName: "",
            ground: true,
            maritime: false,
            air: false,
            enabled: true,
          }}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="Nombre"
            labelAlign="left"
            name="name"
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="NIT"
            labelAlign="left"
            name="nit"
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item labelAlign="left" label="Núm. Teléfono" name="phoneNumber">
            <Input />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Núm. Celular"
            name="cellPhoneNumber"
          >
            <Input />
          </Form.Item>

          <Form.Item labelAlign="left" label="Correo electrónico" name="email">
            <Input />
          </Form.Item>

          <Form.Item labelAlign="left" label="Dirección" name="address">
            <Input />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Persona de contacto"
            name="contactName"
          >
            <Input />
          </Form.Item>

          <Descriptions
            style={{ marginBottom: "10px" }}
            bordered
            size="small"
            column={{ xxl: 4, xl: 4, lg: 4, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label="Terrestre">
              <Form.Item name="ground" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Marítimo">
              <Form.Item name="maritime" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Aéreo">
              <Form.Item name="air" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Habilitada">
              <Form.Item name="enabled" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                onClick={handleCancel}
              >
                {isEditing ? "Modificar" : "Agregar"}
              </Button>
              <Button type="ghost" shape="round" onClick={handleClear}>
                Cancelar
              </Button>
            </Space>
          </Form.Item>
          <Form.Item name="deleteable" hidden valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="timetables" hidden>
            <List />
          </Form.Item>
        </Form>
      </Modal>
      <Timetables
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
        setTimetables={setTimetables}
        timetables={timetables}
        companyName={companyName}
        transportCompanyId={transportCompanyId}
      />
    </Col>
  );
};

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Collapse, Table, Input, Form, Button, Switch } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import { HuePicker as PickerColor } from "react-color";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Polygon,
} from "@react-google-maps/api";

import {
  fetchSectors,
  insertSector,
  updateNameSector,
  updateColorSector,
  updateEnabledSector,
  deleteSector,
  insertSectorPolygon,
  updateSectorPolygon,
  deletePolygon,
} from "../../queries/sectorsQueries";

import { basicTheme } from "../../../../map-themes/basicTheme";
import localStorageService from "../../../../services/localStorageService";
import "../styles/sectors.css";
import { ConfigurationMoreSettings } from "../../../configuration/ConfigurationMoreSettings";

const EditableContext = React.createContext();

const companyId = localStorageService.get("companyId");

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const nameRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      nameRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);

    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      if (values.name !== record.name) {
        handleSave({ ...record, ...values });
      }
    } catch (error) {
      console.error("Save failed:", error);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} es requerido`,
          },
        ]}
      >
        <Input ref={nameRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const libraries = ["places"];

const mapContainerStyle = {
  width: "100%",
  height: "80vh",
};

const options = {
  styles: basicTheme,
  disableDefaultUI: true,
  zoomControl: false,
};

export const MapSectorsSreen = (props) => {
  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    variables: { companyId },
    fetchPolicy: "no-cache",
  });
  const [insSector] = useMutation(insertSector, { fetchPolicy: "no-cache" });
  const [removeSector] = useMutation(deleteSector, { fetchPolicy: "no-cache" });
  const [udpNameSector] = useMutation(updateNameSector, {
    fetchPolicy: "no-cache",
  });
  const [udpColorSector] = useMutation(updateColorSector, {
    fetchPolicy: "no-cache",
  });
  const [udpEnabledSector] = useMutation(updateEnabledSector, {
    fetchPolicy: "no-cache",
  });
  const [insSectorPolygon] = useMutation(insertSectorPolygon, {
    fetchPolicy: "no-cache",
  });
  const [delPolygon] = useMutation(deletePolygon, { fetchPolicy: "no-cache" });
  const [updSectorPolygon] = useMutation(updateSectorPolygon, {
    fetchPolicy: "no-cache",
  });

  const mapRef = useRef();

  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({ lat: 4.570868, lng: -74.297333 });
  const [paths, setPaths] = useState([]);
  const [allPaths, setAllPaths] = useState([]);
  const [dragCoords, setDragCoords] = useState({});
  const [collapseText, setCollapseText] = useState("Ocultar panel");
  const [dataSource, setDataSource] = useState([]);
  const [colorSector, setColorSector] = useState([]);
  const [loadingEnabled, setLoadingEnabled] = useState([]);
  const [selectedSector, setSelectedSector] = useState({});
  const [polygonFillColor, setPolygonFillColor] = useState("#9c10ec");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    getSectors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoaded) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        function (error) {
          console.error(error);
        }
      );
    }
  }, [isLoaded]);

  useEffect(() => {
    if (
      !dataSectors.loading &&
      dataSectors.data &&
      dataSectors.data.sectors.length > 0
    ) {
      let records = [];

      dataSectors.data.sectors.map((item, index) => {
        const deleteable = item.ordersTable_aggregate.aggregate.count === 0;
        const { id, name, color, enabled, sectorPolygons } = item;

        setColorSector((current) => [
          ...current,
          {
            id,
            index,
            color,
          },
        ]);

        setLoadingEnabled((current) => [
          ...current,
          {
            id,
            index,
            enabled,
            loading: false,
          },
        ]);

        setAllPaths((current) => [
          ...current,
          {
            path: sectorPolygons,
            options: {
              fillColor: "#a0a0a0",
              fillOpacity: 0.2,
              strokeColor: "black",
              strokeOpacity: 0.8,
              strokeWeight: 1,
              clickable: false,
              draggable: false,
              editable: false,
              geodesic: false,
              zIndex: -1,
            },
          },
        ]);

        return records.push({
          key: id,
          id,
          name,
          color,
          enabled,
          sectorPolygons,
          deleteable,
        });
      });
      setDataSource(records);
    }
  }, [dataSectors.loading, dataSectors.data]);

  /*const getGrayScaleColor = () => {
        const value = Math.random() * 0xFF | 0;
        const grayscale = (value << 16) | (value << 8) | value;
        const color = '#' + grayscale.toString(16);

        return color;
    };*/

  const onMapClick = useCallback(
    async (e) => {
      if (Object.keys(selectedSector).length > 0) {
        //const id = uuidv4();
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();

        const res = await insSectorPolygon({
          variables: {
            lat,
            lng,
            sectorId: selectedSector.id,
          },
          fetchPolicy: "no-cache",
        });

        if (res.data.insert_sectorsPolygons.affected_rows > 0) {
          const { id } = res.data.insert_sectorsPolygons.returning[0];

          setMarkers((current) => [
            ...current,
            {
              id,
              lat,
              lng,
            },
          ]);

          setPaths((current) => [
            ...current,
            {
              id,
              lat,
              lng,
            },
          ]);

          const sectorPolygons = selectedSector.sectorPolygons;

          sectorPolygons.push({
            id,
            lat,
            lng,
          });

          const newSelectedSector = {
            id: selectedSector.id,
            key: selectedSector.key,
            name: selectedSector.name,
            color: selectedSector.color,
            enabled: selectedSector.enabled,
            sectorPolygons,
          };

          setSelectedSector(newSelectedSector);

          const index = dataSource.findIndex(
            (item) => item.id === newSelectedSector.id
          );
          const newData = dataSource;

          newData.splice(index, 1, {
            id: newSelectedSector.id,
            key: newSelectedSector.key,
            name: newSelectedSector.name,
            color: newSelectedSector.color,
            enabled: newSelectedSector.enabled,
            sectorPolygons,
          });

          setDataSource([...newData]);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectedSector, dataSource]
  );

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (!isLoaded) {
    return "Cargando mapas...";
  }

  const polygonOptions = {
    fillColor: polygonFillColor,
    fillOpacity: 0.4,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };

  const onChangeColor = async (color, id) => {
    if (Object.keys(selectedSector).length > 0 && selectedSector.id === id) {
      const index = colorSector.findIndex((item) => item.id === id);
      const arr = colorSector;
      const row = { id, color: color.hex };

      arr.splice(index, 1, {
        id,
        index,
        color: color.hex,
      });
      setColorSector([...arr]);

      try {
        const res = await udpColorSector({
          variables: {
            id,
            color: color.hex,
          },
          fetchPolicy: "no-cache",
        });

        if (res.data.update_sectors.affected_rows > 0) {
          const newData = [...dataSource];
          const index = newData.findIndex((item) => item.id === id);
          const item = newData[index];

          newData.splice(index, 1, { ...item, ...row });

          setDataSource([...newData]);

          setPolygonFillColor(color.hex);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onChangeEnabledSector = async (e, record) => {
    const index = loadingEnabled.findIndex((item) => item.id === record.id);
    const arr = loadingEnabled;

    arr.splice(index, 1, {
      key: record.id,
      id: record.id,
      index,
      enabled: record.enabled,
      loading: true,
    });

    setLoadingEnabled([...arr]);

    const row = {
      key: record.key,
      id: record.id,
      name: record.name,
      color: record.color,
      enabled: e,
    };
    const { id, enabled } = row;

    try {
      const res = await udpEnabledSector({
        variables: {
          id,
          enabled,
        },
      });

      if (res.data.update_sectors.affected_rows > 0) {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];

        newData.splice(index, 1, { ...item, ...row });

        setDataSource([...newData]);

        arr.splice(index, 1, {
          key: record.id,
          id: record.id,
          index,
          enabled: record.enabled,
          loading: false,
        });
      }
    } catch (error) {
      console.error(error);

      arr.splice(index, 1, {
        id: record.id,
        index,
        enabled: !record.enabled,
        loading: false,
      });
    }

    setLoadingEnabled([...arr]);
  };

  const tableColumns = [
    {
      key: "name",
      title: "Nombre",
      dataIndex: "name",
      width: "50%",
      editable: true,
    },
    {
      key: "color",
      title: "Color",
      dataIndex: "color",
      render: (value, record, idx) => (
        <PickerColor
          color={colorSector[idx].color}
          onChangeComplete={(color) => {
            onChangeColor(color, record.id);
          }}
        />
      ),
    },
    {
      key: "selectedColor",
      dataIndex: "selectedColor",
      render: (value, record, idx) => (
        <div
          style={{
            border: "1px solid black",
            backgroundColor: `${colorSector[idx].color}`,
            height: "20px",
            width: "20px",
          }}
        ></div>
      ),
    },
    {
      key: "enabled",
      title: "Habilitado",
      dataIndex: "enabled",
      align: "center",
      render: (value, record, idx) => (
        <Switch
          loading={loadingEnabled[idx].loading}
          checked={value}
          onChange={(e) => onChangeEnabledSector(e, record)}
        />
      ),
    },
    {
      key: "operation",
      title: "",
      dataIndex: "operation",
      render: (text, record) =>
        dataSectors &&
        dataSectors.data &&
        dataSectors.data.sectors.length >= 1 ? (
          <Button
            disabled={!record.deleteable}
            type="primary"
            shape="round"
            icon={<DeleteFilled />}
            onClick={() => {
              Swal.fire({
                title: "¿Seguro desea eliminar?",
                text: "Este proceso es irreversible!",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "var(--primaryColor)",
                cancelButtonColor: "#B6B6B6",
                confirmButtonText: "Sí, eliminar",
                cancelButtonText: "No, cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  handledDelete(record.id).then((res) => {
                    if (res) {
                      Swal.fire({
                        title: "Registro eliminado",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000,
                      });
                    }
                  });
                }
              });
            }}
          />
        ) : null,
    },
  ];

  const handledDelete = async (id) => {
    try {
      const result = await removeSector({
        variables: {
          id,
        },
        fetchPolicy: "no-cache",
      });

      if (result.data.delete_sectors.affected_rows > 0) {
        setDataSource(dataSource.filter((item) => item.id !== id));
        setLoadingEnabled(loadingEnabled.filter((item) => item.id !== id));
        setColorSector(colorSector.filter((item) => item.id !== id));
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleAdd = async () => {
    const name = "NUEVA ZONA";
    const color = "#9c10ec";
    const enabled = false;

    try {
      const res = await insSector({
        variables: {
          name,
          color,
          enabled,
          companyId,
        },
        fetchPolicy: "no-cache",
      });

      if (res.data.insert_sectors.affected_rows > 0) {
        const { id } = res.data.insert_sectors.returning[0];
        const newRecord = {
          key: id,
          id,
          name,
          color,
          enabled,
          sectorPolygons: [],
          deleteable: true,
        };
        setColorSector((current) => [
          ...current,
          {
            id,
            index: current.index + 1,
            color,
          },
        ]);

        setLoadingEnabled((current) => [
          ...current,
          {
            id,
            index: current.index + 1,
            enabled,
            loading: false,
          },
        ]);

        setDataSource((current) => [...current, newRecord]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async (row) => {
    const { id, name } = row;

    try {
      const res = await udpNameSector({
        variables: {
          id,
          name,
        },
      });

      if (res.data.update_sectors.affected_rows > 0) {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];

        newData.splice(index, 1, { ...item, ...row });

        setDataSource([...newData]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        key: col.key,
        handleSave,
      }),
    };
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length > 0) {
        setPaths([]);
        setMarkers([]);
        setSelectedSector(selectedRows[0]);
        setPolygonFillColor(selectedRows[0].color);

        selectedRows[0].sectorPolygons.map((item) => {
          const { id, lat, lng } = item;

          setMarkers((current) => [
            ...current,
            {
              id,
              lat,
              lng,
            },
          ]);

          return setPaths((current) => [
            ...current,
            {
              id,
              lat,
              lng,
            },
          ]);
        });
      }
    },
  };

  return (
    <div className="mapa-google-v2">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
        id="mapa-google-map-v2"
      >
        {markers.length > 0
          ? markers.map((marker) => {
              return (
                <Marker
                  key={marker.id}
                  draggable
                  position={{ lat: marker.lat, lng: marker.lng }}
                  onClick={async (e) => {
                    const markerToRemove = markers.filter(
                      (item) => item.id !== marker.id
                    );
                    const pathToRemove = paths.filter(
                      (item) => item.id !== marker.id
                    );
                    const res = await delPolygon({
                      variables: {
                        id: marker.id,
                      },
                      fetchPolicy: "no-cache",
                    });

                    if (res.data.delete_sectorsPolygons.affected_rows > 0) {
                      const sectorPolygons =
                        selectedSector.sectorPolygons.filter(
                          (item) => item.id !== marker.id
                        );
                      const newSelectedSector = {
                        id: selectedSector.id,
                        key: selectedSector.key,
                        name: selectedSector.name,
                        color: selectedSector.color,
                        enabled: selectedSector.enabled,
                        sectorPolygons,
                      };

                      setSelectedSector(newSelectedSector);

                      const index = dataSource.findIndex(
                        (item) => item.id === newSelectedSector.id
                      );
                      const newData = dataSource;

                      newData.splice(index, 1, {
                        id: newSelectedSector.id,
                        key: newSelectedSector.key,
                        name: newSelectedSector.name,
                        color: newSelectedSector.color,
                        enabled: newSelectedSector.enabled,
                        sectorPolygons,
                      });

                      setDataSource([...newData]);
                      setMarkers(markerToRemove);
                      setPaths(pathToRemove);
                    }
                  }}
                  onDragStart={(coords) => {
                    const { latLng } = coords;

                    setDragCoords({ lat: latLng.lat(), lng: latLng.lng() });
                  }}
                  onDragEnd={async (coords) => {
                    let newPaths = paths;
                    setPaths([]);

                    const { latLng } = coords;
                    const lat = latLng.lat();
                    const lng = latLng.lng();
                    const id = selectedSector.sectorPolygons.filter(
                      (item) =>
                        item.lat === dragCoords.lat &&
                        item.lng === dragCoords.lng
                    );

                    const res = await updSectorPolygon({
                      variables: {
                        id: id[0].id,
                        lat,
                        lng,
                      },
                      fetchPolicy: "no-cache",
                    });

                    if (res.data.update_sectorsPolygons.affected_rows > 0) {
                      const pathIndex = paths.findIndex(
                        (item) =>
                          item.lat === dragCoords.lat &&
                          item.lng === dragCoords.lng
                      );

                      newPaths.splice(pathIndex, 1, { id: id[0].id, lat, lng });

                      const newMarkers = markers.filter(
                        (item) =>
                          item.lat !== dragCoords.lat &&
                          item.lng !== dragCoords.lng
                      );

                      newMarkers.push({
                        id: id[0].id,
                        lat,
                        lng,
                      });

                      const index = selectedSector.sectorPolygons.findIndex(
                        (item) =>
                          item.lat === dragCoords.lat &&
                          item.lng === dragCoords.lng
                      );

                      const newSelectedSector = selectedSector;

                      newSelectedSector.sectorPolygons.splice(index, 1, {
                        id: id[0].id,
                        lat,
                        lng,
                      });

                      setSelectedSector(newSelectedSector);

                      setMarkers(newMarkers);
                      setPaths(newPaths);
                    }

                    setDragCoords({});
                  }}
                />
              );
            })
          : null}

        {paths.length > 0 && <Polygon paths={paths} options={polygonOptions} />}
        {allPaths.length > 0 &&
          allPaths.map((item, index) => (
            <Polygon
              key={`allPaths-${index}`}
              paths={item.path}
              options={item.options}
            />
          ))}

        <Collapse
          bordered={false}
          accordion={true}
          collapsible="header"
          style={{ borderRadius: "0px", maxHeight: "60%", overflowY: "scroll" }}
          defaultActiveKey={["1"]}
          onChange={(e) => {
            setCollapseText(e === "1" ? "Ocultar panel" : "Mostrar panel");
          }}
        >
          <Collapse.Panel header={collapseText} key="1">
            <div>
              <ConfigurationMoreSettings /> &nbsp;
              <Button
                onClick={handleAdd}
                type="primary"
                shape="round"
                style={{
                  marginBottom: 16,
                }}
              >
                Agregar
              </Button>
              <Table
                style={{
                  minWidth: "100%",
                }}
                size="small"
                components={components}
                rowClassName={() => "editable-row"}
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 10 }}
                rowSelection={{
                  type: "radio",
                  ...rowSelection,
                }}
              />
            </div>
          </Collapse.Panel>
        </Collapse>
      </GoogleMap>
    </div>
  );
};

import { gql } from '@apollo/client';

export const fetchUsersVehicles = gql`
query fetchUsersVehicles($distributionCenterId: [uuid!]){
    userVehicle(where: {hasAssignedRoute: {_eq: false},_and:{user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}}}) {
        id
        userId
        lastLocation
        user {
            displayName
          distributionCenterId
          userDeliveryCenters{
            distributionCenterId
          }
        }
        hasAssignedRoute
        vehicleId
        vehicle {
            licencePlate
            cubicMeters
            tare
            weight
            schedulingStartLocation
        }
    }
}
`;

export const subscriptionUsersVehicles = gql`
    subscription subscriptionUsersVehicles {
        userVehicle(where: {hasAssignedRoute: {_eq: false}}) {
            id
            userId
            lastLocation
            user {
                displayName
            }
            hasAssignedRoute
            vehicleId
            vehicle {
                licencePlate
                cubicMeters
                tare
                weight
                schedulingStartLocation
            }
            ordersInVehicleTemp {
                id
                createByUserId
                orderTableId
                orderPosition
                userId
                user {
                    displayName
                }
                vehicleId
                vehicle {
                    licencePlate
                }
                order {
                    address
                    addressComplement
                    orderNumber
                    destination
                    weight
                    totalCubicMeters
                    enableDeliveryWindow
                    expectedDeliveryStartTime
                    expectedDeliveryEndTime
                    consecutiveBill
                    consecutiveRemission
                    consecutiveSaleOrder
                    sector {
                        color
                    }
                }
            }
        }
    }
  
`;

export const fetchOrders = gql`
query fetchOrders($distributionCenterId: [uuid!]) {
    ordersTable(where:{distributionCenterId: { _in: $distributionCenterId }, sector: {enabled: {_eq: true}}, _or: [{_and: [ {route: {status: {_eq: "rescheduled_delivery"}}}, {inUse: {_eq: false}}]},{_and: [ {route: {status: {_eq: "partial_delivered"}}}, {inUse: {_eq: false}}]}, {_and: [ {routeId: {_is_null: true}}, {inUse: {_eq: false}}   ]}  ]}, order_by: {priority: asc, createdAt: desc}) {
        id
        orderNumber
        address
        addressComplement
        custFullName
        custIdentificationNumber
        destination
        sectorId
        type
        priority
        consecutiveBill
    	consecutiveBurden
        consecutiveRemission
        consecutiveSaleOrder
    	distributionCenterId
    	custPhoneNumber
    	custEmail
    distributionCenter{
      id
      name
    }
        sector {
            name
            color
        }
        inUse
        routeId
        route{
          status
        }
        totalCubicMeters
        enableDeliveryWindow
        expectedDeliveryStartTime
        expectedDeliveryEndTime
        weight
    }
}
`;

export const subscriptionFetchOrders = gql`
subscription fetchOrders($distributionCenterId: [uuid!]) {
    ordersTable(where:{distributionCenterId: { _in: $distributionCenterId }, sector: {enabled: {_eq: true}}, _or: [{_and: [ {route: {status: {_eq: "rescheduled_delivery"}}}, {inUse: {_eq: false}}]},{_and: [ {route: {status: {_eq: "partial_delivered"}}}, {inUse: {_eq: false}}]}, {_and: [ {routeId: {_is_null: true}}, {inUse: {_eq: false}}   ]}  ]}, order_by: {priority: asc, createdAt: desc}) {
        id
        orderNumber
        address
        addressComplement
        custFullName
        custIdentificationNumber
        destination
        sectorId
        type
        priority
        consecutiveBill
    		consecutiveBurden
        consecutiveRemission
        consecutiveSaleOrder
    	distributionCenterId
    	custPhoneNumber
    	custEmail
        paymentMethod
        consecutiveShipping
        onSiteDelivery{
            status
          }
    	ordersLines{
        orderNumber
        productNumber
        productName
        orderedQuantity
        deliveredQuantity
        packageId
        height
        width
        depth
        weight
        partsPerBox
        partWeight
        safetyFactor
        cubicMeters
        totalCubicMeters
        numberLine
        externalId
        initialQuantity
        Invoice
        PackingSlip
        summationQuantity
        externalInvoiceId
        externalSalesId
        processed
      }
    distributionCenter{
      id
      name
    }
        sector {
            name
            color
        }
        inUse
        routeId
        route{
            id
          status
        }
        totalCubicMeters
        enableDeliveryWindow
        expectedDeliveryStartTime
        expectedDeliveryEndTime
        weight
    }
}
`;
export const updateOrdeLineOrdersInVehicleTemp = gql`
mutation updateOrdeLineOrdersInVehicleTemp($id : uuid!, $orderedQuantity : Int!, $deliveredQuantity  : Int!) {
    update_ordersLine(_set: {orderedQuantity: $orderedQuantity, deliveredQuantity: $deliveredQuantity}, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;
export const fetchOrdersInVehicleTempCreateByUserId = gql`
    query fetchOrdersInVehicleTempCreateByUserId($createdByUserId: uuid!) {
        ordersInVehicleTemp(where: {createByUserId: {_eq: $createdByUserId}}, order_by: {orderPosition: asc}) {
            id
            createByUserId
            orderTableId
            orderPosition
            userId
            user {
                displayName
            }
            vehicleId
            vehicle {
                licencePlate
            }
            order {
                custFullName
                custIdentificationNumber
                custEmail
                address
                addressComplement
                orderNumber
                destination
                totalCubicMeters
                enableDeliveryWindow
                expectedDeliveryStartTime
                expectedDeliveryEndTime
                consecutiveBill
                consecutiveRemission
                consecutiveSaleOrder
                weight
                route{
                    id,
                    status
                }
                ordersLines{
                    id
                    orderNumber
                    productNumber
                    productName
                    orderedQuantity
                    deliveredQuantity
                    initialQuantity
                }
                sector {
                    color
                    name
                }
            }
        }
    }
`;


export const subscriptionOrdersInVehicleTempCreateByUserId = gql`
    subscription subscriptionOrdersInVehicleTempCreateByUserId {
        ordersInVehicleTemp(order_by: {orderPosition: asc}) {
            id
            createByUserId
            orderTableId
            orderPosition
            userId
            user {
                displayName
            }
            vehicleId
            vehicle {
                licencePlate
            }
            order {
                custFullName
                custIdentificationNumber
                custEmail
                address
                addressComplement
                orderNumber
                destination
                totalCubicMeters
                enableDeliveryWindow
                expectedDeliveryStartTime
                expectedDeliveryEndTime
                weight
                consecutiveBill
                consecutiveRemission
                consecutiveSaleOrder
                custPhoneNumber
                route{
                    id,
                    status
                }
                ordersLines{
                    id
                    orderNumber
                    productNumber
                    productName
                    orderedQuantity
                    deliveredQuantity
                    initialQuantity
                    summationQuantity
                }
                sector {
                    color
                    name
                }
            }
        }
    }
  
`;

export const updateOrdersTableInUseToLeft = gql`
    mutation updateOrdersTableInUse($id: uuid!, $inUse: Boolean!, $createByUserId: uuid!, $orderTableId: uuid!, $userId: uuid!, $vehicleId: uuid!, $orderPosition: timestamptz!) {
        update_ordersTable(_set: {inUse: $inUse}, where: {id: {_eq: $id}, _and: {inUse: {_neq: $inUse}}}) {
            affected_rows
        }
        
        insert_ordersInVehicleTemp(objects: {createByUserId: $createByUserId, orderTableId: $orderTableId, userId: $userId, vehicleId: $vehicleId, orderPosition: $orderPosition}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateOrdersTableInUseToRight = gql`
    mutation updateOrdersTableInUse($id: uuid!, $inUse: Boolean!, $ordersInVehicleTempId: uuid!) {
        update_ordersTable(_set: {inUse: $inUse}, where: {id: {_eq: $id}, _and: {inUse: {_neq: $inUse}}}) {
            affected_rows
        }
        
        delete_ordersInVehicleTemp(where: {id: {_eq: $ordersInVehicleTempId}}) {
            affected_rows
        }
    }
`;

export const getOrderById = gql`
    query getOrderById($id: uuid!) {
        ordersTable (where: {id: {_eq: $id}}) {
            id
            address
            addressComplement
            orderNumber
            sectorId
            sector {
                color
                name
            }
            weight
            totalCubicMeters
            enableDeliveryWindow
            expectedDeliveryStartTime
            expectedDeliveryEndTime
        }
    }
`;

export const updateSortPositionOrdersInVehicleTemp = gql`
    mutation updateSortPositionOrdersInVehicleTemp($id: uuid!, $orderPosition: timestamptz!) {
        update_ordersInVehicleTemp(_set: {orderPosition: $orderPosition}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const insertRoute = gql`
    mutation insertRoute($userId: uuid!, $batch: uuid!, $routeOrder: Int!, $orderTableId: uuid!, $createByUserId: uuid!, $orderInVehicleTempId: uuid!) {
        insert_routes(objects: {userId: $userId, batch: $batch, routeOrder: $routeOrder, orderTableId: $orderTableId, createByUserId: $createByUserId, orderInVehicleTempId: $orderInVehicleTempId}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateRouteIfError = gql`
    mutation updateRouteIfError($orderInVehicleTempId: uuid!, $batch: uuid!, $orderTableId: uuid!) {
        update_routes(_set: {orderInVehicleTempId: ""}, where: {id: {_eq: $orderInVehicleTempId}}) {
            affected_rows
        }
        
        update_ordersTable(_set: {routeId: ""}, where: {id: {_eq: $orderTableId}}) {
            affected_rows
        }
        
        update_ordersInVehicleTemp(_set: {deleteable: false}, where: {id: {_eq: $orderInVehicleTempId}}) {
            affected_rows
        }
        
        delete_routesBatch(where: {batch: {_eq: $batch}}) {
            affected_rows
        }
    }
`;

export const insertRoutesBatch = gql`
    mutation insertRoutesBatch($batch: uuid!, $userId: uuid!, $licencePlate: String!) {
        insert_routesBatch(objects: {batch: $batch, userId: $userId, licencePlate: $licencePlate}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const deleteOrderInVehicleTemp = gql`
    mutation deleteOrderInVehicleTemp($id: uuid!, $batch: uuid!, $userId: uuid!, $licencePlate: String!, $userVehicleId: uuid!) {
        delete_ordersInVehicleTemp(where: {id: {_eq: $id}}) {
            affected_rows
        }
        
        insert_routesBatch(objects: {batch: $batch, userId: $userId, licencePlate: $licencePlate}, on_conflict: {constraint: routesBatch_batch_key, update_columns: batch}) {
            affected_rows
            returning {
                id
            }
        }
        
        update_userVehicle(_set: {hasAssignedRoute: true}, where: {id: {_eq: $userVehicleId}}) {
            affected_rows
        }

        update_routes(_set: {orderInVehicleTempId: null}, where: {orderInVehicleTempId: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const updateRouteIdOrdersTable = gql`
    mutation MyMutation($id: uuid!, $routeId: uuid!) {
        update_ordersTable(where: {id: {_eq: $id}}, _set: {routeId: $routeId}) {
            affected_rows
        }
    }  
`;

export const updateHasAssignedRouteUserVehicle = gql`
    mutation updateHasAssignedRouteUserVehicle($id: uuid!, $hasAssignedRoute: Boolean!) {
        update_userVehicle(_set: {hasAssignedRoute: $hasAssignedRoute}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const subscriptionCustomerGetOrder = gql`
subscription getRoute($orderNumber: String!) {
    routes(where: {orderTable: {orderNumber: {_eq: $orderNumber}}}order_by: { createdAt: desc}) {
        id
        createdAt
        durationText
        durationValue
        startDateTime
        endDateTime
        status
        reason {
            description
        }
        routeBatch {
            startDateRoute
        }
        evidences {
            evidenceType
            evidenceURL
            comments
        }
    }
}
`;
export const fetchWarehouses = gql`
    query fetchWarehouses($companyId: uuid!) {
        distributionCenters(where:{enabled:{_eq: true}, companyId: {_eq: $companyId}}) {
            name
            id
            state
            city
            address
            addressComplement
            lat
            lng
            contactName
            phone
            email
            sectorId
        }
    }
`;

export const insertHistoricalOrdersTable = gql`
mutation insertHistoricalOrdersTable($name: String,$identificationNumber: String, $type: String, $coment: String, $userId: uuid, $ordenNumber: String) {
    insert_historicalDeleteOrders(objects: {name: $name, identificationNumber: $identificationNumber, type: $type, coment: $coment, userId: $userId, ordenNumber: $ordenNumber}) {
        affected_rows
        returning {
            id
        }
    }
}
`;

export const insertDeleteOrdersTable = gql`
mutation insertDeleteOrdersTable($orderNumber: String,$address: String, $addressComplement: String, $color: String, $consecutiveBill: String, $consecutiveBurden: String, $consecutiveRemission: String, $consecutiveSaleOrder: String, $custEmail: String, $custFullName: String, $custIdentificationNumber: String, $custPhoneNumber: String, $destination: String, $distributionCenterid: uuid, $enableDeliveryWindow: Boolean, $priority: String, $route: uuid, $sectorId: uuid, $totalCubicMeters: Int, $type: String, $weight: Int, $paymentMethod: String, $consecutiveShipping: String) {
    insert_deleteOrdersTable(objects: {orderNumber: $orderNumber, address: $address, addressComplement: $addressComplement, color: $color, consecutiveBill: $consecutiveBill, consecutiveBurden: $consecutiveBurden, consecutiveRemission: $consecutiveRemission, consecutiveSaleOrder: $consecutiveSaleOrder, custEmail: $custEmail, custFullName: $custFullName, custIdentificationNumber: $custIdentificationNumber, custPhoneNumber: $custPhoneNumber, destination: $destination, distributionCenterid: $distributionCenterid, enableDeliveryWindow: $enableDeliveryWindow, priority: $priority, route: $route, sectorId: $sectorId, totalCubicMeters: $totalCubicMeters, type: $type, weight: $weight, paymentMethod: $paymentMethod, consecutiveShipping: $consecutiveShipping}) {
        affected_rows
        returning {
            id
        }
    }
}
`;

export const insertDeleteOrdersLine = gql`
mutation insertDeleteOrdersLine($objects: [DeleteOrdersLine_insert_input!]!) {
    insert_DeleteOrdersLine(objects: $objects) {
        returning {
            id
        }
    }
}
`;
export const deleteOrdersTableInUseToLeft = gql`
mutation deleteOrdersTableInUseToLeft($id: uuid!) {
    delete_ordersTable( where: {id: {_eq: $id}}) {
        affected_rows
    }
}
`;

export const recordRoutes = gql`
query recordRoutes($orderNumber: String!) {
    routes(where: {orderTable: {orderNumber: {_eq: $orderNumber}}}order_by: { createdAt: desc}) {
      batch
      endDateTime
      receivedPerson
      receivedDocument
      status
      orderTable {
        orderNumber
      }
    }
}
`;
import React, { useState, useEffect} from "react";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Search from '@mui/icons-material/Search';
import { useTMSStore } from "../../../../../../../store/tms-store";
import { Button } from "antd";
import { ArrowLeftOutlined, RedoOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { TMSSearchTextOvTrn } from './tms-filters-search-ovtrn';

export const TMSSearchText = ({isBlankActive}) => {

  const loading = useTMSStore((state) => state.loading);
  const setSearchText = useTMSStore((state) => state.setSearchText);
  const [searchFilter, setSearchFilter] = useState("");
  const [inputValue, setInputValue] = useState(isBlankActive);
  

  useEffect(() => {
    setInputValue(isBlankActive);
  }, [isBlankActive]);
 

  const textFieldEvent = (ev) => {
    const inputValue = ev.target.value;
    setSearchFilter(inputValue);

  };

  useEffect(() => {
    if (inputValue) {
   
      setSearchFilter("");
    }
  }, [inputValue, setSearchText]);

  const textFieldOnKeyDown = (ev) => {

    if(ev.keyCode === 13){
      setSearchText(searchFilter)
      setInputValue(false);

    }
  };
 

  ///

  
    return (
      
    <TextField onKeyDown={textFieldOnKeyDown} onChange={textFieldEvent} label="Buscar Carga" className="tms-search-textfield" color="error" value={searchFilter} InputProps={{endAdornment: <InputAdornment position="end">
        <IconButton>
          {loading ? <CircularProgress size={20} color="error" /> :  <Search onClick={() => setSearchText(searchFilter)} />}
        </IconButton>
      </InputAdornment>
      }}/>  
    
     
  );
};

import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import CloseIcon from "@mui/icons-material/Close";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Swal from "sweetalert2";
import {
  getDepartments,
  getCitiesByDepartment,
} from "../services/get-departments-city";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const geocodeAddressModal = async (address, city, state, apiKey) => {
  // Intenta primero con la dirección completa
  let formatAddress = address.replace(/ /g, "+");
  let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formatAddress}&key=${apiKey}`;

  try {
    let response = await fetch(url);
    let data = await response.json();
    if (data.status === "OK" && data.results[0]) {
      return data.results[0].geometry.location;
    } else {
      // Si la dirección completa no funciona, intenta con ciudad y departamento
      formatAddress = `${city.replace(/ /g, "+")},+${state.replace(/ /g, "+")}`;
      url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formatAddress}&key=${apiKey}`;
      response = await fetch(url);
      data = await response.json();
      if (data.status === "OK" && data.results[0]) {
        return data.results[0].geometry.location;
      } else {
        throw new Error(
          "Error fetching location with fallback address: " + data.status
        );
      }
    }
  } catch (error) {
    console.error("Error in geocoding process", error);
    throw error;
  }
};

const containerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "15px",
};

const MapModal = ({
  address,
  onClose,
  city,
  street,
  state,
  shippingId,
  setNewLocation,
}) => {
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [departamento, setDepartamento] = useState(state || "");
  const [ciudad, setCiudad] = useState(city || "");
  const [direccion, setDireccion] = useState(street || "");
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [centerModal, setCenterModal] = React.useState({
    lat: 4.570868,
    lng: -74.297333,
  });

  useEffect(() => {
    // Carga inicial de departamentos
    const loadDepartments = async () => {
      const departmentsData = await getDepartments();
      setDepartamentos(departmentsData); // Corregido para usar el estado adecuado
    };

    loadDepartments();
  }, []);

  useEffect(() => {
    // Carga de ciudades según el departamento seleccionado
    const loadCities = async () => {
      if (!departamento) return;
      const citiesData = await getCitiesByDepartment(departamento);
      setCiudades(citiesData); // Corregido para usar el estado adecuado
    };

    if (departamento) {
      loadCities();
    } else {
      setCiudades([]); // Limpia las ciudades si no hay departamento seleccionado
    }
  }, [departamento]);

  useEffect(() => {
    const geocodeAndUpdateMapWithDefaultCity = async () => {
      if (departamento && ciudades.length > 0 && direccion) {
        try {
          // Utiliza la primera ciudad de la lista como la nueva ciudad por defecto
          const combinedAddress = `${direccion}, ${ciudades[0]}, ${departamento}`;
          const location = await geocodeAddressModal(
            combinedAddress,
            ciudades[0],
            departamento,
            apiKey
          );
          setCenterModal(location); // Actualiza la ubicación central del mapa
          setLatitud(location.lat); // Actualiza el input de latitud
          setLongitud(location.lng); // Actualiza el input de longitud
        } catch (error) {
          console.error(error);
        }
      }
    };

    geocodeAndUpdateMapWithDefaultCity();
  }, [departamento, ciudades, direccion, apiKey]);

  useEffect(() => {
    const geocodeAndUpdateMap = async () => {
      if (departamento && ciudad && direccion) {
        try {
          // Combina la dirección, ciudad y departamento para la geolocalización
          const combinedAddress = `${direccion}, ${ciudad}, ${departamento}`;
          const location = await geocodeAddressModal(
            combinedAddress,
            ciudad,
            departamento,
            apiKey
          );
          setCenterModal(location); // Actualiza la ubicación central del mapa
          setLatitud(location.lat); // Actualiza el input de latitud
          setLongitud(location.lng); // Actualiza el input de longitud
        } catch (error) {
          console.error(error);
        }
      }
    };

    geocodeAndUpdateMap();
  }, [departamento, ciudad, direccion, apiKey]);

  const reverseGeocode = async (lat, lng, apiKey) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === "OK" && data.results[0]) {
        const addressComponents = data.results[0].address_components;

        let streetNumber = "";
        let route = "";
        let locality = "";
        let administrativeArea = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("street_number")) {
            streetNumber = component.long_name;
          }
          if (component.types.includes("route")) {
            route = component.long_name;
          }
          if (component.types.includes("locality")) {
            locality = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            administrativeArea = component.long_name;
          }
          // Puedes continuar para otros tipos como país, código postal, etc.
        });

        const address = `${streetNumber} ${route}`;
        const city = locality;
        const state = administrativeArea;

        return { address, city, state };
      } else {
        throw new Error("No se pudo obtener la dirección desde la ubicación.");
      }
    } catch (error) {
      console.error("Error en la geocodificación inversa", error);
      throw error;
    }
  };

  const handleSave = async () => {
    const newLocation = {
      departamento,
      ciudad,
      direccion,
    };
    // Aquí pasarías la nueva ubicación hacia arriba para ser usada por otro componente o función
    await setNewLocation(newLocation);
    onClose(); // Cerrar el modal después de guardar
    Swal.fire({
      icon: "success",
      title: "¡Ubicación Actualizada!",
      text: `La ubicación del envío ${shippingId} ha sido cambiada correctamente.`,
    });
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartment = event.target.value;
    setDepartamento(selectedDepartment);
    setCiudad(""); // Limpia la ciudad al cambiar de departamento
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setCiudad(selectedCity);
    setDireccion(selectedCity); // Establece la dirección como el nombre de la ciudad seleccionada
  };

  const updateLocation = async (lat, lng) => {
    setLatitud(lat);
    setLongitud(lng);

    // Aquí asumimos que tienes una función que puede hacer geocodificación inversa a partir de la lat y lng
    try {
      const addressData = await reverseGeocode(lat, lng, apiKey);
      setDireccion(addressData.address);
      setCiudad(addressData.city);
      setDepartamento(addressData.state);
      setCenterModal({ lat, lng });
    } catch (error) {
      console.error(error);
    }
  };

  const mapOptions = {
    streetViewControl: false, // Desactiva el control de Street View
    scaleControl: false, // Desactiva el control de escala
    mapTypeControl: false, // Desactiva el control para cambiar el tipo de mapa
    panControl: false, // Desactiva el control de paneo
    zoomControl: false, // Desactiva los controles de zoom
    fullscreenControl: false, // Desactiva el botón de pantalla completa
  };

  useEffect(() => {
    const fetchLocation = async () => {
      if (address) {
        try {
          const location = await geocodeAddressModal(
            address,
            city,
            state,
            apiKey
          );
          setCenterModal(location);
          setLatitud(location.lat);
          setLongitud(location.lng);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchLocation();
  }, [address, city, state, apiKey]);

  return (
    <div className="map-modal">
      <div className="map-modal-content">
        <div className="map-modal-header">
          <span className="map-modal-title">Modificar Ubicación</span>
          <span className="map-modal-shipment-id">{shippingId}</span>
        </div>
        <button className="map-modal-close-button" onClick={onClose}>
          <CloseIcon className="close-icon" />
        </button>
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={centerModal}
            zoom={17}
            options={mapOptions}
          >
            <Marker
              position={centerModal}
              draggable={true}
              onDragEnd={(e) => {
                updateLocation(e.latLng.lat(), e.latLng.lng());
              }}
            />
          </GoogleMap>
          <div className="map-modal-footer">
            <select
              title="Departamento"
              value={departamento}
              onChange={handleDepartmentChange}
            >
              {departamentos.map((dep) => (
                <option key={dep} value={dep}>
                  {dep}
                </option>
              ))}
            </select>
            <select value={ciudad} onChange={handleCityChange}>
              {ciudades.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={direccion}
              onChange={(e) => setDireccion(e.target.value)}
              className="direccion-input"
            />
            <input
              type="text"
              value={latitud}
              onChange={(e) => setLatitud(e.target.value)}
              className="latitud-input"
            />
            <input
              type="text"
              value={longitud}
              onChange={(e) => setLongitud(e.target.value)}
              className="longitud-input"
            />
            <button onClick={handleSave}>
              <SaveAsIcon className="save-icon" />
            </button>
          </div>
        </LoadScript>
      </div>
    </div>
  );
};

export default MapModal;

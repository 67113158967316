import React, { useState, useEffect } from "react";
import { Table, Card, Skeleton, Row, Col, Image, Pagination } from "antd";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import "moment/locale/es";
import localStorageService from "../../../../services/localStorageService";
import { getColumnSearchProps, getColumnSearchPropsText } from "../../../completed-routes/ui/screens/UseCases";
import {
  fetchOnSiteDeliveries
} from "../../queries/queryOnsiteDeliveries";
import "../../../completed-routes/ui/styles/styles.css";

export const OnSiteDeliveriesScreen = (props) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    JSON.parse(localStorageService.get("userDistributionsCenters"))
  );
 
  const [getCompletedRoutes, dataCompletedRoutes] = useLazyQuery(
    fetchOnSiteDeliveries,
    {
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
      },
      fetchPolicy: "no-cache",
    }
  );
  const [detailss, setDetailss] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedOrderTable, setSelectedOrderTable] = useState([]);
  const [lines, setLines] = useState([]);
  const [filter, setFilter] = useState("");
  const [currentPageSecond, setCurrentPageSecond] = useState(1);
  const [pageSizeSecond, setPageSizeSecond] = useState(10);
  const [currentPageTree, setCurrentPageTree] = useState(1);
  const [pageSizeTree, setPageSizeTree] = useState(10);
  

  useEffect(() => {
    const warehouses = JSON.parse(
      localStorageService.get("selectedWarehouses")
    )?.warehouses;
    setSelectedWarehouse(warehouses);
  }, [props.flag]);

  useEffect(() => {
    getCompletedRoutes();
  }, []);

  useEffect(() => {
    setDetailss(dataCompletedRoutes.data?.onSiteDeliveries.map(item=>({
      ...item,
      key: item.id
    })));
    console.log(dataCompletedRoutes.data?.onSiteDeliveries)
  }, [dataCompletedRoutes.data]);
  
  useEffect(() => {
    setFilter(
      localStorage.getItem("searchTarget") &&
        localStorage.getItem("searchTarget").length > 0
        ? localStorage.getItem("searchTarget")
        : ""
    );
  }, [props.flag]);

  const detailsColumns = [
    {
      key: "orderNumber",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Orden",
      render: (record) => {
        return <span>{record?.orderNumber}</span>;
      },
      ...getColumnSearchPropsText("Orden", "orderTable.orderNumber"),
    },
    {
      key: "consecutiveBurden",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Carga",
      render: (record) => {
        return <span>{record?.consecutiveBurden}</span>;
      },
      ...getColumnSearchPropsText("Carga", "orderTable.consecutiveBurden"),
    },
    {
      key: "custFullName",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Cliente",
      render: (record) => {
        return <span>{record?.custFullName}</span>;
      },
    },
    {
      key: "status",
      dataIndex: "status",
      title: "Estado",
      className: "table-column",
      render: (value) => {
        let status = "";
        switch (value) {
          case "none":
            status = "Ninguno";
            break;
          case "start":
            status = "Ninguno";
            break;
          case "started":
            status = "En reparto";
            break;
          case "delivered":
            status = "Completamente Entregado";
            break;
          case "undelivered":
            status = "Devuelto";
            break;
          case "partial_delivered":
            status = "Con Novedad";
            break;
          case "rescheduled_delivery":
            status = "Entrega Reprogramada";
            break;
          default:
            status = "Ninguno";
            break;
        }

        return status;
      },
    },
    {
      key: "receivedPerson",
      dataIndex: "receivedPerson",
      title: "Nombre",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      key: "receivedDocument",
      dataIndex: "receivedDocument",
      title: "Documento",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      key: "consecutiveBill",
      dataIndex: "orderTable",
      title: "Factura",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveBill}</span>;
      },
    },
    {
      key: "consecutiveRemission",
      dataIndex: "orderTable",
      title: "Remisión",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveRemission}</span>;
      },
    },
    {
      key: "consecutiveSaleOrder",
      dataIndex: "orderTable",
      title: "Orden/TRN",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveSaleOrder}</span>;
      },
    },
    {
      key: "comments",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Notas",
      render: (record) => {
        return <span>{record?.evidences[0].comments}</span>;
      },
    },
  ];

  const linesColumns = [
    {
      key: "productNumber",
      dataIndex: "productNumber",
      title: "Código",
      className: "table-column",
      align: "center",
      className: "table-column",
    },
    {
      key: "productName",
      dataIndex: "productName",
      className: "table-column",
      title: "Nombre",
    },
    {
      key: "orderedQuantity",
      dataIndex: "orderedQuantity",
      title: "Cantidad",
      className: "table-column",
      align: "right",
    },
    {
      key: "summationQuantity",
      dataIndex: "summationQuantity",
      title: "Entregado",
      className: "table-column",
      align: "right",
    },
  ];


  const rowSelectionDetails = {
    onChange: (selectedRowKeys, selectedRows) => {
      const records = [];
      setDetails(selectedRows[0]);
      selectedRows[0].orderTable.ordersLines.map((item) => {
        const {
          id,
          productNumber,
          productName,
          orderedQuantity,
          summationQuantity,
        } = item;

        return records.push({
          id,
          productNumber,
          productName,
          orderedQuantity,
          summationQuantity
        });
      });

      setLines(records);
      setSelectedOrderTable(selectedRows[0].orderTable);
    },
  };

  const handlePageChangeSecond = (page, pageSize) => {
    setCurrentPageSecond(page);
    setPageSizeSecond(pageSize);
  };
  const handlePageChangeTree = (page, pageSize) => {
    setCurrentPageTree(page);
    setPageSizeTree(pageSize);
  };

  const filteredDataSecond =
    filter.length > 0
      ? details.filter((item) => {
          if (
            item.orderTable?.address
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }

          if (
            item.orderTable?.addressComplement
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }

          if (
            item.orderTable?.custFullName
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }

          if (
            item.orderTable?.custIdentificationNumber
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }

          if (
            item.orderTable?.orderNumber
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.consecutiveBill
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.consecutiveRemission
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.consecutiveSaleOrder
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.consecutiveBill
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.consecutiveShipping
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.address
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.city?.toUpperCase().includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.state
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.type?.toUpperCase().includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.routes?.receivedPerson
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.routes?.receivedDocument
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.expectedDeliveryStartTime
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          if (
            item.orderTable?.expectedDeliveryEndTime
              ?.toUpperCase()
              .includes(filter?.toUpperCase())
          ) {
            return true;
          }
          return false;
        })
      : detailss;

  const paginatedDataSecond = filteredDataSecond?.slice(
    (currentPageSecond - 1) * pageSizeSecond,
    currentPageSecond * pageSizeSecond
  );

  const paginatedDataTree = lines?.slice(
    (currentPageTree - 1) * pageSizeTree,
    currentPageTree * pageSizeTree
  );
  
  return (
    <Col className="children-inner-container-with-bg">
      <Row>
        <Col span={14}>
          <Skeleton loading={dataCompletedRoutes.loading} active>
            <Table
              className={
                "children-table-101"
              }
              columns={detailsColumns}
              dataSource={paginatedDataSecond}
              pagination={false}
              rowKey="id"
              rowSelection={{
                type: "radio",
                ...rowSelectionDetails,
              }}
            />
            <Pagination
              current={currentPageSecond}
              pageSize={pageSizeSecond}
              total={filteredDataSecond?.length}
              onChange={handlePageChangeSecond}
            />
          </Skeleton>
        </Col>
        <Col span={8}>
          <Row>
            <Skeleton loading={dataCompletedRoutes.loading} active>
              <Table
                className={
                   "children-table-101"
                }
                columns={linesColumns}
                dataSource={paginatedDataTree}
                pagination={false}
                rowKey="id"
              />
              <Pagination
                current={currentPageTree}
                pageSize={pageSizeTree}
                total={filteredDataSecond?.length}
                onChange={handlePageChangeTree}
              />
            </Skeleton>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={42}>
          <Row>
            {selectedOrderTable?.evidences?.length > 0 && (
              <Col span={24}>
                <h2>Evidencias</h2>
                <div>
                  <h4>
                    Comentarios: {selectedOrderTable.evidences[0].comments}
                  </h4>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: "inline-flex",
                overflowX: "scroll",
                height: "auto",
                maxWidth: "100%",
                width: "100%",
              }}
            >
              {selectedOrderTable?.evidences?.map((item, index) => {
                return (
                  <span key={`span-${index}`} style={{ padding: "3px" }}>
                    <Card
                      key={`card-${index}`}
                      hoverable
                      style={{ width: "300px", minWidth: "300px" }}
                      cover={
                        <Image
                          style={{
                            borderRadius: "10px 10px 0 0",
                            objectFit:
                              item.evidenceType === "FIRMA"
                                ? "contain"
                                : "cover",
                          }}
                          key={`image-${index}`}
                          width={300}
                          height={300}
                          src={item.evidenceURL}
                        />
                      }
                    >
                      <Card.Meta
                        key={`cardmeta-${index}`}
                        description={item.evidenceType}
                      />
                    </Card>
                  </span>
                );
              })}
            </Col>
          </Row>
        </Col>
        
      </Row>
    </Col>
  );
};

import React, { useState, useEffect } from "react";
import { Table, Card, Skeleton, Row, Col, Image, Pagination } from "antd";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import "moment/locale/es";
import localStorageService from "../../../../services/localStorageService";
import { getColumnSearchProps, getColumnSearchPropsText } from "./UseCases";
import {
  fetchCompletedRoutes,
  dataRoutesForRouteBatch,
  fetchtelemetry,
  countRegisterRoute,
  countRegisterRouteNumber
} from "../../queries/routesBatch";
import { DeliveryRoute } from "./DeliveryRoute";
import "../styles/styles.css";
import { Label } from "recharts";
import * as geolib from 'geolib';

export const CompletedRoutesScreen = (props) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    JSON.parse(localStorageService.get("userDistributionsCenters"))
  );
  const [telemetryUserVehicle, setTelemetryUserVehicle] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [
    telemetryOrderTablestartDateTime,
    setTelemetryOrderTablestartDateTime,
  ] = useState("");
  const [telemetryOrderTableEndDateTime, setTelemetryOrderTableEndDateTime] =
    useState("");
  const [visibleTelemetry, setVisibleTelemetry] = useState(false);
  const [tableResponsive, setTableResponsive] = useState(false);
  const [numeberPagination, setNumeberPagination] = useState(5);
  const [limitPagination, setlimitPagination] = useState(1);
  const [getCompletedRoutes, dataCompletedRoutes] = useLazyQuery(countRegisterRoute, {
    variables: {
      distributionCenterId:
        selectedWarehouse?.length > 0
          ? selectedWarehouse
          : ["00000000-0000-0000-0000-000000000000"],
     
    },
    
    fetchPolicy: "no-cache",
  })
  const [getCompletedRoutesNumber, dataCompletedRoutesNumber] = useLazyQuery(countRegisterRouteNumber, {
    variables: {
      distributionCenterId:
        selectedWarehouse?.length > 0
          ? selectedWarehouse
          : ["00000000-0000-0000-0000-000000000000"],
     
    },
    
    fetchPolicy: "no-cache",
  })
  const [getPagineRoute, pagineRoute] = useLazyQuery(
    fetchCompletedRoutes,
    {
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
        limit: numeberPagination,
        offset: 0,
      },
      
      fetchPolicy: "no-cache",
    }
    
  ); 
  const [getRoutesForRouteBatch, dataroutesForRouteBatch] = useLazyQuery(
    dataRoutesForRouteBatch,
    {
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
      },
      fetchPolicy: "no-cache",
    }
  );
  const [getTelemetry, dataTelemetry] = useLazyQuery(fetchtelemetry, {
    fetchPolicy: "no-cache",
  });
  const [polylines, setPolylines] = useState("");
  const [routesBatch, setRoutesBatch] = useState([]);
  const [routesBatchComplete, setRoutesBatchComplete] = useState([]);
  const [dataFilterRoutesBatch, setDataFilterRoutesBatch] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedOrderTable, setSelectedOrderTable] = useState([]);
  const [lines, setLines] = useState([]);
  const [filter, setFilter] = useState("");
  const [averageSpeed, setAverageSpeed] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [currentPageSecond, setCurrentPageSecond] = useState(1);
  const [pageSizeSecond, setPageSizeSecond] = useState(5);
  const [currentPageTree, setCurrentPageTree] = useState(1);
  const [filterdataFirst, setFilterdataFirst] = useState([]);
  const [filterdataFirstRoute, setFilterdataFirstRoute] = useState([]);
 
  const [pageSizeTree, setPageSizeTree] = useState(10);
  const [activeHeader, setActiveHeader] = useState(false);
  const [activeHeadeget, setActiveHeaderget] = useState(true);




  useEffect(() => {
    if (
      telemetryUserVehicle?.length > 0 &&
      telemetryOrderTablestartDateTime?.length > 0 &&
      telemetryOrderTableEndDateTime?.length > 0
    ) {
      const telemetryOrderTableStartDateTimeUTC = moment(
        moment.utc(telemetryOrderTablestartDateTime)
      )
        .add(5, "hours")
        .format("YYYY-MM-DDTHH:mm:ss+00:00");
      const telemetryOrderTableEndDateTimeUTC = moment(
        moment.utc(telemetryOrderTableEndDateTime)
      )
        .add(5, "hours")
        .format("YYYY-MM-DDTHH:mm:ss+00:00");

      getTelemetry({
        variables: {
          userVehicleId: telemetryUserVehicle,
          startDateTime: telemetryOrderTableStartDateTimeUTC,
          endDateTime: telemetryOrderTableEndDateTimeUTC,
        },
      });
    }
  }, [
    telemetryUserVehicle,
    telemetryOrderTablestartDateTime,
    telemetryOrderTableEndDateTime,
  ]);

  useEffect(() => {
    if (dataTelemetry.data?.telemetry?.length > 0) {
      const distanceSum = dataTelemetry.data?.telemetry.reduce(
        (acc, curr) => acc + curr.distance,
        0
      );
      setTotalDistance(distanceSum);

      const speedSum = dataTelemetry.data?.telemetry.reduce(
        (acc, curr) => acc + curr.speed,
        0
      );
      const speedAverage = speedSum / dataTelemetry.data?.telemetry.length;
      setAverageSpeed(Math.round(speedAverage));

      setVisibleTelemetry(true);
    } else {
      setVisibleTelemetry(false);
    }
  }, [dataTelemetry.data, dataTelemetry.loading]);

  useEffect(() => {
    const warehouses = JSON.parse(
      localStorageService.get("selectedWarehouses")
    )?.warehouses;
    setSelectedWarehouse(warehouses);
  }, [props.flag]);
  
  useEffect(() => {
    getCompletedRoutes();
    getRoutesForRouteBatch();
    getPagineRoute();
    getCompletedRoutesNumber();
  }, []);

  useEffect(() => {
    
    setFilter(
      localStorage.getItem("searchTarget") &&
        localStorage.getItem("searchTarget").length > 0
        ? localStorage.getItem("searchTarget")
        : ""
    );
    if(localStorage.getItem("searchTarget").length === 0 )
    {
      setFilterdataFirstRoute([])
      setDataFilterRoutesBatch(routesBatch)
      setTelemetryUserVehicle(dataTelemetry)
    }

  }, [props.flag]);

  useEffect(() => {
    
    if(dataroutesForRouteBatch && dataroutesForRouteBatch.data && dataroutesForRouteBatch.data?.routes.length > 0)
    {
      setFilterdataFirst(dataroutesForRouteBatch.data?.routes)
    }

  },[dataroutesForRouteBatch, dataroutesForRouteBatch.data]);


  useEffect(() => {
    let selectedData = null;

    if (pagineRoute.data && dataCompletedRoutes.data) {
        if (pagineRoute.data?.routesBatch.length >= dataCompletedRoutes.data?.routesBatch.length) {
            selectedData = pagineRoute.data.routesBatch;
        } else {
          selectedData = dataCompletedRoutes.data.routesBatch;
        }
    } else if (pagineRoute.data) {
        selectedData = pagineRoute.data.routesBatch;
    } else if (dataCompletedRoutes.data) {
        selectedData = dataCompletedRoutes.data.routesBatch;
    }

    if (selectedData && selectedData.length > 0) {
        const newRoutesBatch = selectedData.map((item) => {
            const {
                id,
                batch,
                createdAt,
                startDateRoute,
                endDateRoute,
                routes,
                licencePlate,
                user,
            } = item;

            return {
                id,
                batch,
                createdAt,
                startDateRoute,
                endDateRoute,
                routes,
                driverName: user.displayName,
                planner: routes[0]?.createByUser?.displayName,
                licencePlate,
            };
        });

        setRoutesBatch(newRoutesBatch);
        
    } else {
        setRoutesBatch([]);
    }
  }, [pagineRoute.loading, pagineRoute.data, dataCompletedRoutes.loading, dataCompletedRoutes.data]);
  //console.log(routesBatch)

  const headerColumns = [
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "Creación",
      className: "table-column",
      align: "center",
      render: (value) => {
        let date = moment.utc(value);
        let localTime = moment(date).local().format("DD/MM/YYYY h:mm a");
    
        return <span>{localTime}</span>;
      },
      ...getColumnSearchProps("createdAt"),
    },
    {
      key: "planner",
      dataIndex: "planner",
      title: "Planificador",
      className: "table-column",
      align: "center",
      render: (value) => {
        return <span>{value}</span>;
      },
      ...getColumnSearchPropsText("Planificador", "displayName"),
    },
    {
      key: "licencePlate",
      dataIndex: "licencePlate",
      className: "table-column",
      title: "Placa",
      render: (value) => {
        return <span>{value}</span>;
      },
      ...getColumnSearchPropsText("Placa", "licencePlate"),
    },
    {
      key: "driverName",
      dataIndex: "driverName",
      className: "table-column",
      title: "Conductor",
      render: (value) => {
        return <span>{value}</span>;
      },
      ...getColumnSearchPropsText("Conductor", "driverName"),
    },
    {
      key: "startDateRoute",
      dataIndex: "startDateRoute",
      title: "Inicio de Ruta",
      className: "table-column",
      align: "center",
      render: (value) => {
        let date = moment.utc(value);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");
    
        return <span>{localTime}</span>;
      },
      ...getColumnSearchProps("startDateRoute"),
    },
    {
          key: "endDateRoute",
          dataIndex: "endDateRoute",
          title: "Fin de Ruta",
          className: "table-column",
          align: "center",
          render: (value) => {
            let date = moment.utc(value);
            let localTime = moment(date).format("DD/MM/YYYY h:mm a");
    
            return <span>{localTime}</span>;
          },
          ...getColumnSearchProps("endDateRoute"),
    }    
  ];

  const detailsColumns = [
    {
      key: "orderNumber",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Orden",
      render: (record) => {
        return <span>{record?.orderNumber}</span>;
      },
      ...getColumnSearchPropsText("Orden", "orderTable.orderNumber"),
    },
    {
      key: "type",
      dataIndex: "orderTable",
      title: "Tipo",
      className: "table-column",
      align: "center",
      render: (record) => {
        return (
          <span>
            {record?.type === "Envío" || record?.type === "delivery"
              ? "Envío"
              : record?.type === "pickup"
              ? "Recogida"
              : record?.type === "customService"
              ? "Servicio Personalizado"
              : record?.type === "warehousesTransfer"
              ? "Traslado entre Bodegas"
              : ""}
          </span>
        );
      },
    },
    {
      key: "custFullName",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Cliente",
      render: (record) => {
        return <span>{record?.custFullName}</span>;
      },
    },
    {
      key: "status",
      dataIndex: "status",
      title: "Estado",
      className: "table-column",
      render: (value) => {
        let status = "";
        switch (value) {
          case "none":
            status = "Ninguno";
            break;
          case "start":
            status = "Ninguno";
            break;
          case "started":
            status = "En reparto";
            break;
          case "delivered":
            status = "Completamente Entregado";
            break;
          case "undelivered":
            status = "Devuelto";
            break;
          case "partial_delivered":
            status = "Con Novedad";
            break;
          case "rescheduled_delivery":
            status = "Entrega Reprogramada";
            break;
          default:
            status = "Ninguno";
            break;
        }
    
        return status;
      },
    },
    {
      key: "description",
      dataIndex: "description",
      title: "Motivo",
      className: "table-column",
      render: (_, record) => {
        return <span>{record?.reason?.description}</span>;
      },
    },
    {
      key: "consecutiveBurden",
      dataIndex: "orderTable",
      title: "Carga",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveBurden}</span>;
      },
    },
    {
      key: "consecutiveBill",
      dataIndex: "orderTable",
      title: "Factura",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveBill}</span>;
      },
    },
    {
      key: "consecutiveRemission",
      dataIndex: "orderTable",
      title: "Remisión",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveRemission}</span>;
      },
    },
    {
      key: "consecutiveSaleOrder",
      dataIndex: "orderTable",
      title: "Orden/TRN",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveSaleOrder}</span>;
      },
    },
    {
      key: "receivedPerson",
      dataIndex: "receivedPerson",
      title: "Nombre",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      key: "receivedDocument",
      dataIndex: "receivedDocument",
      title: "Documento",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      key: "notes",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Notas",
      render: (record) => {
        return <span>{record?.notes}</span>;
      },
    },
    {
      key: "paymentMethod",
      dataIndex: "orderTable",
      title: "Metodo de Pago",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.paymentMethod}</span>;
      },
    },
    {
      key: "state",
      dataIndex: "orderTable",
      title: "Departamento",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.state}</span>;
      },
    },
    {
      key: "city",
      dataIndex: "orderTable",
      title: "Ciudad",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.city}</span>;
      },
    },
    {
      key: "address",
      dataIndex: "orderTable",
      title: "Dirección",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.address}</span>;
      },
    },
    {
      key: "distanceText",
      dataIndex: "distanceText",
      title: "Distancia",
      className: "table-column",
      align: "center",
    },
    {
      key: "durationText",
      dataIndex: "durationText",
      title: "Duración",
      className: "table-column",
    },
    {
      key: "expectedDeliveryStartTime",
      dataIndex: "orderTable",
      title: "Fecha inicio compromiso de entrega ",
      className: "table-column",
      align: "center",
      render: (record) => {
        let date = moment.utc(record?.expectedDeliveryStartTime);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");
    
        return (
          <span>{localTime !== "Fecha inválida" ? localTime : "N/D"}</span>
        );
      },
    },
    {
      key: "expectedDeliveryEndTime",
      dataIndex: "orderTable",
      title: "Fecha fin compromiso de entrega",
      className: "table-column",
      align: "center",
      render: (record) => {
        let date = moment.utc(record?.expectedDeliveryEndTime);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");
    
        return (
          <span>{localTime !== "Fecha inválida" ? localTime : "N/D"}</span>
        );
      },
    },
    {
      key: "startDateTime",
      dataIndex: "startDateTime",
      title: "Inicio",
      className: "table-column",
      align: "center",
      render: (value) => {
        let date = moment.utc(value);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");
    
        return <span>{localTime}</span>;
      },
    },
    {
      key: "endDateTime",
      dataIndex: "endDateTime",
      title: "Fin",
      className: "table-column",
      align: "center",
      render: (value) => {
        let date = moment.utc(value);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");
    
        return <span>{localTime}</span>;
      },
    },
    {
      key: "consecutiveShipping",
      dataIndex: "orderTable",
      title: "Envío",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveShipping}</span>;
      },
    },    
  ];

  const linesColumns = [
    {
      key: "productNumber",
      dataIndex: "productNumber",
      title: "Código",
      className: "table-column",
      align: "center",
      className: "table-column",
    },
    {
      key: "productName",
      dataIndex: "productName",
      className: "table-column",
      title: "Nombre",
    },
    {
      key: "initialQuantity",
      dataIndex: "initialQuantity",
      title: "Cantidad",
      className: "table-column",
      align: "right",
    },
    {
      key: "summationQuantity",
      dataIndex: "summationQuantity",
      title: "Entregado",
      className: "table-column",
      align: "right",
    },
  ];

  
  const rowSelectionHeader = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDetails(selectedRows[0].routes);
      FilterRouteBathcDetails(selectedRows[0].routes)
      
      setTelemetryUserVehicle(
        selectedRows[0]?.routes[0]?.user?.userVehicle?.id
      );
      setActiveHeader(true)
    },
  };

  const rowSelectionDetails = {
    onChange: (selectedRowKeys, selectedRows) => {
      const records = [];
      selectedRows[0].orderTable.ordersLines.map((item) => {
        const {
          id,
          productNumber,
          productName,
          initialQuantity,
          summationQuantity,
        } = item;

        return records.push({
          id,
          productNumber,
          productName,
          initialQuantity,
          summationQuantity
        });
      });

      setLines(records);
      setSelectedOrderTable(selectedRows[0].orderTable);

      setPolylines(selectedRows[0].polylines);
      setTelemetryOrderTablestartDateTime(selectedRows[0].startDateTime);
      setTelemetryOrderTableEndDateTime(selectedRows[0].endDateTime);
      setTableResponsive(true);
      if(activeHeader === false || filter.length > 0)
      {
        FilterRouteBathc(selectedRows[0].batch, selectedRows[0]?.user?.userVehicle?.id)

      }
      
    },
  };


  useEffect(() => {
    setNumeberPagination(currentPage * pageSize);
  }, [currentPage, pageSize]);
  

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    setNumeberPagination(pageSize * page)
    
    
  };
  const handlePageChangeSecond = (page, pageSize) => {
    setCurrentPageSecond(page);
    setPageSizeSecond(pageSize);
  };
  const handlePageChangeTree = (page, pageSize) => {
    setCurrentPageTree(page);
    setPageSizeTree(pageSize);
  };
  let filteredDataRouteBatch
  const FilterRouteBathc = (dataBatch, dataTelemetry) => {
    console.log(filter.length)
    

    if(filter.length > 0)
    {
      filteredDataRouteBatch =  routesBatch.filter(
      (item) => item.batch === dataBatch);
      setDataFilterRoutesBatch(filteredDataRouteBatch);
      setActiveHeaderget(false)
      setTelemetryUserVehicle(dataTelemetry)
    }
    else{
      setDataFilterRoutesBatch(routesBatch)
      setTelemetryUserVehicle(dataTelemetry)
    }

  
    
  }
  ///
  const FilterRouteBathcDetails = (dataRoute) => {
    //console.log(dataRoute)
    let _dataRoute = []
    for (let i = 0; i < dataRoute.length; i++) {
      const data = dataRoute[i];
      //console.log(data)
      const filterRoute = dataroutesForRouteBatch.data?.routes.filter((item) => item.id === data.id)
      //console.log(filterRoute)
      _dataRoute = _dataRoute.concat(filterRoute);
    }
    //console.log(_dataRoute)
    setFilterdataFirstRoute(_dataRoute);


    

  }
  ///
  //console.log(routesBatch)
  const filteredData =
    filter.length > 0
    ? routesBatch.filter(
          (item) =>
            item.licencePlate.toUpperCase().includes(filter.toUpperCase()) ||
            item.driverName.toUpperCase().includes(filter.toUpperCase())
        )
      : routesBatch;  
  const filteredDataSecond =
  filter.length > 0
  ? filterdataFirst?.filter((item) =>{
    if (
      item.orderTable?.address
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }

    if (
      item.orderTable?.addressComplement
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }

    if (
      item.orderTable?.custFullName
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }

    if (
      item.orderTable?.custIdentificationNumber
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }

    if (
      item.orderTable?.orderNumber
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.consecutiveBill
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.consecutiveRemission
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.consecutiveSaleOrder
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.consecutiveBurden
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.consecutiveShipping
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.status
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.city?.toUpperCase().includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.state
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.type?.toUpperCase().includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.routes?.receivedPerson
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.routes?.receivedDocument
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.expectedDeliveryStartTime
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    if (
      item.orderTable?.expectedDeliveryEndTime
        ?.toUpperCase()
        .includes(filter?.toUpperCase())
    ) {
      return true;
    }
    return false;
  }): details;
          
  const paginatedData = dataFilterRoutesBatch?.length > 0  ? dataFilterRoutesBatch.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
    //console.log("entro aca 1 primero ")
  ) :filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
    //console.log("entro aca 2 primero ")
  );
  //console.log(paginatedData)

  let paginatedDataSecond = []
  if(filteredDataSecond !== undefined){
   /// console.log(dataFilterRoutesBatch) 
    
    paginatedDataSecond = filterdataFirstRoute?.length > 0  ? filterdataFirstRoute.slice(
    
    (currentPageSecond - 1) * pageSizeSecond,
    currentPageSecond * pageSizeSecond,
    
   
    ) :filteredDataSecond.slice(
    (currentPageSecond - 1) * pageSizeSecond,
    currentPageSecond * pageSizeSecond,
  

  )};
  
  const paginatedDataTree = lines.slice(
    (currentPageTree - 1) * pageSizeTree,
    currentPageTree * pageSizeTree
  );

  //console.log(dataTelemetry.data?.telemetry)
  const telemetry = () => {
    if (!dataTelemetry.data?.telemetry || dataTelemetry.data.telemetry.length === 0) {
      return "No hay suficientes datos para calcular.";
    }
  
    let totalDistance = 0;
    let totalSpeed = 0;
    let count = 0;
  
    let lastData = dataTelemetry.data.telemetry[0];
    for (let i = 1; i < dataTelemetry.data.telemetry.length; i++) {
      const currentData = dataTelemetry.data.telemetry[i];
  
      const distance = geolib.getDistance(
        { latitude: lastData.latitude, longitude: lastData.longitude },
        { latitude: currentData.latitude, longitude: currentData.longitude }
      );
  
      // Si la distancia es mayor a 10 metros
      if (distance > 10) {
        const time = moment(currentData.timestamp).diff(moment(lastData.timestamp), "seconds");
        if (time > 0) {
          const speed = (distance / time) * 3.6; // Convertir de m/s a km/h
          totalSpeed += speed;
          totalDistance += distance;
          count++;
        }
      }
  
      lastData = currentData;
    }
  
    const averageSpeed = count > 0 ? totalSpeed / count : 0;
    return `Velocidad promedio: ${averageSpeed.toFixed(2)} Km/h - Distancia recorrida: ${totalDistance} Mts`;
  };
  return (
    <Col className="children-inner-container-with-bg">
      <Row>
        <Col span={8}>
        <Skeleton loading={dataCompletedRoutesNumber.loading} active>
            <Table
              className={
                tableResponsive === true
                  ? "children-table-3"
                  : "children-table-100"
              }
              columns={headerColumns}
              dataSource={paginatedData}
              rowKey="batch"
              pagination={false}
              rowSelection={{
                type: "radio",
                ...rowSelectionHeader,
              }}
            />
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={routesBatch.length}
              
              onChange={handlePageChange}
            />
          </Skeleton>
        </Col>
        <Col span={8}>
        <Skeleton loading={dataCompletedRoutesNumber.loading} active>
            <Table
              className={
                tableResponsive === true
                  ? "children-table-3"
                  : "children-table-100"
              }
              columns={detailsColumns}
              dataSource={paginatedDataSecond}
              pagination={false}
              rowKey="id"
              rowSelection={{
                type: "radio",
                ...rowSelectionDetails,
              }}
            />
            <Pagination
              current={currentPageSecond}
              pageSize={pageSizeSecond}
              total={routesBatch.length}
              onChange={handlePageChangeSecond}
            />
          </Skeleton>
        </Col>
        <Col span={8}>
          <Row>
          <Skeleton loading={dataCompletedRoutesNumber.loading} active>
              <Table
                className={
                  tableResponsive === true
                    ? "children-table-3"
                    : "children-table-100"
                }
                columns={linesColumns}
                dataSource={paginatedDataTree}
                pagination={false}
                rowKey="id"
              />
              <Pagination
                current={currentPageTree}
                pageSize={pageSizeTree}
                total={routesBatch.length}
                onChange={handlePageChangeTree}
              />
            </Skeleton>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Row>
            {selectedOrderTable?.evidences?.length > 0 && (
              <Col span={24}>
                <h2>Evidencias</h2>
                <div>
                  <h4>
                    Comentarios: {selectedOrderTable.evidences[0].comments}
                  </h4>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: "inline-flex",
                overflowX: "scroll",
                height: "auto",
                maxWidth: "100%",
                width: "100%",
              }}
            >
              {selectedOrderTable?.evidences?.map((item, index) => {
                return (
                  <span key={`span-${index}`} style={{ padding: "3px" }}>
                    <Card
                      key={`card-${index}`}
                      hoverable
                      style={{ width: "300px", minWidth: "300px" }}
                      cover={
                        <Image
                          style={{
                            borderRadius: "10px 10px 0 0",
                            objectFit:
                              item.evidenceType === "FIRMA"
                                ? "contain"
                                : "cover",
                          }}
                          key={`image-${index}`}
                          width={300}
                          height={300}
                          src={item.evidenceURL}
                        />
                      }
                    >
                      <Card.Meta
                        key={`cardmeta-${index}`}
                        description={item.evidenceType}
                      />
                    </Card>
                  </span>
                );
              })}
            </Col>
          </Row>
        </Col>
        {visibleTelemetry === true ? (
          <Col offset={1} span={11}>
            <Row>
              <Col span={24}>
                <h2>Ruta realizada</h2>
                <div>
                  <h4>
                    {telemetry()}
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DeliveryRoute
                  dataTelemetry={dataTelemetry}
                  visibleTelemetry={visibleTelemetry}
                  polylines={polylines}
                />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
    </Col>
  );
};

import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { Card, CardContent } from "@mui/material";
import { ConfigurationMoreSettings } from "../../../ConfigurationMoreSettings";
import { useMutation, useLazyQuery } from "@apollo/client";
import Swal from "sweetalert2";
import {
  updateConfigureOptions,
  queryConfigureOptions,
} from "../../queries/configureOptions";

import localStorageService from "../../../../../services/localStorageService";

const userId = localStorageService.get("id");

export const ConfigureOptions = () => {
  const [UptConfigureOptions] = useMutation(updateConfigureOptions, {
    fetchPolicy: "no-cache",
  });
  const [getConfigureOptions, dataConfigureOptions] = useLazyQuery(
    queryConfigureOptions,
    { fetchPolicy: "no-cache" }
  );

  const [isSelectActiveShippingType, setIsSelectActiveShippingType] =
    useState(false);
  const [
    isSelectActiveSpecialServiceType,
    setIsSelectActiveSpecialServiceType,
  ] = useState(false);
  const [isSelectActivePickupType, setIsSelectActivePickupType] =
    useState(false);
  const [
    isSelectActiveDeliveryBetweenBoodegasType,
    setIsSelectActiveDeliveryBetweenBoodegasType,
  ] = useState(false);
  const [isSelectActiveTMS_Synchronizer, setIsSelectActiveTMS_Synchronizer] =
    useState(false);
  const [data, setData] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    getConfigureOptions();
  }, []);

  useEffect(() => {
    if (
      dataConfigureOptions &&
      dataConfigureOptions.data &&
      dataConfigureOptions.data.configureOptions.length > 0
    ) {
      const data = dataConfigureOptions.data.configureOptions[0];
      setData(data);
      InitialConfi(data);
    }
  }, [dataConfigureOptions, dataConfigureOptions.data]);

  const InitialConfi = (data) => {
    if (data.shippingType === true) {
      setIsSelectActiveShippingType(true);
    }
    if (data.specialServiceType === true) {
      setIsSelectActiveSpecialServiceType(true);
    }
    if (data.pickupType === true) {
      setIsSelectActivePickupType(true);
    }
    if (data.deliveryBetweenBoodegasType === true) {
      setIsSelectActiveDeliveryBetweenBoodegasType(true);
    }
    if (data.TMS_Synchronizer === true) {
      setIsSelectActiveTMS_Synchronizer(true);
    }
  };
  const formItemLayout = {
    labelCol: {
      lg: { span: 8 },
    },
    wrapperCol: {
      lg: { span: 15 },
    },
  };
  const handlSave = async () => {
    const instHistoricalOrdersTable = await UptConfigureOptions({
      variables: {
        id: "683efb8f-df39-418a-a924-2050ff328cd3",
        shippingType: isSelectActiveShippingType,
        specialServiceType: isSelectActiveSpecialServiceType,
        pickupType: isSelectActivePickupType,
        deliveryBetweenBoodegasType: isSelectActiveDeliveryBetweenBoodegasType,
        TMS_Synchronizer: isSelectActiveTMS_Synchronizer,
        userId: userId,
      },
      fetchPolicy: "no-cache",
    });
    if (
      instHistoricalOrdersTable.data.update_configureOptions.affected_rows > 0
    ) {
      Swal.fire({
        title: "Actualización Exitosa",
        text: `Parametros Actualizados Correctamente`,
        icon: "success",
        confirmButtonText: "Cerrar",
      });
    }
  };
  return (
    <div className="children-inner-container-with-bg-col">
      {/* <ConfigurationMoreSettings /> */}
      <Card
        key="card"
        title={`Configuración de Opciones de Vista`}
        style={{ marginBottom: "10px", minHeight: "0%" }}
        size="small"
      >
        <CardContent>
          <Form
            {...formItemLayout}
            key="form"
            form={form}
            name="configuration-users"
            onClose={() => {}}
          >
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name="shippingType"
              labelAlign="left"
              label="Tipo Envio en Carga Manual"
              hasFeedback
            >
              <Checkbox
                checked={
                  data.shippingType === true
                    ? isSelectActiveShippingType
                    : isSelectActiveShippingType
                }
                onChange={(value) => {
                  setIsSelectActiveShippingType(value.target.checked);
                  form.setFieldsValue({
                    shippingType: value.target.checked,
                  });
                }}
              ></Checkbox>
            </Form.Item>
            <Form.Item
              name="specialServiceType"
              labelAlign="left"
              label="Tipo Servicio Especial en Carga Manual"
              hasFeedback
            >
              <Checkbox
                checked={
                  data.specialServiceType === true
                    ? isSelectActiveSpecialServiceType
                    : isSelectActiveSpecialServiceType
                }
                onChange={(value) => {
                  setIsSelectActiveSpecialServiceType(value.target.checked);
                  form.setFieldsValue({
                    specialServiceType: value.target.checked,
                  });
                }}
              ></Checkbox>
            </Form.Item>
            <Form.Item
              labelAlign="left"
              hasFeedback
              name="pickupType"
              label="Tipo Recogida en Carga Manual"
            >
              <Checkbox
                checked={
                  data.pickupType === true
                    ? isSelectActivePickupType
                    : isSelectActivePickupType
                }
                onChange={(value) => {
                  setIsSelectActivePickupType(value.target.checked);
                  form.setFieldsValue({
                    pickupType: value.target.checked,
                  });
                }}
              ></Checkbox>
            </Form.Item>
            <Form.Item
              name="deliveryBetweenBoodegasType"
              labelAlign="left"
              label="Tipo TRN en Carga Manual"
              hasFeedback
            >
              <Checkbox
                checked={
                  data.isSelectActiveDeliveryBetweenBoodegasType === true
                    ? isSelectActiveDeliveryBetweenBoodegasType
                    : isSelectActiveDeliveryBetweenBoodegasType
                }
                onChange={(value) => {
                  setIsSelectActiveDeliveryBetweenBoodegasType(
                    value.target.checked
                  );
                  form.setFieldsValue({
                    deliveryBetweenBoodegasType: value.target.checked,
                  });
                }}
              ></Checkbox>
            </Form.Item>
            <Form.Item
              name="TMS_Synchronizer"
              labelAlign="left"
              label="TMS Sincronizador"
              hasFeedback
            >
              <Checkbox
                checked={
                  data.TMS_Synchronizer === true
                    ? isSelectActiveTMS_Synchronizer
                    : isSelectActiveTMS_Synchronizer
                }
                onChange={(value) => {
                  setIsSelectActiveTMS_Synchronizer(value.target.checked);
                  form.setFieldsValue({
                    TMS_Synchronizer: value.target.checked,
                  });
                }}
              ></Checkbox>
            </Form.Item>
          </Form>
          <Button
            style={{ marginTop: 10 }}
            type="primary"
            shape="round"
            onClick={() => {
              handlSave();
            }}
          >
            Guardar
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

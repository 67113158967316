import React, { useEffect, useState } from "react";
import packageJson from "../../../../../package.json";
import { But, Card, CardContent, Grid, Stack } from "@mui/material";
//import { Button, Col, Row, Card, Space } from "antd";
import { useTranslation } from "react-i18next";
import "./../../../../i18n";
// import {
//   CarOutlined,
//   IdcardOutlined,
//   DropboxOutlined,
//   InboxOutlined,
//   TeamOutlined,
//   EnvironmentOutlined,
//   WarningOutlined,
//   InfoOutlined,
//   SettingOutlined,
//   AlertOutlined,
//   BarsOutlined,
//   BgColorsOutlined,
//   OrderedListOutlined,
//   SendOutlined,
//   CheckOutlined,
// } from "@ant-design/icons";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import UpcomingOutlinedIcon from "@mui/icons-material/UpcomingOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import TakeoutDiningOutlinedIcon from "@mui/icons-material/TakeoutDiningOutlined";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import FormatColorFillOutlinedIcon from "@mui/icons-material/FormatColorFillOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Link, useLocation, Route, Switch } from "react-router-dom";
import "../styles/ConfigurationSelectorScreen.css";
import localStorageService from "../../../../services/localStorageService";
import { MapSectorsSreen } from "../../../sectors/ui/screens/MapSectorsSreen";
import { IncidentsScreen } from "../../../configuration/incidents/ui/screens/IncidentsScreen";
import { IncidentsCausesScreen } from "../../../configuration/incidents-causes/ui/screens/IncidentsCausesScreen";
import { IncidentManagementScreen } from "../../../configuration/incident-management/ui/screens/IncidentManagementScreen";
import { ReasonsScreen } from "../../../configuration/reasons/ui/screens/ReasonsScreen";
import { UsersScreen } from "../../../configuration/users/ui/screens/UsersScreen";
import { VehicleBrandsScreen } from "../../../../components/configuration/vehicle-brands/ui/screens/VehicleBrandsScreen";
import { TransportCompaniesScreen } from "../../../../components/configuration/transportation-companies/ui/screens/TransportCompaniesScreen";
import { VehiclesScreen } from "../../../../components/configuration/vehicles/ui/screens/VehiclesScreen";
import { DistributionCentersScreen } from "../../../../components/configuration/distribution-centers/ui/screens/DistributionCentersScreen";
import { ClientsScreen } from "../../../../components/configuration/clients/ui/screens/ClientsScreen";
import { PackagesScreen } from "../../../../components/configuration/packages/ui/screens/PackagesScreen";
import { SequencesNumbersScreen } from "../../../../components/configuration/sequences-numbers/ui/screens/SequencesNumbersScreen";
import { ReleaseOrders } from "../../../../components/configuration/releaseOrders/ui/screen/releaseOrders";
import { BrandingScreen } from "../../../../components/configuration/branding/ui/screens/BrandingScreen";
import { ConfigureOptions } from "../../../../components/configuration/configureOptions/ui/screens/configureOptions";

const UserRole = localStorageService.get("role");

export const ConfigurationSelectorScreen2 = () => {
  const { t } = useTranslation("global");
  const location = useLocation();

  const menuItems = [
    {
      key: "/sectors",
      icon: <PlaceOutlinedIcon />,
      label: t("modules.configuration.submodules.zones"),
      component: <MapSectorsSreen />,
    },

    {
      key: "/incidents-config",
      icon: <InfoOutlinedIcon />,
      label: t("modules.configuration.submodules.incidents"),
      component: <IncidentsScreen />,
    },
    {
      key: "/incidents-causes-config",
      icon: <WarningAmberOutlinedIcon />,
      label: t("modules.configuration.submodules.causes"),
      component: <IncidentsCausesScreen />,
    },
    {
      key: "/incident-management-config",
      icon: <SettingsOutlinedIcon />,
      label: t("modules.configuration.submodules.management"),
      component: <IncidentManagementScreen />,
    },
    {
      key: "/reasons",
      icon: <UpcomingOutlinedIcon />,
      label: t("modules.configuration.submodules.reasons"),
      component: <ReasonsScreen />,
    },
    {
      key: "/users",
      icon: <PeopleAltOutlinedIcon />,
      label: t("modules.configuration.submodules.users"),
      component: <UsersScreen />,
    },
    {
      key: "/vehicle-brands",
      icon: <DirectionsCarOutlinedIcon />,
      label: t("modules.configuration.submodules.vehicle_brands"),
      component: <VehicleBrandsScreen />,
    },
    {
      key: "/transport-companies",
      icon: <LocalShippingOutlinedIcon />,
      label: t("modules.configuration.submodules.carriers"),
      component: <TransportCompaniesScreen />,
    },
    {
      key: "/vehicles",
      icon: <AirportShuttleOutlinedIcon />,
      label: t("modules.configuration.submodules.vehicles"),
      component: <VehiclesScreen />,
    },
    {
      key: "/distribution-centers",
      icon: <InboxOutlinedIcon />,
      label: t("modules.configuration.submodules.distribution_centers"),
      component: <DistributionCentersScreen />,
    },
    {
      key: "/clients",
      icon: <BadgeOutlinedIcon />,
      label: t("modules.configuration.submodules.clients"),
      component: <ClientsScreen />,
    },
    {
      key: "/packages",
      icon: <TakeoutDiningOutlinedIcon />,
      label: t("modules.configuration.submodules.packages"),
      component: <PackagesScreen />,
    },
    {
      key: "/sequences-numbers",
      icon: <FormatListNumberedOutlinedIcon />,
      label: t("modules.configuration.submodules.numeric_sequences"),
      component: <SequencesNumbersScreen />,
    },
    {
      key: "/company-branding",
      icon: <FormatColorFillOutlinedIcon />,
      label: t("modules.configuration.submodules.design"),
      component: <BrandingScreen />,
    },
    {
      key: "/releaseOrders",
      icon: <SendOutlinedIcon />,
      label: t("modules.configuration.submodules.released_orders"),
      component: <ReleaseOrders />,
    },
    {
      key: "/configureOptions",
      icon: <CheckOutlinedIcon />,
      label: t("modules.configuration.submodules.system_parameters"),
      component: <ConfigureOptions />,
    },
  ];

  const [selectedKey, setSelectedKey] = useState(menuItems[0].key);
  const [activeComponent, setActiveComponent] = useState(
    menuItems[0].component
  );

  useEffect(() => {
    const cardScrollbars = document.querySelectorAll(".card-scrollbar");

    cardScrollbars.forEach((card) => {
      let isMouseDom = false;
      let startY;
      let starScrollTop;

      card.addEventListener("mousedown", (event) => {
        isMouseDom = true;
        startY = event.clientY;
        starScrollTop = card.scrollTop;
      });

      card.addEventListener("mouseup", () => {
        isMouseDom = false;
      });

      card.addEventListener("mousemove", (event) => {
        if (!isMouseDom) return;

        const deltaY = event.clientY - startY;
        card.scrollTop = starScrollTop - deltaY;
      });

      card.addEventListener("mouseleave", () => {
        isMouseDom = false;
      });
    });
  }, []);

  const handleClick = (key, component) => {
    // console.log("component", component);
    // console.log("Key", key);
    setSelectedKey(key);
    setActiveComponent(component);
  };

  return (
    <div className="container-div">
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Card
            className="card-scrollbar"
            style={{
              overflow: "auto",
              height: "calc(100vh - 118px)",
              borderRadius: "20px",
            }}

            // sx={{
            //   overflow: "auto",
            //   //width: "13vw",
            //   height: "calc(100vh - 200px)",
            // }}
          >
            <CardContent>
              <Stack direction="column" spacing={2}>
                {menuItems.map((item) => (
                  <Card
                    key={item.key}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "70px",
                      backgroundColor:
                        selectedKey === item.key
                          ? "var(--primaryColor)"
                          : "transparent",
                      color: selectedKey === item.key ? "white" : "black",
                      cursor: "pointer",
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                    }}
                    style={{ borderRadius: "15px" }}
                    onClick={() => handleClick(item.key, item.component)}
                  >
                    {React.cloneElement(item.icon, {
                      style: {
                        ...item.icon.props.style,
                        marginLeft: "10px",
                        color: selectedKey === item.key ? "white" : "black",
                        fontSize: "30px",
                        //marginTop: "10px",
                        //maxWidth: "80%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        witheSpace: "nowrap",
                      },
                    })}
                    <span
                      style={{
                        marginLeft: "10px",
                        color: selectedKey === item.key ? "white" : "black",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {item.label}
                    </span>
                  </Card>
                ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={10}>
          <Card style={{ borderRadius: "20px" }}>
            <CardContent className="muiCardContent-root">
              {activeComponent}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

import React from "react";
import SyncIcon from "@mui/icons-material/Sync";
import DownloadIcon from "@mui/icons-material/Download";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Swal from "sweetalert2";
import { useState } from "react";
import axios from "axios";
import {
  insertOrdersTable,
  insertOrdersLine,
  fetchclients,
  InsertClients,
  InsertClientAddresses,
  insertClient,
  checkClientExists,
  UpdateClients,
} from "../../../TMS-Dynamics/queries/TMSqueries";
import calculateGeoEnclosure from "../../../../../../../services/geo-enclosure";
import localStorageService from "../../../../../../../services/localStorageService";
import loggerCooltrack from "../../../../../../../services/logger-cooltrack";
import { useLazyQuery, useMutation } from "@apollo/client";

const companyId = localStorageService.get("companyId");
const userId = localStorageService.get("id");

const ActionButtons = (props) => {
  const {
    isShippingSelected,
    isLoadSelected,
    selecteLoad,
    selectedShipment,
    sectors,
    distributionCenters,
    listShipping,
    dataOrderRunningg,
    dataOrderRunning,
    setListShipping,
    handleSelectShipping,
    setIsLoadSelected,
  } = props;
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [previousOrderNumberList, setPreviousOrderNumberList] = useState([]);
  const [packingSlip, setPackingSlip] = useState("");
  const [invoce, setInvoce] = useState("");

  const [insOrdersTable] = useMutation(insertOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [insOrdersLine] = useMutation(insertOrdersLine, {
    fetchPolicy: "no-cache",
  });
  const [insClients] = useMutation(InsertClients, {
    fetchPolicy: "no-cache",
  });
  const [updateClients] = useMutation(UpdateClients, {
    fetchPolicy: "no-cache",
  });

  const [insClientAddresses] = useMutation(InsertClientAddresses, {
    fetchPolicy: "no-cache",
  });
  const [getClients, dataClients] = useLazyQuery(fetchclients, {
    fetchPolicy: "no-cache",
  });

  const [createClientNew] = useMutation(insertClient, {
    fetchPolicy: "no-cache",
  });

  const [getCheckCliente, verficationExistClient] = useLazyQuery(
    checkClientExists,
    {
      fetchPolicy: "no-cache",
    }
  );
  console.log(isShippingSelected);
  console.log(isLoadSelected);

  const handleSelectAllShipments = () => {
    const allShipments = selecteLoad.LoadLines;
    const updatedListShipping = [...listShipping];
    allShipments.forEach((shipping) => {
      const isSelected = updatedListShipping.some(
        (item) => item.ShipmentId === shipping.ShipmentId
      );
      if (!isSelected) {
        updatedListShipping.push(shipping);
      }
    });
    setListShipping(updatedListShipping);
  };

  const handleSyncLoad = () => {
    if (selecteLoad && selecteLoad.LoadLines.length > 0) {
      // Seleccionar el primer envío de la carga
      const firstShipment = selecteLoad.LoadLines[0];
      // Crear un nuevo arreglo con el envío seleccionado
      const updatedListShipping = [firstShipment, ...listShipping];
      // Actualizar el estado local con el nuevo arreglo
      console.log(updatedListShipping);
      setListShipping(updatedListShipping);
      // Activar el modo de carga seleccionada
      setIsLoadSelected(true);
      // Realizar la sincronización directamente después de la selección
      handleSave();
    }
  };

  const handleSyncSelection = () => {
    if (selecteLoad && selecteLoad.LoadLines.length > 0) {
      // Seleccionar el primer envío de la carga
      const firstShipment = selecteLoad.LoadLines[0];
      // Actualizar el estado local con el nuevo arreglo, agregando el envío al principio
      setListShipping(prevListShipping =>
        prevListShipping.filter(item => item.ShipmentId !== firstShipment.ShipmentId)
      );
      setListShipping(prevListShipping => [firstShipment, ...prevListShipping]);
      // Activar el modo de carga seleccionada
      setIsLoadSelected(true);
      // No realizar la sincronización aquí, se realizará después de que el usuario lo solicite explícitamente
      handleSave();
    }
  };

  const calculateTotalSizeAndVolume = (containers) => {
    let totalHeight = 0;
    let totalWidth = 0;
    let totalDepth = 0;
    let totalVolume = 0;
    let totalWeight = 0;

    containers.forEach((container) => {
      totalHeight += container.height;

      if (container.width > totalWidth) {
        totalWidth = container.width;
      }

      if (container.depth > totalDepth) {
        totalDepth = container.depth;
      }

      totalVolume += container.height * container.width * container.depth;
      totalWeight += container.weight;
    });

    return {
      totalSize: {
        height: totalHeight,
        width: totalWidth,
        depth: totalDepth,
      },
      totalVolume,
      totalWeight,
    };
  };

  const validateExistsShipment = async (shipment) => {
    const exists = props.dataOrderRunning?.data?.ordersTable.some((order) => {
      if (shipment.ShipmentId && shipment.ShipmentId !== "") {
        return order.consecutiveShipping === shipment.ShipmentId;
      }

      return false;
    });
    if (exists) {
      setSyncInProgress(false);
      Swal.fire({
        title: "Orden de venta o TRN existente",
        text: `El envio ${shipment.ShipmentId} fue sincronizado anteriormente, por esta razón fue ignorado para la sincronización`,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    }

    return exists;
  };

  const handleSave = async () => {
    if (
      selecteLoad?.LoadLines[0]?.InvoiceList?.length === 0 &&
      selecteLoad?.LoadLines[0]?.PackingSlipList?.length === 0
    ) {
      Swal.fire({
        title: "Error de Sincronización",
        text: `El envio ${selectedShipment[0]?.ShipmentId} no puede ser sincronizado ya que no cuenta con un numero de factura y remisión`,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    } else {
      let orders = [];
      setSyncInProgress(true);
      console.log(selecteLoad);
      console.log(listShipping.length);
      if (listShipping.length > 0) {
        let orderNumberList = "";
        for (let i = 0; i < listShipping.length; i++) {
          const shipment = listShipping[i];
          console.log(shipment);
          const exists = await validateExistsShipment(shipment);
          console.log(exists);
          if (!exists) {
            let newAddress = "";
            const address = shipment.Street.replace(",", " ")
              .replace(/\s\s+/g, " ")
              .replace("#", "")
              .split(" ");

            address.forEach((item) => {
              newAddress += item + "+";
            });

            newAddress = newAddress.substring(0, newAddress.length - 1);

            newAddress += `+${shipment.City}+${shipment.State}+${shipment.Country}`;

            const res = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=country:CO&address=${newAddress}`
            );

            let sector = calculateGeoEnclosure(
              {
                lat: res.data.results[0].geometry.location.lat,
                lng: res.data.results[0].geometry.location.lng,
              },
              sectors
            );

            if (!sector) {
              sector = sectors.find((item) => item.name === "Importados TMS");
            }

            let weight = 0;
            let height = 0;
            let width = 0;
            let depth = 0;
            let totalSize = 0;
            let totalVolume = 0;
            let individualCubic = 0;
            let totalContainers = 0;

            if (shipment.ContainerList.length > 0) {
              const containersList = shipment.ContainerList.map((container) => {
                return {
                  id: container.ContainerId,
                  weight:
                    container.WeightUnit === "kg"
                      ? container.Weight ?? 0
                      : 0.453592 * (container.Weight ?? 0),
                  height: container.Height ?? 0,
                  width: container.Width ?? 0,
                  depth: container.Length ?? 0,
                };
              });

              const containerResponse =
                calculateTotalSizeAndVolume(containersList);

              totalSize = containerResponse.totalSize;
              totalVolume = containerResponse.totalVolume;
              weight = containerResponse.totalWeight;
              height = containerResponse.totalSize.height;
              width = containerResponse.totalSize.width;
              depth = containerResponse.totalSize.depth;
              individualCubic =
                containerResponse.totalVolume / containersList.length;
              totalContainers = containersList.length;
            }

            let custIdentificationNumber;
            let custFullName;
            let custPhone = shipment.ContactPhone;
            let distributionCenter;
            switch (shipment.Type) {
              case "Envío de pedidos de transferencia":
                custIdentificationNumber = shipment.InventLocationIdTo;
                distributionCenter = distributionCenters.find(
                  (center) => center.name === shipment.InventLocationIdTo
                );
                custFullName =
                  "TRN - " + distributionCenter?.description === undefined
                    ? "N/D"
                    : shipment.InventLocationIdTo;
                custPhone = distributionCenter?.phone;
                break;
              case "Pedido de ventas":
                custIdentificationNumber = shipment.CustAccount;
                custFullName = shipment.SalesName;
                break;
              default:
                custIdentificationNumber = shipment.OrderAccount;
                custFullName = shipment.PurchName;
                break;
            }

            let sequenceResponse = await axios({
              method: "POST",
              url: process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL,
              data: { companyId, sequenceTypeId: "orders" },
            });

            let sequence = sequenceResponse.data.sequence;

            if (previousOrderNumberList.includes(sequence)) {
              while (previousOrderNumberList.includes(sequence)) {
                sequenceResponse = await axios({
                  method: "POST",
                  url: process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL,
                  data: { companyId, sequenceTypeId: "orders" },
                });
                sequence = sequenceResponse.data.sequence;
              }
            }
            for (
              let i = 0;
              i < selecteLoad?.LoadLines[0]?.PackingSlipList?.length;
              i++
            ) {
              const PackingSlipList =
                selecteLoad?.LoadLines[0]?.PackingSlipList[i];
              setPackingSlip(PackingSlipList.PackingSlipId);
            }

            let PaymentOnCashValue = 0;

            for (
              let j = 0;
              j < selecteLoad?.LoadLines[0]?.InvoiceList?.length;
              j++
            ) {
              const InvoiceList = selecteLoad?.LoadLines[0]?.InvoiceList[j];
              setInvoce(InvoiceList.InvoiceId);
              if (InvoiceList.Payment === "CONTRAENTREGA") {
                PaymentOnCashValue += InvoiceList.InvoiceAmount;
              }
            }

            setPreviousOrderNumberList([...previousOrderNumberList, sequence]);

            const ClientsResponses = await getClients({
              variables: {
                nit: shipment.CustAccount,
              },
            });

            let customerSupplierId = "";

            if (
              shipment.CustAccount !== "" &&
              ClientsResponses?.data?.clients?.length === 0
            ) {
              const variablesInsClient = {
                nit: shipment.CustAccount,
                name: shipment.SalesName,
                companyId: companyId,
                email: shipment.ContactEmail,
                accountNumber: shipment.CustAccount,
                documentType: "NIT",
                currency: "Pesos colombianos",
                personType: "organization",
                person: "customer",
                typePerson: "Persona jurídica",
              };
              const _insertClients = await insClients({
                variables: {
                  objects: [variablesInsClient],
                },
              });

              customerSupplierId =
                _insertClients?.data?.insert_clients?.returning[0]?.id;
              if (_insertClients?.data?.insert_clients?.returning?.length > 0) {
                const variablesInsClientAddresses = {
                  clientId:
                    _insertClients?.data?.insert_clients?.returning[0]?.id,
                  name: "Bodega Principal",
                  contactName: shipment.ContactName,
                  phoneNumber: shipment.ContactPhone,
                  cellPhoneNumber: shipment.ContactPhone,
                  address: shipment.Street,
                  state: shipment.State,
                  city: shipment.City,
                  addressComplement: "",
                  lat: `${res.data.results[0].geometry.location.lat}`,
                  lng: `${res.data.results[0].geometry.location.lng}`,
                  sectorId: sector.id,
                  enabled: true,
                  principal: true,
                };
                await insClientAddresses({
                  variables: {
                    objects: [variablesInsClientAddresses],
                  },
                });
              }
            }
            if (
              (shipment.CustAccount !== "" &&
                ClientsResponses?.data?.clients[0]?.typePerson === "") ||
              ClientsResponses?.data?.clients[0]?.typePerson === null ||
              ClientsResponses?.data?.clients[0]?.typePerson === undefined
            ) {
              const _updateTypePerson = await updateClients({
                variables: {
                  nit: shipment.CustAccount,
                  typePerson: "Persona jurídica",
                },
              });
            }
            const requestOrder = {
              orderNumber: sequence,
              customerSupplierId:
                shipment.CustAccount !== "" &&
                ClientsResponses?.data?.clients?.length === 0
                  ? customerSupplierId
                  : ClientsResponses?.data?.clients[0]?.id,
              address: shipment.Street,
              city: shipment.City,
              state: shipment.State,
              addressComplement: shipment.BuildingCompliment,
              sectorId: sector.id,
              custIdentificationNumber,
              custFullName,
              custPhoneNumber: custPhone,
              type:
                shipment.Type === "Envío de pedidos de transferencia"
                  ? "warehousesTransfer"
                  : shipment.Type === "Pedido de compra"
                  ? "pickup"
                  : "delivery",
              totalOrderAmount: PaymentOnCashValue,
              destination: `${res.data.results[0].geometry.location.lat},${res.data.results[0].geometry.location.lng}`,
              weight,
              height,
              width,
              depth,
              cubicMeters: individualCubic,
              totalCubicMeters: totalVolume,
              orderedQuantity: totalContainers,
              linesDetail: true,
              notes: shipment.Type,
              paymentOrCashOnDeliveryRequired: PaymentOnCashValue > 0,
              consecutiveSaleOrder: shipment.OrderNum,
              consecutiveShipping: shipment.ShipmentId,
              createByUserId: userId,
              typePerson: "Persona jurídica",
              distributionCenterId:
                distributionCenters.find(
                  (item) => item.name === selecteLoad.InventLocationId
                )?.id ?? distributionCenters[0].id,
              consecutiveBurden: shipment.LoadId,
              consecutiveRemission: (() => {
                if (
                  !selecteLoad?.LoadLines[0]?.ItemList ||
                  selecteLoad?.LoadLines[0]?.ItemList.length === 0
                ) {
                  return "";
                }

                const uniquePackingSlipIds = new Set();

                shipment.ItemList?.forEach((item) => {
                  uniquePackingSlipIds.add(item.PackingSlipId);
                });

                return Array.from(uniquePackingSlipIds).toString();
              })(),
              consecutiveBill: (() => {
                if (
                  !selecteLoad?.LoadLines[0]?.ItemList ||
                  selecteLoad?.LoadLines[0]?.ItemList.length === 0
                ) {
                  return "";
                }

                const uniqueInvoiceIds = new Set();

                shipment.ItemList?.forEach((item) => {
                  uniqueInvoiceIds.add(item.InvoiceId);
                });

                return Array.from(uniqueInvoiceIds).toString();
              })(),
              priority: "a_high",
              custEmail: "adavid@navitrans.com.co",
              paymentMethod: shipment.PaymMode,
              companyId: "482777f8-95e3-4b2a-8a78-7c75aa733946",
              enablePackageDimensions: true,
              packageId: "380ed460-52a7-4e88-8c2d-42cdfa31ea87",
            };

            const resOrder = await insOrdersTable({
              variables: {
                objects: [requestOrder],
              },
            });

            orders.push(resOrder);

            if (orders.length > 0) {
              const order =
                resOrder["data"]["insert_ordersTable"]["returning"][0];
              orderNumberList += order.orderNumber + ", ";
              const data = shipment.ItemList;
              const _dataLines = [];

              const _InvoiceList = shipment.InvoiceList;

              for (let i = 0; i < data.length; i++) {
                const line = data[i];

                let externalInvoiceId = "";

                let externalSalesId = "";

                if (_InvoiceList) {
                  const invoiceRecId = _InvoiceList?.find(
                    (invoice) => invoice.InvoiceId === line.InvoiceId
                  )?.RecId;
                  if (invoiceRecId) {
                    externalInvoiceId = invoiceRecId.toString();
                  }
                }

                if (shipment?.SalesRecId) {
                  externalSalesId = shipment.SalesRecId?.toString();
                }

                _dataLines.push({
                  orderNumber: order.orderNumber,
                  productNumber: line.ItemId,
                  productName: line.ProductName,
                  orderedQuantity: line.ProductQuantity,
                  initialQuantity: line.ProductQuantity,
                  Invoice:
                    line.TRN === "" ||
                    line.TRN === null ||
                    line.TRN === undefined
                      ? line.InvoiceId
                      : line.TRN,
                  PackingSlip: line.PackingSlipId,
                  externalId: line.RecId?.toString(),
                  externalInvoiceId,
                  externalSalesId,
                });
              }

              let resLine;
              resLine = await insOrdersLine({
                variables: {
                  objects: _dataLines,
                },
              });
              setSyncInProgress(false);
              if (resLine.data.insert_ordersLine.affected_rows > 0) {
                /*
                const identification = shipment.CustAccount
                if(identification !==""){

                const checkClient = await getCheckCliente({variables : {nit: identification}});
                console.log(checkClient);
                console.log(checkClient.data.clients.length);
                if(checkClient.data.clients.length == 0)
                {      
                   await createClientNew({
                    variables: {
                      personType : "organization",
                      person : "customer", 
                      documentType: "NIT",
                      nit: shipment.CustAccount,
                      name : shipment.SalesName,
                      accountNumber : shipment.CustAccount,
                      currency : "Pesos colombianos ",
                      email : shipment.ContactEmail,
                      enabled : true,
                      companyId : companyId,
                    }
                      
                  })
                  console.log("Cliente creado"); 
                }
                }
                
                */
                const id = resLine.data.insert_ordersLine.returning[0].id;

                Swal.fire({
                  title: "Envios y Lineas sincronizados",
                  text: `${
                    resOrder.length > 1
                      ? "Los envios fueron sincronizados correctamente, se crearon las ordenes"
                      : "El envio fue sincronizado correctamente, se creó la orden"
                  } ${orderNumberList.substring(
                    0,
                    orderNumberList.length - 2
                  )}`,
                  icon: "success",
                  confirmButtonText: "Cerrar",
                });

                loggerCooltrack({
                  module: "DataUpload",
                  operation: "SynchronizedDataUpload",
                  target: id,
                });
              } else {
                Swal.fire({
                  title: "Error al ingresar las líneas de las órdenes",
                  text: "Ocurrió un error al ingresar las líneas de las órdenes. Por favor, intente de nuevo.",
                  icon: "error",
                  confirmButtonText: "Cerrar",
                });
              }
            } else {
              setSyncInProgress(false);
              Swal.fire({
                title: "Error",
                text: "Alguno de los envios ya fue sincronizado anteriormente.",
                icon: "warning",
                confirmButtonText: "Cerrar",
              });
            }
            try {
            } catch (error) {
              setSyncInProgress(false);
              if (
                error.message.includes(
                  "duplicate key value violates unique constraint"
                )
              ) {
                Swal.fire({
                  title: "Error",
                  text: "Alguno de los envios ya fue sincronizado anteriormente.",
                  icon: "warning",
                  confirmButtonText: "Cerrar",
                });
              } else {
                Swal.fire({
                  title: "Error",
                  text: "Ocurrió un error al procesar las órdenes. Por favor, intente de nuevo.",
                  icon: "error",
                  confirmButtonText: "Cerrar",
                });
              }
            }
            props.getOrderRunning();
          }
        }
      }
    }
    setSyncInProgress(false);
    //getTMSLoad(dataOrderRunningg,CenterUser,selectedPageSize,selectedPageId,filterr)
  };

  return (
    <div className="action-buttons-container">
      <div className="container-buttons">
        <button
          className="action-button"
          disabled={listShipping.length > 0}
          onClick={() => handleSyncLoad()}
        >
          <SyncIcon className="icon-action" />
        </button>
        <p>Sincronizar</p>
        <p>Carga</p>
      </div>
      <div className="container-buttons">
        <button
          className="action-button"
          disabled={listShipping.length <= 0}
          onClick={() => {
            handleSave();
          }}
        >
          <DownloadIcon className="icon-action" />
        </button>
        <p>Sincronizar</p>
        <p>Envios</p>
      </div>
      <div className="container-buttons">
        <button
          className="action-button"
          disabled={isLoadSelected}
          onClick={handleSelectAllShipments}
        >
          <ChecklistRtlIcon className="icon-action" />
        </button>
        <p>Seleccionar</p>
        <p>Todos los Envios</p>
      </div>
      <div className="container-buttons">
        <button className="action-button" onClick={() => handleSyncSelection()}>
          <PublishedWithChangesIcon className="icon-action" />
        </button>
        <p>Sincronizar</p>
        <p>Seleccion</p>
      </div>
      <div className="container-buttons">
        <button className="action-button" disabled={listShipping.length > 0}>
          <DoneAllIcon className="icon-action" />
        </button>
        <p>Seleccionar</p>
        <p>Todas las Cargas</p>
      </div>
      <div className="container-buttons">
        <button className="action-button" disabled={listShipping.length > 0}>
          <SyncIcon className="icon-action" />
        </button>
        <p>Sincronizar</p>
        <p>Todas las Cargas</p>
      </div>
    </div>
  );
};

export default ActionButtons;

import React from "react";
import packageJson from "../../../../../package.json";
import { Button, Col, Row } from "antd";
import "../../../../i18n";
import { useTranslation } from "react-i18next";
import {
  CarOutlined,
  IdcardOutlined,
  DropboxOutlined,
  InboxOutlined,
  TeamOutlined,
  EnvironmentOutlined,
  WarningOutlined,
  InfoOutlined,
  SettingOutlined,
  AlertOutlined,
  BarsOutlined,
  BgColorsOutlined,
  OrderedListOutlined,
  SendOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import PlaceIcon from "@mui/icons-material/Place";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Link } from "react-router-dom";
import "../styles/ConfigurationSelectorScreen.css";
import localStorageService from "../../../../services/localStorageService";

const UserRole = localStorageService.get("role");
export const ConfigurationSelectorScreen = () => {
  const { t } = useTranslation("global");
  return (
    <Col
      className="children-inner-container-with-bg"
      style={{ textAlign: "center" }}
    >
      <Row className="children-inner-row">
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/sectors">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <EnvironmentOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.zones")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/incidents-config">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <InfoOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.incidents")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/incidents-causes-config">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <WarningOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.causes")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/incident-management-config">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <SettingOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.management")} </p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>

        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/reasons">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <AlertOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.reasons")} </p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/users">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <TeamOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.users")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/vehicle-types">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <BarsOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.vehicle_types")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/vehicle-brands">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <CarOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.vehicle_brands")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>

        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/transport-companies">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <CarOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.carriers")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/vehicles">
            <Button className="children-inner-col-button" type="primary" ghost>
              <Col>
                <Row>
                  <CarOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.vehicles")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/distribution-centers">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row style={{ marginTop: "24px" }}>
                  <InboxOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>
                    {t("modules.configuration.submodules.distribution_centers")}
                  </p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/clients">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <IdcardOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.clients")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/packages">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <DropboxOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.packages")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/sequences-numbers">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row style={{ marginTop: "24px" }}>
                  <OrderedListOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>
                    {t("modules.configuration.submodules.numeric_sequences")}
                  </p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>

        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/company-branding">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <BgColorsOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.design")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>

        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/releaseOrders">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <SendOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>{t("modules.configuration.submodules.released_orders")}</p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>

        <Col xs={24} sm={6} lg={4} xl={3} className="children-inner-col-box">
          <Link to="/configureOptions">
            <Button
              className="children-inner-col-button"
              type="primary"
              ghost
              disabled={UserRole === "user"}
            >
              <Col>
                <Row>
                  <CheckOutlined className="children-inner-col-icon" />
                </Row>
                <Row className="children-inner-col-label">
                  <p>
                    {t("modules.configuration.submodules.system_parameters")}
                  </p>
                </Row>
              </Col>
            </Button>
          </Link>
        </Col>
      </Row>
      <p class="footer_version">
        {t("common.version")} {packageJson.version}
      </p>
    </Col>
  );
};

import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Switch,
  Descriptions,
  Input,
  Card,
  Button,
  Select,
  Space,
  Skeleton,
  Col,
  Row,
  Modal,
  InputNumber,
} from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  OrderedListOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  handleSave,
  handleDelete,
  handleEdit,
  handleClear,
  getColumnSearchPropsText,
} from "./UseCases";

import {
  fetchSequencesNumbers,
  insertSequenceNumber,
  updateSequenceNumber,
  deleteSequenceNumber,
  fetchSequenceTypes,
} from "../../queries/sequencesNumbersQueries";
import localStorageService from "../../../../../services/localStorageService";
import { ConfigurationMoreSettings } from "../../../ConfigurationMoreSettings";

import { SequencesNumbersIndicator } from "./SequencesNumbersIndicator";

const companyId = localStorageService.get("companyId");

export const SequencesNumbersScreen = () => {
  const [getSequencesNumbers, dataSequencesNumbers] = useLazyQuery(
    fetchSequencesNumbers,
    { variables: { companyId }, fetchPolicy: "no-cache" }
  );
  const [getSequenceTypes, dataSequenceTypes] = useLazyQuery(
    fetchSequenceTypes,
    { fetchPolicy: "no-cache" }
  );
  const [insSequenceNumber] = useMutation(insertSequenceNumber, {
    variables: { companyId },
    fetchPolicy: "no-cache",
  });
  const [delSequenceNumber] = useMutation(deleteSequenceNumber, {
    fetchPolicy: "no-cache",
  });
  const [updSequenceNumber] = useMutation(updateSequenceNumber, {
    fetchPolicy: "no-cache",
  });

  const [sequencesNumbers, setSequencesNumbers] = useState([]);
  const [sequenceTypes, setSequenceTypes] = useState([]);
  const [sequenceTypesList, setSequenceTypesList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [clickedRow, setClickedRow] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    setIsLoading(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsLoading(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsLoading(false);
  };

  useEffect(() => {
    getSequencesNumbers();
    getSequenceTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSequencesNumbers([]);

    if (
      !dataSequencesNumbers.loading &&
      dataSequencesNumbers.data &&
      dataSequencesNumbers.data.sequenceNumbers.length > 0
    ) {
      dataSequencesNumbers.data.sequenceNumbers.map((item) => {
        const deleteable =
          dataSequencesNumbers.data.sequenceNumbers_aggregate.aggregate
            .count === 0;
        const {
          id,
          name,
          enabled,
          from,
          to,
          currentNumber,
          prefix,
          sequenceTypeId,
        } = item;
        const sequenceType =
          item.sequenceType.length > 0 ? item.sequenceType[0].name : undefined;
        return setSequencesNumbers((current) => [
          ...current,
          {
            id,
            name,
            from,
            to,
            currentNumber,
            prefix,
            enabled,
            sequenceTypeId,
            sequenceType: sequenceType ?? "",
            deleteable,
          },
        ]);
      });
    }
  }, [dataSequencesNumbers.loading, dataSequencesNumbers.data]);

  useEffect(() => {
    setSequenceTypes([]);

    if (
      !dataSequenceTypes.loading &&
      dataSequenceTypes.data &&
      dataSequenceTypes.data.sequenceTypes.length > 0
    ) {
      dataSequenceTypes.data.sequenceTypes.map((item) => {
        const { id, name } = item;

        setSequenceTypesList((current) => [
          ...current,
          {
            id,
            name,
          },
        ]);

        return setSequenceTypes((current) => [
          ...current,
          {
            id,
            name,
          },
        ]);
      });
    }
  }, [dataSequenceTypes.loading, dataSequenceTypes.data]);

  const columns = [
    {
      title: "Nombre",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchPropsText("Nombre", "name"),
    },
    {
      title: "Tipo de secuencia",
      key: "sequenceType",
      dataIndex: "sequenceType",
      sorter: (a, b) => a.sequenceType.localeCompare(b.sequenceType),
      ...getColumnSearchPropsText("Tipo de secuencia", "sequenceType"),
    },
    {
      title: "Desde",
      key: "from",
      dataIndex: "from",
      sorter: (a, b) => a.from.localeCompare(b.from),
      ...getColumnSearchPropsText("Desde", "from"),
    },
    {
      title: "Hasta",
      key: "to",
      dataIndex: "to",
      sorter: (a, b) => a.to.localeCompare(b.to),
      ...getColumnSearchPropsText("Hasta", "to"),
    },
    {
      title: "Número actual",
      key: "currentNumber",
      dataIndex: "currentNumber",
      sorter: (a, b) => a.currentNumber.localeCompare(b.currentNumber),
      ...getColumnSearchPropsText("Número actual", "currentNumber"),
    },
    {
      title: "Prefijo",
      key: "prefix",
      dataIndex: "prefix",
      sorter: (a, b) => a.prefix.localeCompare(b.prefix),
      ...getColumnSearchPropsText("Prefijo", "prefix"),
    },
    {
      title: "Habilitado",
      key: "enabled",
      dataIndex: "enabled",
      align: "center",
      sorter: {
        compare: (a, b) => a.enabled - b.enabled,
      },
      render: (value) => <Switch checked={value} disabled />,
    },
  ];

  useEffect(() => {
    if (isLoading === false) {
      let newSequenceTypes = [];
      for (let j = 0; j < sequenceTypes.length; j++) {
        let add = true;
        for (let i = 0; i < sequencesNumbers.length; i++) {
          if (sequencesNumbers[i].sequenceTypeId === sequenceTypes[j].id)
            add = false;
        }

        if (add) newSequenceTypes.push(sequenceTypes[j]);
      }

      setSequenceTypesList(newSequenceTypes);
      setIsLoading(true);
    }
  });
  const formItemLayout = {
    labelCol: {
      lg: { span: 9 },
    },
    wrapperCol: {
      lg: { span: 14 },
    },
  };
  return (
    <Col className="children-inner-container-with-bg-col">
      <ConfigurationMoreSettings />
      <Row className="children-inner-row">
        <Col span={5} className="children-inner-col">
          <SequencesNumbersIndicator
            value={sequencesNumbers.length}
            label={"Total secuencias"}
          >
            <OrderedListOutlined
              style={{ backgroundColor: "var(--primaryColor)" }}
              className="report-indicator-icon"
            />
          </SequencesNumbersIndicator>
        </Col>
        <Col span={5} className="children-inner-col">
          <SequencesNumbersIndicator
            value={sequencesNumbers.filter((item) => item.enabled).length}
            label={"Secuencias Activas"}
          >
            <CheckOutlined
              style={{ backgroundColor: "#9ccc65" }}
              className="report-indicator-icon"
            />
          </SequencesNumbersIndicator>
        </Col>
        <Col span={5} className="children-inner-col">
          <SequencesNumbersIndicator
            value={sequencesNumbers.filter((item) => !item.enabled).length}
            label={"Secuencias Inactivos"}
          >
            <ClockCircleOutlined
              style={{ backgroundColor: "#ffba57" }}
              className="report-indicator-icon"
            />
          </SequencesNumbersIndicator>
        </Col>
        <Col span={3} offset={3} className="children-inner-col">
          <Button
            type="primary"
            size={"large"}
            className="children-inner-button"
            icon={<EditOutlined />}
            onClick={showModal}
          >
            {clickedRow.length === 0 ? "Crear" : "Editar"}
          </Button>
        </Col>
        <Col span={3} className="children-inner-col">
          <Button
            type="primary"
            size={"large"}
            className="children-inner-button"
            icon={<DeleteFilled />}
            onClick={() =>
              handleDelete(
                clickedRow,
                delSequenceNumber,
                sequencesNumbers,
                setSequencesNumbers,
                setClickedRow,
                form,
                setIsLoading
              )
            }
            disabled={clickedRow.length === 0}
          >
            Eliminar
          </Button>
        </Col>
      </Row>
      <Row>
        <Skeleton loading={dataSequencesNumbers.loading} active>
          <Table
            className="children-table-global"
            columns={columns}
            dataSource={sequencesNumbers}
            size="small"
            rowKey="id"
            pagination={{ pageSize: 13 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (clickedRow.length === 0 || clickedRow !== record.id) {
                    setClickedRow(record.id);
                    handleEdit(record, setIsEditing, form);
                  } else {
                    handleClear(setIsEditing, form);
                    setClickedRow("");
                  }
                },
              };
            }}
            rowClassName={(record) => [
              "mouse-row-enter",
              record.id === clickedRow ? "table-row-color" : "",
            ]}
          />
        </Skeleton>
      </Row>
      <Modal
        className="children-modal"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[]}
        title={clickedRow.length === 0 ? "Crear Secuencia" : "Editar Secuencia"}
        width={450}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="configuration"
          onFinish={(values) => {
            handleSave(
              values,
              insSequenceNumber,
              updSequenceNumber,
              sequencesNumbers,
              setSequencesNumbers,
              isEditing,
              setIsEditing,
              form,
              setIsModalVisible
            );
            setClickedRow("");
          }}
          initialValues={{
            id: "",
            name: "",
            from: "",
            to: "",
            currentNumber: "",
            prefix: "",
            enabled: true,
            deleteable: true,
            sequenceTypeId: null,
            sequenceType: "",
          }}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="Nombre"
            labelAlign="left"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor ingrese un nombre para la secuencia",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Tipo de secuencia"
            labelAlign="left"
            hasFeedback
            name="sequenceTypeId"
            rules={[
              { required: true, message: "Seleccione un tipo de secuencia" },
            ]}
          >
            <Select
              placeholder="Seleccione..."
              allowClear
              onChange={(value, object) => {
                if (value !== undefined) {
                  return form.setFieldsValue({
                    sequenceTypeId: value,
                    sequenceType: object.children,
                  });
                }
              }}
            >
              {sequenceTypesList.map((item) => {
                return (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Desde"
            labelAlign="left"
            name="from"
            rules={[
              {
                required: true,
                message: "Por favor ingrese un valor numérico",
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item
            label="Hasta"
            labelAlign="left"
            name="to"
            rules={[
              {
                required: true,
                message: "Por favor ingrese un valor numérico",
              },
              () => ({
                validator() {
                  const from = form.getFieldValue("from");
                  const to = form.getFieldValue("to");
                  if (to < from) {
                    return Promise.reject(
                      new Error(`El valor debe ser mayor a ${from}`)
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item
            label="Número actual"
            labelAlign="left"
            name="currentNumber"
            rules={[
              {
                required: true,
                message: "Por favor ingrese un valor numérico",
              },
              () => ({
                validator() {
                  const currentNumber = form.getFieldValue("currentNumber");
                  const from = form.getFieldValue("from") - 1;
                  const to = form.getFieldValue("to");
                  if (currentNumber >= from && currentNumber <= to) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `El valor actual debe estar entre ${from} y ${to}`
                    )
                  );
                },
              }),
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            label="Prefijo"
            labelAlign="left"
            name="prefix"
            rules={[
              {
                required: true,
                message: "Por favor ingrese un prefijo de secuencia",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Habilitado"
            labelAlign="left"
            name="enabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item name="sequenceType" hidden>
            <Input />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                // onClick={handleCancel}
              >
                {isEditing ? "Modificar" : "Agregar"}
              </Button>
              <Button
                type="ghost"
                shape="round"
                onClick={() => {
                  handleClear(setIsEditing, form);
                  setClickedRow("");
                  handleCancel();
                }}
              >
                Cancelar
              </Button>
            </Space>
          </Form.Item>
          <Form.Item name="deleteable" hidden valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </Col>
  );
};

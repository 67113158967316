// import React, { useState, useEffect } from "react";
// import {
//   InputLabel,
//   Button,
//   Input,
//   Modal,
//   Card,
//   TextField,
//   Select,
//   MenuItem,
//   FormControl,
//   Switch,
//   FormGroup,
//   FormControlLabel,
// } from "@mui/material";

// import Swal from "sweetalert2";
// import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
// import localStorageService from "../../../../../services/localStorageService";
// import axios from "axios";
// import "../styles/Styles.css";
// import bcrypt from "bcryptjs";

// import { useTranslation } from "react-i18next";
// import "./../../../../..//i18n";
// import {
//   checkIfEmailExists,
//   checkIfIdentificationNumberExists,
//   checkIfIdentificationNumberEmailExists,
//   insertUser,
//   updateUser,
//   updateUserInsertAutomaticPassword,
//   fetchRoles,
//   updateDistributionCenters,
//   cleanDistrutionCenter,
// } from "../../queries/userQueries";
// import "../styles/styles";
// const companyId = localStorageService.get("companyId");

// const UserDetailsScreen = ({ props }) => {
//   const apolloClient = useApolloClient();
//   const [formData, setFormData] = useState({
//     displayName: "",
//     email: "",
//     identificationNumber: "",
//     phoneNumber: "",
//     roleId: "",
//     driverLicense: "",
//     address: "",
//     enabled: true,
//     distributionCenterId: [],
//   });
//   const [formErrors, setFormErrors] = useState({});
//   const {
//     showDrawer,
//     setShowDrawer,
//     recordToEdit,
//     setRecordToEdit,
//     isEditing,
//     setIsEditing,
//     users,
//     setUsers,
//     dataDistributionCenters,
//   } = props;
//   const { t } = useTranslation("global");
//   const [role, setRole] = useState("");
//   const [identificationVerification, setIdentificationVerification] =
//     useState(false);
//   const [emailVerification, setEmailVerification] = useState(false);
//   const [distributionCenters, setDistributionCenters] = useState([]);
//   const [distributionCentersName, setDistributionCentersName] = useState([]);
//   const [verificationemail, setVerificationemail] = useState(false);

//   const [getRoles, dataRoles] = useLazyQuery(fetchRoles, {
//     fetchPolicy: "no-cache",
//   });
//   const [insUser] = useMutation(insertUser, { fetchPolicy: "no-cache" });
//   const [udpUser] = useMutation(updateUser, { fetchPolicy: "no-cache" });

//   const [cleanDistCenter] = useMutation(cleanDistrutionCenter, {
//     fetchPolicy: "no-cache",
//   });
//   const [updateDistCenter] = useMutation(updateDistributionCenters, {
//     fetchPolicy: "no-cache",
//   });

//   const [InsertPasswordAutomaticUser] = useMutation(
//     updateUserInsertAutomaticPassword,
//     { fetchPolicy: "no-cache" }
//   );

//   useEffect(() => {
//     getRoles();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   useEffect(() => {
//     form.setFieldsValue({
//       id: recordToEdit.id,
//       displayName: recordToEdit.displayName,
//       email: recordToEdit.email,
//       identificationNumber: recordToEdit.identificationNumber,
//       phoneNumber: recordToEdit.phoneNumber,
//       roleId: recordToEdit.roleId,
//       roleName: recordToEdit.roleName,
//       driverLicense: recordToEdit.driverLicense,
//       photoURL: recordToEdit.photoURL ?? "",
//       address: recordToEdit.address ?? "",
//       enabled: recordToEdit.enabled ?? true,
//       distributionCenter: recordToEdit.distributionCenter ?? "",
//       distributionCenterId: recordToEdit.distributionCenterId ?? [],
//     });

//     setRole(recordToEdit.roleName);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [recordToEdit]);

//   useEffect(() => {
//     if (
//       !dataDistributionCenters.loading &&
//       dataDistributionCenters.data &&
//       dataDistributionCenters.data.distributionCenters.length > 0
//     ) {
//       dataDistributionCenters.data.distributionCenters.map((item) => {
//         const { id, name } = item;

//         return setDistributionCenters((current) => [
//           ...current,
//           {
//             id,
//             name,
//           },
//         ]);
//       });
//     }
//   }, [dataDistributionCenters.loading, dataDistributionCenters.data]);

//   const formItemLayout = {
//     labelCol: {
//       lg: { span: 8 },
//     },
//     wrapperCol: {
//       lg: { span: 15 },
//     },
//   };
//   const verificationEmail = async (value) => {
//     const email = value;
//     apolloClient
//       .query({
//         query: checkIfEmailExists,
//         variables: { email },
//       })
//       .then(({ data }) => {
//         if (data.users.length > 0) {
//           setEmailVerification(true);
//         } else {
//           setEmailVerification(false);
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//         Swal.fire({
//           title: "Error",
//           text: `Ocurrió un error. Consulte al administrador. ${error.toString()}`,
//           icon: "error",
//           confirmButtonText: "Cerrar",
//           willClose: () => {
//             return;
//           },
//         });
//       });
//   };

//   const verificationIdentification = async (value) => {
//     const identificationNumber = value;
//     apolloClient
//       .query({
//         query: checkIfIdentificationNumberExists,
//         variables: { identificationNumber },
//       })
//       .then(({ data }) => {
//         if (data.users.length > 0) {
//           setIdentificationVerification(true);
//         } else {
//           setIdentificationVerification(false);
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//         Swal.fire({
//           title: "Error",
//           text: `Ocurrió un error. Consulte al administrador. ${error.toString()}`,
//           icon: "error",
//           confirmButtonText: "Cerrar",
//           willClose: () => {
//             return;
//           },
//         });
//       });
//   };

//   const renewDistributionCenters = async (id, distributionCenterId) => {
//     await cleanDistCenter({ variables: { id } }).then((res) => {});
//     const distributionCenters = distributionCenterId.map((item) => {
//       return {
//         userId: id,
//         distributionCenterId: item,
//       };
//     });

//     await updateDistCenter({
//       variables: { distributionCenters },
//     }).then((res) => {});
//   };

// const handleSave = async () => {
//   form
//     .validateFields()
//     .then((_) => {
//       const values = form.getFieldsValue();
//       const {
//         id,
//         displayName,
//         email,
//         identificationNumber,
//         phoneNumber,
//         roleId,
//         roleName,
//         driverLicense,
//         address,
//         photoURL,
//         enabled,
//         distributionCenter,
//         distributionCenterId,
//         hasRoutes,
//       } = values;
//       if (!isEditing) {
//         insUser({
//           variables: {
//             displayName,
//             email,
//             identificationNumber,
//             phoneNumber,
//             roleId,
//             driverLicense,
//             address,
//             photoURL,
//             enabled,
//             companyId,
//             distributionCenterId: form
//               .getFieldValue("distributionCenterId")[0]
//               .toString(),
//           },
//         })
//           .then((res) => {
//             if (res.data.insert_users.affected_rows > 0) {
//               const { id } = res.data.insert_users.returning[0];
//               renewDistributionCenters(id, distributionCenterId);
//               setUsers((current) => [
//                 ...current,
//                 {
//                   id,
//                   displayName,
//                   email,
//                   identificationNumber,
//                   phoneNumber,
//                   roleId,
//                   roleName,
//                   driverLicense,
//                   address,
//                   photoURL,
//                   enabled,
//                   distributionCenterId,
//                   distributionCenter: `${distributionCentersName} ` ?? [],
//                   hasRoutes,
//                 },
//               ]);
//               Swal.fire({
//                 title: "Registro de Usuario Exitoso",
//                 text: "Se Registro el Usuario Correctamente en el Sistema!",
//                 icon: "success",
//                 confirmButtonText: "Aceptar",
//                 willClose: () => {
//                   verificationemail === true
//                     ? Swal.fire({
//                         title: "Enviar Verificación al Correo Electronico",
//                         text: "Se Enviara al Correo Electronico Registrado una Notificacion de Verificación!",
//                         icon: "success",
//                         confirmButtonText: "Aceptar",
//                         willClose: () => {
//                           const data = {
//                             id,
//                             email,
//                             displayName,
//                             roleId,
//                           };

//                           handleSendEmailVerification(data);
//                         },
//                       })
//                     : Swal.fire({
//                         title: "Contraseña Automatica",
//                         text: "Su Contraseña es el Numero de Documento Registrado en el sistema!",
//                         icon: "success",
//                         confirmButtonText: "Aceptar",
//                         willClose: () => {
//                           const data = { id, identificationNumber, email };
//                           passwordIdentificationNumber(data);
//                         },
//                       });
//                 },
//               });
//             }

//             handleClear();
//             setShowDrawer(false);
//           })
//           .catch((error) => {
//             console.error(error);
//           });
//       } else {
//         apolloClient
//           .query({
//             query: checkIfIdentificationNumberEmailExists,
//             variables: { identificationNumber, email },
//           })
//           .then(({ data }) => {
//             if (data.users.length > 0) {
//               Swal.fire({
//                 title: "Error",
//                 text: "El número de identificación que intenta registrar ya existe para otra cuenta, ingrese otro.",
//                 icon: "error",
//                 confirmButtonText: "Cerrar",
//                 timer: 5000,
//                 timerProgressBar: true,
//                 willClose: () => {
//                   return;
//                 },
//               });
//             }
//           })
//           .catch((error) => {
//             console.error(error);
//             Swal.fire({
//               title: "Error",
//               text: `Ocurrió un error. Consulte al administrador. ${error.toString()}`,
//               icon: "error",
//               confirmButtonText: "Cerrar",
//               willClose: () => {
//                 return;
//               },
//             });
//           });

//         udpUser({
//           variables: {
//             id,
//             displayName,
//             email,
//             identificationNumber,
//             phoneNumber,
//             roleId,
//             driverLicense,
//             address,
//             photoURL,
//             enabled,
//             distributionCenter,
//           },
//         })
//           .then((res) => {
//             if (res.data.update_users.affected_rows > 0) {
//               renewDistributionCenters(id, distributionCenterId);
//               const records = users;
//               const indexToReplace = users.findIndex((item) => item.id === id);

//               records.splice(indexToReplace, 1, {
//                 id,
//                 displayName,
//                 email,
//                 identificationNumber,
//                 phoneNumber,
//                 roleId,
//                 roleName,
//                 driverLicense,
//                 address,
//                 photoURL,
//                 enabled,
//                 distributionCenterId,
//                 distributionCenter: `${distributionCentersName} ` ?? [],
//               });
//               setUsers([...records]);

//               Swal.fire({
//                 title: "Actualización exitosa",
//                 text: "Usuario actualizado exitosamente!",
//                 icon: "success",
//                 confirmButtonText: "Cerrar",
//               });
//             }
//             handleClear();
//             setShowDrawer(false);
//           })
//           .catch((error) => {
//             console.error(error);
//           });
//       }
//     })
//     .catch((err) => {
//       console.error(err);
//     });
// };

//   const handleSendEmailVerification = (data) => {
//     const companyBrand = JSON.parse(localStorageService.get("companyDetails"));

//     axios({
//       method: "post",
//       url: process.env.REACT_APP_FUNCTION_EMAIL_VERIFICATION_URL,
//       data: {
//         id: data.id,
//         email: data.email,
//         displayName: data.displayName,
//         roleId: data.roleId,
//         origin: window.location.origin,
//         bigBrand: companyBrand.bigBrand,
//         companyName: localStorageService.get("company"),
//         primaryColor: companyBrand.primaryColor,
//       },
//     })
//       .then((res) => {
//         if (res.status === 200) {
//           Swal.fire({
//             title: "Enviado",
//             text: `El correo electrónico de verificación fue enviado.`,
//             icon: "success",
//             confirmButtonText: "Cerrar",
//             timer: 2000,
//             timerProgressBar: true,
//           });
//         } else {
//           Swal.fire({
//             title: "Error",
//             text: `No fue posible enviar el correo electrónico de verificación.`,
//             icon: "error",
//             confirmButtonText: "Cerrar",
//             timer: 2000,
//             timerProgressBar: true,
//           });
//         }
//       })
//       .catch((error) => {
//         Swal.fire({
//           title: "Error",
//           text: `No fue posible enviar el correo electrónico de verificación. ${error.toString()}`,
//           icon: "error",
//           confirmButtonText: "Cerrar",
//           timer: 2000,
//           timerProgressBar: true,
//         });
//       });
//   };

//   const passwordIdentificationNumber = (data) => {
//     const id = data.id;
//     const email = data.email;
//     const password = data.identificationNumber;

//     bcrypt.genSalt(5, (err, salt) => {
//       if (err == null) {
//         bcrypt.hash(email + password, salt, (error, hash) => {
//           if (error == null) {
//             InsertPasswordAutomaticUser({
//               variables: {
//                 id: id,
//                 password: hash,
//               },
//             })
//               .then((res) => {
//                 if (res.data.update_users.affected_rows > 0) {
//                   Swal.fire({
//                     title: "Registro de Contraseña Exitoso",
//                     text: "La Contraseña del Usuario fue Generada de Manera Correcta!",
//                     icon: "success",
//                     confirmButtonText: "Aceptar",
//                     willClose: () => {
//                       return;
//                     },
//                   });
//                 }
//               })
//               .catch((er) => {
//                 Swal.fire({
//                   title: "Error",
//                   text: `Ocurrió un error al intentar procesar los datos. Muestre la siguiente información al administrador: ${er.toString()}`,
//                   icon: "error",
//                   confirmButtonText: "Cerrar",
//                 });
//               });
//           }
//         });
//       }
//     });
//   };

//   const handleClear = () => {
//     setRecordToEdit({});
//     setIsEditing(false);
//     setRole("");

//     form.setFieldsValue({
//       id: "",
//       displayName: "",
//       email: "",
//       identificationNumber: "",
//       phoneNumber: "",
//       roleId: undefined,
//       roleName: undefined,
//       driverLicense: undefined,
//       address: undefined,
//       photoURL: undefined,
//       enabled: true,
//       distributionCenterId: null,
//       distributionCenter: null,
//     });
//   };

//   return (
//     <div
//       style={{
//         margin: "10px",
//       }}
//     >
//       <Modal
//         key="drawer"
//         title={`Modificar Usuario`}
//         width={500}
//         onCancel={() => {
//           handleClear();
//           setShowDrawer(false);
//         }}
//         visible={showDrawer}
//         bodyStyle={{ paddingBottom: 60 }}
//         footer={
//           <div style={{ textAlign: "center" }}>
//             <Button
//               className="largeRedButton"
//               onClick={() => {
//                 handleSave();
//               }}
//               disabled={
//                 isEditing === false
//                   ? identificationVerification === true ||
//                     emailVerification === true
//                   : ""
//               }
//             >
//               {t("common.modifyButton")}
//             </Button>
//           </div>
//         }
//       >
//         <Card
//           key="card"
//           style={{ marginBottom: "10px", minHeight: "0%" }}
//           size="small"
//         >
//           <Form
//             {...formItemLayout}
//             key="form"
//             form={form}
//             name="configuration-users"
//             onClose={() => {
//               handleClear();
//               setShowDrawer(false);
//             }}
//           >
//             <Form.Item name="id" hidden>
//               <Input />
//             </Form.Item>
//             <Form.Item
//               name="displayName"
//               hasFeedback
//               rules={[
//                 { required: true, message: "" },
//                 ({ _ }) => ({
//                   validator(_, value) {
//                     const textToValidate = new RegExp(/^[a-zA-Z\s]*$/);
//                     const res = textToValidate.test(value);

//                     if (!res) {
//                       return Promise.reject("Solo letras");
//                     } else {
//                       return Promise.resolve();
//                     }
//                   },
//                 }),
//               ]}
//             >
//               <TextField className="textField" label="Nombre y apellido" />
//             </Form.Item>
//             <Form.Item
//               name="identificationNumber"
//               hasFeedback
//               rules={[{ required: true, message: "" }]}
//             >
//               <TextField
//                 label="Nro. Identificación"
//                 className="textField"
//                 disabled={isEditing}
//                 onBlur={(value, object) =>
//                   verificationIdentification(value.target.value)
//                 }
//                 helperText={
//                   identificationVerification === true && isEditing === false ? (
//                     <p className="identificationVerification">
//                       El Numero de Documento Ingresado ya Existe en la Base de
//                       Datos
//                     </p>
//                   ) : (
//                     ""
//                   )
//                 }
//               />
//             </Form.Item>
//             <Form.Item
//               name="email"
//               hasFeedback
//               rules={[{ required: true, message: "", type: "email" }]}
//             >
//               <TextField
//                 label="Correo electrónico"
//                 className="textField"
//                 disabled={isEditing}
//                 onBlur={(value, object) =>
//                   verificationEmail(value.target.value)
//                 }
//                 helperText={
//                   emailVerification === true && isEditing === false ? (
//                     <p className="emailVerification">
//                       El Correo Electronico Ingresado ya Existe en la Base de
//                       Datos
//                     </p>
//                   ) : (
//                     ""
//                   )
//                 }
//               />
//             </Form.Item>
//             <Form.Item
//               name="phoneNumber"
//               hasFeedback
//               rules={[
//                 { required: true, message: "" },
//                 ({ _ }) => ({
//                   validator(_, value) {
//                     if (value && isNaN(value)) {
//                       return Promise.reject("Solo números");
//                     }
//                     if (value && (value.length < 10 || value.length > 10)) {
//                       return Promise.reject("Deben ser 10 dígitos");
//                     }

//                     return Promise.resolve();
//                   },
//                 }),
//               ]}
//             >
//               <TextField className="textField" label="Nro. Celular" />
//             </Form.Item>
//             <Form.Item name="address">
//               <TextField
//                 className="textField"
//                 label="Dirección de residencia"
//               />
//             </Form.Item>
//             <Form.Item
//               name="roleId"
//               hasFeedback
//               rules={[{ required: true, message: "" }]}
//             >
//               <InputLabel id="demo-simple-select-helper-label">Rol</InputLabel>
//               <Select
//                 labelId="demo-simple-select-helper-label"
//                 placeholder="Seleccione..."
//                 allowClear
//                 onChange={(value, object) => {
//                   if (value !== undefined) {
//                     setRole(object.children);
//                     return form.setFieldsValue({
//                       roleId: value,
//                       roleName: object.children,
//                     });
//                   }
//                 }}
//               >
//                 {dataRoles &&
//                   dataRoles.data &&
//                   dataRoles.data.roles.map((item) => {
//                     return (
//                       <Select.Option value={item.id}>
//                         {item.label}
//                       </Select.Option>
//                     );
//                   })}
//               </Select>
//             </Form.Item>
//             <Form.Item labelAlign="left" name="roleName" hidden>
//               <TextField className="textField" />
//             </Form.Item>
//             <Form.Item
//               labelAlign="left"
//               hidden={role !== "driver"}
//               name="driverLicense"
//               label="Nro. Licencia"
//               hasFeedback
//               rules={[{ required: role === "driver", message: "" }]}
//             >
//               <TextField className="textField" />
//             </Form.Item>

//             <Form.Item
//               labelAlign="left"
//               hasFeedback
//               name="distributionCenterId"
//               label="Centro de distribución"
//             >
//               <Select
//                 placeholder="Seleccione..."
//                 allowClear
//                 optionFilterProp="children"
//                 mode="multiple"
//                 style={{ width: "100%" }}
//                 onChange={(value, object) => {
//                   if (value !== undefined) {
//                     setDistributionCentersName(
//                       object.map((item) => {
//                         return item.children;
//                       })
//                     );

//                     return form.setFieldsValue({
//                       distributionCenterId: value,
//                       distributionCenter: object.children,
//                     });
//                   }

//                   return form.setFieldsValue({
//                     distributionCenterId: null,
//                     distributionCenter: "",
//                   });
//                 }}
//               >
//                 {distributionCenters.map((item) => {
//                   return (
//                     <Select.Option value={item.id}>{item.name}</Select.Option>
//                   );
//                 })}
//               </Select>
//             </Form.Item>
//             <Form.Item labelAlign="left" name="distributionCenter" hidden>
//               <TextField className="textField" />
//             </Form.Item>

//             <Form.Item
//               labelAlign="left"
//               name="enabled"
//               label="Habilitado"
//               valuePropName="checked"
//             >
//               <Switch />
//             </Form.Item>
//             <Form.Item
//               name="ValidarCorreo"
//               labelAlign="left"
//               label="Validar Correo"
//               valuePropName="checked"
//             >
//               <Switch
//                 onChange={(value) => {
//                   setVerificationemail(value);
//                 }}
//               />
//             </Form.Item>
//           </Form>
//         </Card>
//       </Modal>
//     </div>
//   );
// };

// export default UserDetailsScreen;
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Card,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  Box,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  OutlinedInput,
  Chip,
} from "@mui/material";
import Swal from "sweetalert2";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import localStorageService from "../../../../../services/localStorageService";
import axios from "axios";
import bcrypt from "bcryptjs";
import { useTranslation } from "react-i18next";
import {
  insertUser,
  updateUser,
  updateUserInsertAutomaticPassword,
  fetchRoles,
  cleanDistrutionCenter,
  updateDistributionCenters,
  checkIfIdentificationNumberEmailExists,
  checkIfIdentificationNumberExists,
  checkIfEmailExists,
} from "../../queries/userQueries";
import { red } from "@mui/material/colors";

const UserDetailsScreen = ({
  showDrawer,
  setShowDrawer,
  recordToEdit,
  setRecordToEdit,
  isEditing,
  setIsEditing,
  users,
  setUsers,
  dataDistributionCenters,
}) => {
  const [getRoles, { data: rolesData }] = useLazyQuery(fetchRoles, {
    fetchPolicy: "no-cache",
  });
  const [formData, setFormData] = useState({
    displayName: "",
    email: "",
    identificationNumber: "",
    phoneNumber: "",
    roleId: "",
    roleName: "",
    driverLicense: "",
    address: "",
    enabled: true,
    distributionCenterId: [],
  });

  const [identificationVerification, setIdentificationVerification] =
    useState(false);
  const companyId = localStorageService.get("companyId");
  const { t } = useTranslation();
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [distributionCentersName, setDistributionCentersName] = useState([]);
  const [insUser] = useMutation(insertUser, { fetchPolicy: "no-cache" });
  const [udpUser] = useMutation(updateUser, { fetchPolicy: "no-cache" });
  const [formErrors, setFormErrors] = useState({});
  const [role, setRole] = useState("");
  const [verificationemail, setVerificationemail] = useState(false);
  const apolloClient = useApolloClient();
  useEffect(() => {
    getRoles();
  }, [getRoles]);
  const [InsertPasswordAutomaticUser] = useMutation(
    updateUserInsertAutomaticPassword,
    { fetchPolicy: "no-cache" }
  );
  const renewDistributionCenters = async (id, distributionCenterId) => {
    await cleanDistCenter({ variables: { id } }).then((res) => {});
    const distributionCenters = distributionCenterId.map((item) => {
      return {
        userId: id,
        distributionCenterId: item,
      };
    });

    await updateDistCenter({
      variables: { distributionCenters },
    }).then((res) => {});
  };
  const validateForm = async () => {
    let errors = {};
    if (!formData.displayName) errors.displayName = "El nombre es requerido";
    if (!formData.identificationNumber)
      errors.identificationNumber = "El número de identificación es requerido";
    if (!formData.email) {
      errors.email = "El correo electrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "El correo electrónico no es válido";
    } else if (await verificationEmail(formData.email)) {
      errors.email =
        "El Correo Electronico Ingresado ya Existe en la Base de Datos";
    }
    if (!formData.phoneNumber)
      errors.phoneNumber = "El número de teléfono es requerido";
    if (!formData.roleId) errors.roleId = "El rol es requerido";
    // Agrega más validaciones según sea necesario

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    const resetForm = () => {
      setFormData({
        displayName: "",
        email: "",
        identificationNumber: "",
        phoneNumber: "",
        roleId: "",
        driverLicense: "",
        address: "",
        enabled: true,

        distributionCenterId: [],
      });
    };
    if (recordToEdit && recordToEdit.roleId !== undefined) {
      // const distributionCenterNames = recordToEdit.distributionCenterId
      //   .map(
      //     (id) =>
      //       dataDistributionCenters.data.distributionCenters.find(
      //         (center) => center.id === id
      //       )?.name
      //   )
      //   .filter(Boolean);
      setFormData({
        ...recordToEdit,
        roleId: recordToEdit.roleId.toString(),
        distributionCenterId: recordToEdit.distributionCenterId,
      });
    } else {
      resetForm();
    }
  }, [recordToEdit]);

  useEffect(() => {
    if (
      !dataDistributionCenters.loading &&
      dataDistributionCenters.data &&
      dataDistributionCenters.data.distributionCenters.length > 0
    ) {
      setDistributionCenters(
        dataDistributionCenters.data.distributionCenters.map((item) => ({
          id: item.id,
          name: item.name,
        }))
      );
    }
  }, [dataDistributionCenters.loading, dataDistributionCenters.data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "distributionCenterId") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, distributionCenterId: value });
  };
  const [emailVerification, setEmailVerification] = useState(false);
  const handleVerifiedEmailChange = (event) => {
    const { value } = event.target;
    setVerificationemail(value);
  };
  const verificationEmail = async (value) => {
    const email = value;
    apolloClient
      .query({
        query: checkIfEmailExists,
        variables: { email },
      })
      .then(({ data }) => {
        if (data.users.length > 0) {
          setEmailVerification(true);
        } else {
          setEmailVerification(false);
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          title: "Error",
          text: `Ocurrió un error. Consulte al administrador. ${error.toString()}`,
          icon: "error",
          confirmButtonText: "Cerrar",
          willClose: () => {
            return;
          },
        });
      });
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      const values = formData;
      const {
        id,
        displayName,
        email,
        identificationNumber,
        phoneNumber,
        roleId,
        roleName,
        driverLicense,
        address,
        photoURL,
        enabled,
        distributionCenter,
        distributionCenterId,
        hasRoutes,
      } = values;

      const operationVariables = {
        ...values,
        companyId,
        distributionCenterId: formData.distributionCenterId[0].toString(),
      };
      console.log(operationVariables);
      if (!isEditing) {
        insUser({
          variables: operationVariables,
        })
          .then((res) => {
            if (res.data.insert_users.affected_rows > 0) {
              const { id } = res.data.insert_users.returning[0];
              renewDistributionCenters(id, distributionCenterId);
              setUsers((current) => [
                ...current,
                {
                  id,
                  displayName,
                  email,
                  identificationNumber,
                  phoneNumber,
                  roleId,
                  roleName,
                  driverLicense,
                  address,
                  photoURL,
                  enabled,
                  distributionCenterId,
                  distributionCenter: `${distributionCentersName} ` ?? [],
                  hasRoutes,
                },
              ]);
              Swal.fire({
                title: "Registro de Usuario Exitoso",
                text: "Se Registro el Usuario Correctamente en el Sistema!",
                icon: "success",
                confirmButtonText: "Aceptar",
                willClose: () => {
                  verificationemail === true
                    ? Swal.fire({
                        title: "Enviar Verificación al Correo Electronico",
                        text: "Se Enviara al Correo Electronico Registrado una Notificacion de Verificación!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                        willClose: () => {
                          const data = {
                            id,
                            email,
                            displayName,
                            roleId,
                          };

                          handleSendEmailVerification(data);
                        },
                      })
                    : Swal.fire({
                        title: "Contraseña Automatica",
                        text: "Su Contraseña es el Numero de Documento Registrado en el sistema!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                        willClose: () => {
                          const data = { id, identificationNumber, email };
                          passwordIdentificationNumber(data);
                        },
                      });
                },
              });
            }

            handleClear();
            setShowDrawer(false);
          })
          .catch((error) => {
            console.error(error);
            if (
              error.message.includes(
                `Uniqueness violation. duplicate key value violates unique constraint "users_email_key"`
              )
            ) {
              setEmailVerification(true);
            }
          });
      } else {
        apolloClient
          .query({
            query: checkIfIdentificationNumberEmailExists,
            variables: { identificationNumber, email },
          })
          .then(async ({ data }) => {
            if (data.users.length > 0) {
              Swal.fire({
                title: "Error",
                text: "El número de identificación que intenta registrar ya existe para otra cuenta, ingrese otro.",
                icon: "error",
                confirmButtonText: "Cerrar",
                timer: 5000,
                timerProgressBar: true,
                willClose: () => {
                  return;
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);

            Swal.fire({
              title: "Error",
              text: `Ocurrió un error. Consulte al administrador. ${error.toString()}`,
              icon: "error",
              confirmButtonText: "Cerrar",
              willClose: () => {
                return;
              },
            });
          });
        udpUser({
          variables: {
            id,
            displayName,
            email,
            identificationNumber,
            phoneNumber,
            roleId,
            driverLicense,
            address,
            photoURL,
            enabled,
            distributionCenter,
          },
        })
          .then((res) => {
            if (res.data.update_users.affected_rows > 0) {
              renewDistributionCenters(id, distributionCenterId);
              const records = users;
              const indexToReplace = users.findIndex((item) => item.id === id);

              records.splice(indexToReplace, 1, {
                id,
                displayName,
                email,
                identificationNumber,
                phoneNumber,
                roleId,
                roleName,
                driverLicense,
                address,
                photoURL,
                enabled,
                distributionCenterId,
                distributionCenter: `${distributionCentersName} ` ?? [],
              });
              setUsers([...records]);

              Swal.fire({
                title: "Actualización exitosa",
                text: "Usuario actualizado exitosamente!",
                icon: "success",
                confirmButtonText: "Cerrar",
              });
            }
            handleClear();
            setShowDrawer(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const passwordIdentificationNumber = (data) => {
    const id = data.id;
    const email = data.email;
    const password = data.identificationNumber;

    bcrypt.genSalt(5, (err, salt) => {
      if (err == null) {
        bcrypt.hash(email + password, salt, (error, hash) => {
          if (error == null) {
            InsertPasswordAutomaticUser({
              variables: {
                id: id,
                password: hash,
              },
            })
              .then((res) => {
                if (res.data.update_users.affected_rows > 0) {
                  Swal.fire({
                    title: "Registro de Contraseña Exitoso",
                    text: "La Contraseña del Usuario fue Generada de Manera Correcta!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    willClose: () => {
                      return;
                    },
                  });
                }
              })
              .catch((er) => {
                Swal.fire({
                  title: "Error",
                  text: `Ocurrió un error al intentar procesar los datos. Muestre la siguiente información al administrador: ${er.toString()}`,
                  icon: "error",
                  confirmButtonText: "Cerrar",
                });
              });
          }
        });
      }
    });
  };

  const handleClear = () => {
    setRecordToEdit({});
    setIsEditing(false);
    setRole("");

    setFormData({
      id: "",
      displayName: "",
      email: "",
      identificationNumber: "",
      phoneNumber: "",
      roleId: undefined,
      roleName: undefined,
      driverLicense: undefined,
      address: undefined,
      photoURL: undefined,
      enabled: true,
      distributionCenterId: [],
      distributionCenter: null,
    });
  };

  const handleSendEmailVerification = (data) => {
    const companyBrand = JSON.parse(localStorageService.get("companyDetails"));

    axios({
      method: "post",
      url: process.env.REACT_APP_FUNCTION_EMAIL_VERIFICATION_URL,
      data: {
        id: data.id,
        email: data.email,
        displayName: data.displayName,
        roleId: data.roleId,
        origin: window.location.origin,
        bigBrand: companyBrand.bigBrand,
        companyName: localStorageService.get("company"),
        primaryColor: companyBrand.primaryColor,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: "Enviado",
            text: `El correo electrónico de verificación fue enviado.`,
            icon: "success",
            confirmButtonText: "Cerrar",
            timer: 2000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Error",
            text: `No fue posible enviar el correo electrónico de verificación.`,
            icon: "error",
            confirmButtonText: "Cerrar",
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: `No fue posible enviar el correo electrónico de verificación. ${error.toString()}`,
          icon: "error",
          confirmButtonText: "Cerrar",
          timer: 2000,
          timerProgressBar: true,
        });
      });
  };
  const [cleanDistCenter] = useMutation(cleanDistrutionCenter, {
    fetchPolicy: "no-cache",
  });
  const [updateDistCenter] = useMutation(updateDistributionCenters, {
    fetchPolicy: "no-cache",
  });

  const handleClose = () => {
    setShowDrawer(false);
  };

  return (
    <Modal
      open={showDrawer}
      onClose={handleClose}
      aria-labelledby="user-details-modal"
      aria-describedby="user-details-form"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="user-details-modal"
          variant="h6"
          component="h2"
          fontWeight="bold"
        >
          {isEditing ? "Modificar Usuario" : "Crear Usuario"}
        </Typography>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Nombre y Apellido"
            variant="outlined"
            name="displayName"
            value={formData.displayName}
            onChange={handleInputChange}
            size="small"
          />
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          error={!!formErrors.identificationNumber}
        >
          <TextField
            label="Nro. Identificación"
            variant="outlined"
            name="identificationNumber"
            value={formData.identificationNumber}
            onChange={handleInputChange}
            disabled={isEditing}
            helperText={formErrors.identificationNumber}
            size="small"
          />
        </FormControl>

        <FormControl fullWidth margin="normal" error={!!formErrors.email}>
          <TextField
            label="Correo Electrónico"
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={async (event) => {
              const email = event.target.value;
              if (await verificationEmail(email)) {
                setFormErrors({
                  ...formErrors,
                  email:
                    "El Correo Electronico Ingresado ya Existe en la Base de Datos",
                });
              } else {
                setFormErrors({
                  ...formErrors,
                  email: "",
                });
              }
            }}
            disabled={isEditing}
            helperText={formErrors.email}
            size="small"
          />
        </FormControl>

        {/* Phone Number */}
        <FormControl fullWidth margin="normal" error={!!formErrors.phoneNumber}>
          <TextField
            label="Nro. Celular"
            variant="outlined"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            helperText={formErrors.phoneNumber}
            size="small"
          />
        </FormControl>

        {/* Address */}
        <FormControl fullWidth margin="normal">
          <TextField
            label="Dirección de Residencia"
            variant="outlined"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            size="small"
          />
        </FormControl>

        <FormControl
          fullWidth
          margin="normal"
          error={!!formErrors.roleId}
          size="small"
        >
          <InputLabel id="role-label">Rol</InputLabel>
          <Select
            labelId="role-label"
            id="roleId"
            name="roleId"
            value={formData.roleId}
            onChange={handleInputChange}
            label="Rol"
          >
            {rolesData?.roles.map((role) => (
              <MenuItem key={role.id} value={role.id.toString()}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Distribution Center */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="distribution-center-label">
            Centro de Distribución
          </InputLabel>
          <Select
            labelId="distribution-center-label"
            id="distributionCenterId"
            multiple
            value={formData.distributionCenterId}
            onChange={handleSelectChange}
            input={
              <OutlinedInput
                id="select-multiple-chip"
                label="Centro de Distribución"
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  const center = distributionCenters.find(
                    (center) => center.id === value
                  );
                  return <Chip key={value} label={center ? center.name : ""} />;
                })}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: { maxHeight: 224, width: 250 },
              },
            }}
          >
            {distributionCenters.map((center) => (
              <MenuItem key={center.id} value={center.id}>
                {center.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.validateEmail}
              onChange={handleVerifiedEmailChange}
              name="validateEmail"
              sx={{
                "&.Mui-checked": {
                  color: red[600],
                },
              }}
            />
          }
          label="Validar Correo"
        />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            style={{
              borderRadius: "20px",
              color: "white",
              fontSize: "16px",
              fontWeight: "bold",
              background: "red",
            }}
          >
            {isEditing ? "Modificar" : "Crear"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserDetailsScreen;

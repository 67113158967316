import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Search from '@mui/icons-material/Search';
import { useTMSStore } from "../../../../../../../store/tms-store";
import "../../stylesTMS/styles-tms.css"

export const TMSSearchTextOvTrn = ({isBlankActiveOvtm}) => {
    const loading = useTMSStore((state) => state.loading);
    const setSearchTextOvTrn = useTMSStore((state) => state.setSearchTextOvTrn);
    const [searchFilterOvTrn, setSearchFilterOvTrn] = useState("");
    const [inputValue, setInputValue] = useState(isBlankActiveOvtm);

    useEffect(() => {
      setInputValue(isBlankActiveOvtm);
    }, [isBlankActiveOvtm]);
    const textFieldEvent = (ev) => {
      const inputValue = ev.target.value;
      setSearchFilterOvTrn(inputValue);
    };
      
    useEffect(() => {
      
        if (inputValue)  {  

          setSearchFilterOvTrn("");
      }
      }, [inputValue, setSearchTextOvTrn]);


    const textFieldOnKeyDown = (ev) => {
      if(ev.keyCode === 13){
        setSearchTextOvTrn(searchFilterOvTrn)
        //setInputValue(false);
        
      }
    };

    return (
      
      <TextField onKeyDown={textFieldOnKeyDown} onChange={textFieldEvent} label="Buscar" className="custom-filter" size={"large"} value={searchFilterOvTrn} color= "error" InputProps={{
        endAdornment: <InputAdornment position="end">
        <IconButton>
          {loading ? <CircularProgress size={10} color="error" /> :  <Search onClick={() => setSearchTextOvTrn(searchFilterOvTrn)} />}
        </IconButton>
      </InputAdornment>
      }}/>
      
  );
};

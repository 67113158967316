import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
  Box,
  Divider,
  Toolbar,
  List,
  Grid,
  Menu,
  menuItem,
  Paper,
  Popper,
  Grow,
  Tooltip,
} from "@mui/material";

// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//import moment from "moment";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
//import MenuIcon from "@mui/icons-material/Menu";
//import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import "./../../../layout/ui/css/miniDrawer.css";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../../context/UserContext";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import BusAlertOutlinedIcon from "@mui/icons-material/BusAlertOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import MailIcon from "@mui/icons-material/Mail";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import localStorageService from "../../../../services/localStorageService";
import moment, { locale } from "moment";

const companyBrand = JSON.parse(localStorageService.get("companyDetails"));

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// interface AppBarProps extends MuiAppBarProps {
//   open?: boolean;
// }

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

if (companyBrand && companyBrand.primaryColor) {
  document.documentElement.style.setProperty(
    "--primaryColor",
    companyBrand.primaryColor
  );
}

export default function MiniDrawer({
  children,
  user,
  title,
  clearSearchTarget,
  searchComponent,
  setSearchComponent,
  setFlag,
  selectedCenterId,
  setSelectedCenterId,
  selectedCenterName,
  setSelectedCenterName,
  setWarehousesName,
  logout,
  history,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation("global");
  const location = useLocation();
  // const { user } = useUser();

  const [roleName, setRoleName] = useState("");
  //const [currentTitle, setCurrentTitle] = useState(t("module-Dashboard.title"));
  //const [searchComponent, setSearchComponent] = useState(false);
  const [searchTarget, setSearchTarget] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const [openRenderMenu, setOpenRederMenu] = useState(false);
  const anchorRef = useRef(null);

  const isActive = (itemPath) => location.pathname === itemPath;

  useEffect(() => {
    setRoleName(user.roleName);
  }, [user]);

  // const logout = () => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   window.location.reload();
  // };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuToggle = () => {
    setOpenRederMenu((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenRederMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab" && event.key === "Escape") {
      event.preventDefault();
      setOpenRederMenu(false);
    }
  }

  const prevOpen = useRef(openRenderMenu);
  useEffect(() => {
    if (prevOpen.current === true && openRenderMenu === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = openRenderMenu;
  }, [openRenderMenu]);

  const menuId = "primary-search-account-menu";
  //const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    localStorageService.set(
      "selectedWarehouses",
      JSON.stringify({ warehouses: selectedCenterId })
    );
    setFlag((current) => current + 1);
  }, [selectedCenterId, setFlag]);

  useEffect(() => {
    localStorageService.set(
      "selectedWarehousesname",
      JSON.stringify({ warehousesname: selectedCenterName })
    );
    setFlag((current) => current + 1);
  }, [selectedCenterName, setFlag]);

  const menuItems = [
    {
      role: "admin",
      text: t("module-Dashboard.title"),
      icon: <DashboardOutlinedIcon />,
      link: "/",
    },
    {
      text: t("module-Upload-Data.title"),
      icon: <CloudUploadOutlinedIcon />,
      link: "/main-upload",
    },
    {
      text: t("module-Planning.title"),
      icon: <EditCalendarOutlinedIcon />,
      link: "/scheduling",
    },
    {
      text: t("module-Tracking-routes.title"),
      icon: <ShareLocationOutlinedIcon />,
      link: "/home",
    },
    {
      text: t("module-Routes-finished.title"),
      icon: <ManageSearchOutlinedIcon />,
      link: "/completed-routes",
    },
    {
      text: t("module-Delivery-Site.title"),
      icon: <Inventory2OutlinedIcon />,
      link: "/onSiteDeliveries",
    },
    {
      text: t("module-Incidents.title"),
      icon: <BusAlertOutlinedIcon />,
      link: "/incidents",
    },
  ];

  const option = [
    {
      value: "Occidente",
    },
    {
      value: "Centro",
    },
    {
      value: "Sur",
    },
    {
      value: "Oriente",
    },
    {
      value: "Norte",
    },
    {
      value: "Soacha",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar className="">
          <IconButton
            size="large"
            //color="inherit"
            aria-label={open ? "close drawer" : "open drawer"}
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 1,
              color: "white",
              //...(open && { display: "none" }),
            }}
          >
            {open ? <MenuOpenOutlinedIcon /> : <MenuOutlinedIcon />}
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}>
            {searchComponent ? (
              <div className="search-component">
                <Autocomplete
                  freeSolo
                  defaultValue={searchTarget}
                  options={option.map((option) => option.value)}
                  //filterOptions={true}
                  sx={{ width: 600, paddingRight: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      //label="Buscar..."
                      prefix={<ArrowLeftOutlinedIcon />}
                      sx={{ padding: "2px" }}
                    />
                  )}
                ></Autocomplete>
              </div>
            ) : (
              <div className="site-header">
                <div>
                  <Grid
                    xs={0}
                    sm={7}
                    xl={14}
                    key="title"
                    className="site-options-icon-container-left"
                  >
                    <h2 className="site-title">{title}</h2>
                  </Grid>
                  {title === "module-Dashboard.title" ? (
                    <Grid direction="row" container spacing={2} my={2.5}>
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disableFuture
                          label="Date From"
                          onChange={() => {}}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider> */}
                    </Grid>
                  ) : (
                    []
                  )}
                </div>
              </div>
            )}
            <IconButton
              size="large"
              color="inherit"
              onClick={() => {
                setSearchComponent(true);
                localStorage.setItem("searchComponent", true);
                setFlag((current) => current + 1);
              }}
              sx={{ mr: 2 }}
            >
              <SearchOutlinedIcon
              // onClick={() => {
              //   setSearchComponent(true);
              //   localStorage.setItem("searchComponent", true);
              //   setFlag((current) => current + 1);
              // }}
              // className="site-options-icon"
              />
            </IconButton>
            <IconButton
              size="large"
              color="inherit"
              onClick={() => {}}
              sx={{ mr: 2 }}
            >
              <NotificationsIcon />
            </IconButton>
            <IconButton
              ref={anchorRef}
              size="large"
              edge="end"
              aria-controls={openRenderMenu ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleMenuToggle}
              color="inherit"
              sx={{ mr: 2 }}
            >
              <PersonOutlineOutlinedIcon />
            </IconButton>
            <Popper
              open={openRenderMenu}
              anchorEl={anchorRef.current}
              transition
              disablePortal
              sx={{ width: "280px", boxShadow: theme.shadows[2] }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleMenuClose}>
                      <MenuList
                        autoFocusItem={openRenderMenu}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        //sx={{ marginTop: 0, backgroundColor: "" }}
                      >
                        <Stack
                          direction="column"
                          alignItems="center"
                          spacing={0}
                          sx={{ marginTop: 1 }}
                        >
                          <Avatar
                            style={{
                              backgroundColor: "red",
                              verticalAlign: "center",
                            }}
                            sizes="large"
                          >
                            {user.initials}
                          </Avatar>

                          <Typography variant="inherit" sx={{ marginTop: 1 }}>
                            {user.displayName}
                          </Typography>
                          <Typography variant="inherit">
                            {user.email}
                          </Typography>
                          <Typography>
                            <b>{localStorageService.get("company")}</b>
                          </Typography>
                        </Stack>
                        <MenuItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: theme.spacing(1),
                            marginTop: 2,
                          }}
                          onClick={() => {
                            handleMenuClose();
                            history.push("/change-password");
                          }}
                        >
                          <LockOutlinedIcon />
                          Cambiar contraseña
                        </MenuItem>
                        <MenuItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: theme.spacing(1),
                          }}
                          onClick={logout}
                        >
                          <LogoutOutlinedIcon />
                          Cerrar sesión
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box>
            {open ? (
              <div className="site-logo-container">
                <img className="site-logo" src={companyBrand.bigBrand} alt="" />
              </div>
            ) : (
              <div className="site-logo-container-collapsed">
                <img
                  className="site-logo-collapsed"
                  src={companyBrand.smallBrand}
                  alt=""
                />
              </div>
            )}
          </Box>
        </DrawerHeader>
        {/* <Divider /> */}
        <Typography
          className="title-text"
          variant="h6"
          sx={{
            marginTop: "10px",
          }}
        >
          {t("menu.title")}
        </Typography>
        <List>
          {menuItems.map((item, index) =>
            item.role ? (
              item.role === roleName && (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{
                    display: "block",
                    bgcolor: isActive(item.link) ? "transparent" : "inherit",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      ".MuiListItemIcon-root": {
                        borderRadius: "8px",
                        color: isActive(item.link) ? "white" : "black",
                        bgcolor: isActive(item.link)
                          ? "var(--primaryColor)"
                          : "transparent",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        padding: "8px",
                      },
                      ".MuiListItemText-primary": {
                        color: isActive(item.link)
                          ? "var(--primaryColor)"
                          : "black",
                      },
                    }}
                    component={Link}
                    to={item.link}
                    onClick={() => {
                      clearSearchTarget();
                      //setCurrentTitle(item.text);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                        color: "black",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            ) : (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    ".MuiListItemIcon-root": {
                      borderRadius: "8px",
                      color: isActive(item.link) ? "white" : "black",
                      bgcolor: isActive(item.link)
                        ? "var(--primaryColor)"
                        : "transparent",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "8px",
                    },
                    ".MuiListItemText-primary": {
                      color: isActive(item.link)
                        ? "var(--primaryColor)"
                        : "black",
                    },
                  }}
                  component={Link}
                  to={item.link}
                  onClick={() => {
                    clearSearchTarget();
                    //setCurrentTitle(item.text);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: "black",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
        <Divider
          sx={{
            borderColor: "#B0B0B0",
            width: "85%",
            margin: "20px auto",
            marginBottom: "20px",
          }}
        />
        <Typography
          className="title-text"
          variant="h6"
          sx={{
            marginTop: "5px",
          }}
        >
          {t("menu.subtitle")}
        </Typography>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              ".MuiListItemIcon-root": {
                borderRadius: "8px",
                color: isActive("/configuration") ? "white" : "black",
                bgcolor: isActive("/configuration")
                  ? "var(--primaryColor)"
                  : "transparent",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                padding: "8px",
              },
              ".MuiListItemText-primary": {
                color: isActive("/configuration")
                  ? "var(--primaryColor)"
                  : "black",
              },
            }}
            component={Link}
            to="/configuration"
            onClick={() => {
              clearSearchTarget();
              //setCurrentTitle(t("module-Configuration.title"));
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : "auto",
                justifyContent: "center",
                color: "black",
              }}
            >
              <SettingsSuggestOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={t("module-Configuration.title")}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          //p: 2
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

import { gql } from "@apollo/client";

export const updateBrandingColor = gql`
    mutation updateBrandingColor($id: uuid!, $primaryColor: String!) {
        update_companies(_set: {primaryColor: $primaryColor}, where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateBrandingImage = gql`
    mutation updateBrandingImage($id: uuid!, $bigBrand: String!) {
        update_companies(_set: {bigBrand: $bigBrand}, where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;
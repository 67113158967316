import { gql } from '@apollo/client';

export const updateConfigureOptions = gql`
mutation updateConfigureOptions($id: uuid, $shippingType : Boolean, $specialServiceType  : Boolean, $pickupType : Boolean, $deliveryBetweenBoodegasType  : Boolean, $TMS_Synchronizer  : Boolean, $userId  : uuid) {
    update_configureOptions(_set: {shippingType: $shippingType, specialServiceType: $specialServiceType, pickupType: $pickupType, deliveryBetweenBoodegasType: $deliveryBetweenBoodegasType, TMS_Synchronizer: $TMS_Synchronizer, userId: $userId}, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }

`;
export const queryConfigureOptions = gql`
query queryConfigureOptions {
    configureOptions {
      id
      shippingType
      specialServiceType
      pickupType
      deliveryBetweenBoodegasType
      TMS_Synchronizer
      userId
    }
    }
`;
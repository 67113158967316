import React, { useState, useEffect } from "react";
import {
  Layout,
  Dropdown,
  Avatar,
  Typography,
  Menu,
  DatePicker,
  Input,
  AutoComplete,
  Select,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SearchOutlined,
  BellOutlined,
  UserOutlined,
  LogoutOutlined,
  LockOutlined,
} from "@ant-design/icons";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import FormatIndentDecreaseOutlinedIcon from "@mui/icons-material/FormatIndentDecreaseOutlined";
import FormatIndentIncreaseOutlinedIcon from "@mui/icons-material/FormatIndentIncreaseOutlined";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import { SideMenuScreen } from "./SideMenuScreen";
import { SideMenuScreen2 } from "./SideMenuScreen2";
import localStorageService from "../../../../services/localStorageService";
import loggerCooltrack from "../../../../services/logger-cooltrack";
import { fetchWarehouses } from "../queries/LayoutQueries";
import "../css/LayoutScreen.css";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { useTMSStore } from "../../../../store/tms-store";
import { Box } from "@mui/material";
import MiniDrawer from "../screens/MiniDrawer";

const { Search } = Input;
const { Footer, Sider, Content, Header } = Layout;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const companyBrand = JSON.parse(localStorageService.get("companyDetails"));

const companyId = localStorageService.get("companyId");
const role = localStorageService.get("role");
const defaultDistributionCenter = localStorageService.get(
  "userDistributionCenterId"
);
const userDistributionCenterName = localStorageService.get(
  "userDistributionCenterName"
);
const userDistributionsCenters = JSON.parse(
  localStorageService.get("userDistributionsCenters")
);
const userDistributionsCentersName = JSON.parse(
  localStorageService.get("userDistributionsCentersName")
);

export const LayoutScreen = ({ children, user, title, setFlag }) => {
  const { t } = useTranslation("global");
  const setWarehousesName = useTMSStore((state) => state.setWarehousesName);

  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [searchComponent, setSearchComponent] = useState(false);

  const [visible, setVisible] = useState(true);

  const [searchTarget, setSearchTarget] = useState("");
  const [selectedCenterId, setSelectedCenterId] = useState(
    JSON.parse(localStorageService.get("userDistributionsCenters"))
  );
  const [selectedCenterName, setSelectedCenterName] = useState(
    JSON.parse(localStorageService.get("userDistributionsCentersName"))
  );

  const [getWarehouses, dataWarehouses] = useLazyQuery(fetchWarehouses, {
    fetchPolicy: "no-cache",
  });

  const onClose = () => {
    setVisible(false);
  };

  const logout = async () => {
    const local = localStorage.getItem("userData");
    let usr = {};

    if (!local || local.length === 0) {
      const session = sessionStorage.getItem("userData");
      usr = JSON.parse(session);
    } else {
      usr = JSON.parse(local);
    }

    await loggerCooltrack({
      module: "Login",
      operation: "Logout",
      target: usr.companyId,
    });

    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  const toggle = () => {
    setCollapsed(!collapsed);
    setVisible((current) => !current);
  };

  useEffect(() => {
    getWarehouses({
      variables: {
        companyId,
      },
    });

    if (companyBrand && companyBrand.primaryColor) {
      //const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');
      document.documentElement.style.setProperty(
        "--primaryColor",
        companyBrand.primaryColor
      );
    }
  }, []);

  useEffect(() => {
    localStorageService.set(
      "selectedWarehouses",
      JSON.stringify({
        warehouses: selectedCenterId,
      })
    );
    setFlag((current) => current + 1);
  }, [selectedCenterId]);

  useEffect(() => {
    setWarehousesName(selectedCenterName);

    localStorageService.set(
      "selectedWarehousesname",
      JSON.stringify({
        warehousesname: selectedCenterName,
      })
    );
    setFlag((current) => current + 1);
  }, [selectedCenterName]);

  const menu2 = (
    <Menu style={{ marginTop: 20 }}>
      <div className="main-menu">
        <Row>
          <Col key="avatar-select" span={24} style={{ textAlign: "center" }}>
            <Avatar
              style={{ backgroundColor: "red", verticalAlign: "center" }}
              size="large"
            >
              {user.initials}
            </Avatar>
          </Col>
        </Row>
        <Row>
          <Col
            key="displayname-select"
            span={24}
            style={{ textAlign: "center" }}
          >
            {user.displayName}
          </Col>
        </Row>
        <Row>
          <Col key="email-select" span={24} style={{ textAlign: "center" }}>
            {user.email}
          </Col>
        </Row>
        <Row>
          <Col key="company-select" span={24} style={{ textAlign: "center" }}>
            <b>{localStorageService.get("company")}</b>
          </Col>
        </Row>
      </div>
      <Menu.Item
        key="1"
        icon={<LockOutlined />}
        onClick={() => history.push("/change-password")}
      >
        Cambiar contraseña
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={logout}>
        <Link to="">Cerrar sesión</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="main-container">
      <MiniDrawer
        clearSearchTarget={() => {
          localStorage.setItem("searchTarget", "");
          localStorage.setItem("searchComponent", false);
          setSearchTarget("");
          setSearchComponent(false);
          setFlag((current) => current + 1);
        }}
        user={user}
        title={title}
        searchComponent={searchComponent}
        setSearchComponent={setSearchComponent}
        setFlag={setFlag}
        selectedCenterId={selectedCenterId}
        setSelectedCenterId={setSelectedCenterId}
        selectedCenterName={selectedCenterName}
        setSelectedCenterName={setSelectedCenterName}
        setWarehousesName={setWarehousesName}
        logout={logout}
        history={history}
      >
        {children}
      </MiniDrawer>

      {/* <Layout className="bg-color" hasSider>
        <Sider
          collapsedWidth={80}
          width={250}
          className="sider-menu"
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          {collapsed ? (
            <div className="site-logo-container-collapsed">
              <img
                className="site-logo-collapsed"
                src={companyBrand.smallBrand}
                alt=""
              />
            </div>
          ) : (
            <div className="site-logo-container">
              <img className="site-logo" src={companyBrand.bigBrand} alt="" />
            </div>
          )}

          <SideMenuScreen2
            clearSearchTarget={() => {
              localStorage.setItem("searchTarget", "");
              localStorage.setItem("searchComponent", false);
              setSearchTarget("");
              setSearchComponent(false);
              setFlag((current) => current + 1);
            }}
          />
        </Sider>

        <Layout>
          <Header className="site-layout-background">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "triggerHeader",
                style: { marginTop: "20px" },
                onClick: () => toggle(),
              }
            )}

            {searchComponent ? (
              <div className="search-component">
                <AutoComplete
                  defaultValue={searchTarget}
                  options={
                    searchTarget.length > 0
                      ? [
                          {
                            value: "Occidente",
                          },
                          {
                            value: "Centro",
                          },
                          {
                            value: "Sur",
                          },
                          {
                            value: "Oriente",
                          },
                          {
                            value: "Norte",
                          },
                          {
                            value: "Soacha",
                          },
                        ]
                      : []
                  }
                  filterOption={true}
                  style={{ width: "100%", paddingRight: "10px" }}
                  onSelect={(value) => {
                    setSearchTarget(value);
                    localStorage.setItem("searchTarget", value);
                    setFlag((current) => current + 1);
                  }}
                  onSearch={(value) => {
                    setSearchTarget(value);
                    localStorage.setItem("searchTarget", value);
                    setFlag((current) => current + 1);
                  }}
                >
                  <Input
                    placeholder="Buscar..."
                    allowClear
                    bordered={false}
                    prefix={
                      <ArrowLeftOutlined
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          localStorage.setItem("searchTarget", "");
                          localStorage.setItem("searchComponent", false);
                          setSearchTarget("");
                          setSearchComponent(false);
                          setFlag((current) => current + 1);
                        }}
                      />
                    }
                  />
                </AutoComplete>
              </div>
            ) : (
              <div className="site-header">
                <div>
                  <Row>
                    <Col
                      xs={0}
                      sm={7}
                      xl={14}
                      key="title"
                      className="site-options-icon-container-left"
                    >
                      <h2 className="site-title">{title}</h2>
                    </Col>

                    {title === "module-Dashboard.title" ? (
                      <Col
                        xs={collapsed ? 11 : 0}
                        sm={10}
                        xl={6}
                        key="rangepicker-select"
                        className="site-options-icon-container-right"
                      >
                        <RangePicker
                          format={dateFormat}
                          defaultValue={
                            localStorage.getItem("range-date-pure-1") !==
                              null ||
                            localStorage.getItem("range-date-pure-2") !== null
                              ? [
                                  moment(
                                    localStorage.getItem("range-date-pure-1")
                                  ),
                                  moment(
                                    localStorage.getItem("range-date-pure-2")
                                  ),
                                ]
                              : [moment(), moment()]
                          }
                          className="site-daterange"
                          onChange={(dates, datesString) => {
                            if (dates && dates.length > 0) {
                              localStorage.setItem(
                                "range-date-1",
                                `${datesString[0]}T05:00:00+00:00`
                              );
                              localStorage.setItem(
                                "range-date-2",
                                `${datesString[1]}T05:00:00+00:00`
                              );
                              localStorage.setItem(
                                "range-date-pure-1",
                                `${dates[0]}`
                              );
                              localStorage.setItem(
                                "range-date-pure-2",
                                `${dates[1]}`
                              );
                            }
                            setFlag((current) => current + 1);
                          }}
                        />
                      </Col>
                    ) : null}

                    {title === "module-Planning.title" ? (
                      <Col
                        xs={collapsed ? 11 : 0}
                        sm={10}
                        xl={6}
                        key="warehouse-select-planning"
                        className="site-options-icon-container-right"
                      >
                        <Select
                          mode="multiple"
                          disabled={role !== "admin"}
                          allowClear
                          maxTagCount="responsive"
                          placeholder="Seleccione Centro..."
                          value={selectedCenterId}
                          onChange={(value, object) => {
                            setSelectedCenterId(value);
                          }}
                          className="site-daterange"
                          showSearch
                          style={{
                            width: 200,
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {dataWarehouses.called &&
                            dataWarehouses.data !== undefined &&
                            dataWarehouses.data.distributionCenters.map(
                              (item) => {
                                return (
                                  <Select.Option
                                    value={item.id}
                                    style={{
                                      backgroundColor:
                                        userDistributionsCenters?.filter(
                                          (distributionCenterId) =>
                                            distributionCenterId === item.id
                                        ).length > 0
                                          ? "rgba(0,0,250,0.10)"
                                          : "rgba(0,0,0,0)",
                                    }}
                                  >
                                    {item.name}
                                  </Select.Option>
                                );
                              }
                            )}
                        </Select>
                      </Col>
                    ) : null}

                    {title === "Seguimiento Rutas" ? (
                      <Col
                        xs={collapsed ? 11 : 0}
                        sm={10}
                        xl={6}
                        key="warehouse-select-tracking"
                        className="site-options-icon-container-right"
                      >
                        <Select
                          mode="multiple"
                          disabled={role !== "admin"}
                          allowClear
                          maxTagCount="responsive"
                          placeholder="Seleccione Centro..."
                          value={selectedCenterId}
                          onChange={(value, object) => {
                            setSelectedCenterId(value);
                          }}
                          className="site-daterange"
                          showSearch
                          style={{
                            width: 200,
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {dataWarehouses.called &&
                            dataWarehouses.data !== undefined &&
                            dataWarehouses.data.distributionCenters.map(
                              (item) => {
                                return (
                                  <Select.Option
                                    value={item.id}
                                    style={{
                                      backgroundColor:
                                        userDistributionsCenters?.filter(
                                          (distributionCenterId) =>
                                            distributionCenterId === item.id
                                        ).length > 0
                                          ? "rgba(0,0,250,0.10)"
                                          : "rgba(0,0,0,0)",
                                    }}
                                  >
                                    {item.name}
                                  </Select.Option>
                                );
                              }
                            )}
                        </Select>
                      </Col>
                    ) : null}

                    {title === "Rutas Finalizadas" ? (
                      <Col
                        xs={collapsed ? 11 : 0}
                        sm={10}
                        xl={6}
                        key="warehouse-select-end-routes"
                        className="site-options-icon-container-right"
                      >
                        <Select
                          mode="multiple"
                          disabled={role !== "admin"}
                          allowClear
                          maxTagCount="responsive"
                          placeholder="Seleccione Centro..."
                          value={selectedCenterId}
                          onChange={(value, object) => {
                            setSelectedCenterId(value);
                          }}
                          className="site-daterange"
                          showSearch
                          style={{
                            width: 200,
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {dataWarehouses.called &&
                            dataWarehouses.data !== undefined &&
                            dataWarehouses.data.distributionCenters.map(
                              (item) => {
                                return (
                                  <Select.Option
                                    value={item.id}
                                    style={{
                                      backgroundColor:
                                        userDistributionsCenters?.filter(
                                          (distributionCenterId) =>
                                            distributionCenterId === item.id
                                        ).length > 0
                                          ? "rgba(0,0,250,0.10)"
                                          : "rgba(0,0,0,0)",
                                    }}
                                  >
                                    {item.name}
                                  </Select.Option>
                                );
                              }
                            )}
                        </Select>
                      </Col>
                    ) : null}
                    {title === "Entrega en Sitio" ? (
                      <Col
                        xs={collapsed ? 11 : 0}
                        sm={10}
                        xl={6}
                        key="warehouse-select-on-site-delivery"
                        className="site-options-icon-container-right"
                      >
                        <Select
                          mode="multiple"
                          disabled={role !== "admin"}
                          allowClear
                          maxTagCount="responsive"
                          placeholder="Seleccione Centro..."
                          value={selectedCenterId}
                          onChange={(value, object) => {
                            setSelectedCenterId(value);
                          }}
                          className="site-daterange"
                          showSearch
                          style={{
                            width: 200,
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {dataWarehouses.called &&
                            dataWarehouses.data !== undefined &&
                            dataWarehouses.data.distributionCenters.map(
                              (item) => {
                                return (
                                  <Select.Option
                                    value={item.id}
                                    style={{
                                      backgroundColor:
                                        userDistributionsCenters?.filter(
                                          (distributionCenterId) =>
                                            distributionCenterId === item.id
                                        ).length > 0
                                          ? "rgba(0,0,250,0.10)"
                                          : "rgba(0,0,0,0)",
                                    }}
                                  >
                                    {item.name}
                                  </Select.Option>
                                );
                              }
                            )}
                        </Select>
                      </Col>
                    ) : null}
                    {title === "Incidentes" ? (
                      <Col
                        xs={collapsed ? 11 : 0}
                        sm={10}
                        xl={6}
                        key="warehouse-select-incidents"
                        className="site-options-icon-container-right"
                      >
                        <Select
                          mode="multiple"
                          disabled={role !== "admin"}
                          allowClear
                          maxTagCount="responsive"
                          placeholder="Seleccione Centro..."
                          value={selectedCenterId}
                          onChange={(value, object) => {
                            setSelectedCenterId(value);
                          }}
                          className="site-daterange"
                          showSearch
                          style={{
                            width: 200,
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {dataWarehouses.called &&
                            dataWarehouses.data !== undefined &&
                            dataWarehouses.data.distributionCenters.map(
                              (item) => {
                                return (
                                  <Select.Option
                                    value={item.id}
                                    style={{
                                      backgroundColor:
                                        userDistributionsCenters?.filter(
                                          (distributionCenterId) =>
                                            distributionCenterId === item.id
                                        ).length > 0
                                          ? "rgba(0,0,250,0.10)"
                                          : "rgba(0,0,0,0)",
                                    }}
                                  >
                                    {item.name}
                                  </Select.Option>
                                );
                              }
                            )}
                        </Select>
                      </Col>
                    ) : null}

                    {title === "TMS Carga" ? (
                      <Col
                        xs={collapsed ? 11 : 0}
                        sm={10}
                        xl={6}
                        key="warehouse-select-loads"
                        className="site-options-icon-container-right"
                      >
                        <Select
                          mode="multiple"
                          disabled={role !== "admin"}
                          allowClear
                          maxTagCount="responsive"
                          placeholder="Seleccione Centro..."
                          value={selectedCenterName}
                          onChange={(value, object) => {
                            setSelectedCenterName(value);
                          }}
                          className="site-daterange"
                          showSearch
                          style={{
                            width: 200,
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {dataWarehouses.called &&
                            dataWarehouses.data !== undefined &&
                            dataWarehouses.data.distributionCenters.map(
                              (item) => {
                                return (
                                  <Select.Option
                                    key={item.id}
                                    value={item.name}
                                    style={{
                                      backgroundColor:
                                        userDistributionsCentersName?.filter(
                                          (distributionCenterName) =>
                                            distributionCenterName === item.name
                                        ).length > 0
                                          ? "rgba(0,0,250,0.10)"
                                          : "rgba(0,0,0,0)",
                                    }}
                                  >
                                    {item.name}
                                  </Select.Option>
                                );
                              }
                            )}
                        </Select>
                      </Col>
                    ) : null}

                    <Col
                      xs={
                        title === "Dashboard" ||
                        title === "Planificación" ||
                        title === "Seguimiento Rutas" ||
                        title === "Rutas Finalizadas" ||
                        title === "Incidentes" ||
                        title === "TMS Carga ||" ||
                        title === "Entrega en Sitio"
                          ? collapsed
                            ? 4
                            : 8
                          : collapsed
                          ? { span: 4, offset: 10 }
                          : { span: 8, offset: 0 }
                      }
                      sm={
                        title === "Dashboard" ||
                        title === "Planificación" ||
                        title === "Seguimiento Rutas" ||
                        title === "Rutas Finalizadas" ||
                        title === "Incidentes" ||
                        title === "TMS Carga" ||
                        title === "Entrega en Sitio"
                          ? 2
                          : { span: 2, offset: 10 }
                      }
                      xl={
                        title === "Dashboard" ||
                        title === "Planificación" ||
                        title === "Seguimiento Rutas" ||
                        title === "Rutas Finalizadas" ||
                        title === "Incidentes" ||
                        title === "TMS Carga" ||
                        title === "Entrega en Sitio"
                          ? 1
                          : { span: 1, offset: 6 }
                      }
                      key="search-select"
                      className="site-options-icon-container-right"
                    >
                      <SearchOutlined
                        onClick={() => {
                          setSearchComponent(true);
                          localStorage.setItem("searchComponent", true);
                          setFlag((current) => current + 1);
                        }}
                        className="site-options-icon"
                      />
                    </Col>

                    <Col
                      xs={collapsed ? 4 : 8}
                      sm={2}
                      xl={1}
                      key="bell-select"
                      className="site-options-icon-container-right"
                    >
                      <BellOutlined
                        onClick={() => {}}
                        className="site-options-icon"
                      />
                    </Col>
                    <Col
                      xs={collapsed ? 4 : 8}
                      sm={2}
                      xl={1}
                      key="dropdown-select"
                      className="site-options-icon-container-right"
                    >
                      <Dropdown overlay={menu2}>
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <UserOutlined
                            onClick={() => {}}
                            className="site-options-icon"
                          />
                        </a>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </Header>

          <Content className="site-children">{children}</Content>
        </Layout>
      </Layout> */}
    </div>
  );
};

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Result, Button, Spin, Col, Row } from 'antd';
import { AimOutlined, SwapOutlined, HeatMapOutlined, BorderOutlined, NodeIndexOutlined } from '@ant-design/icons';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
    Polyline,
    TrafficLayer,
    Polygon,
    DistanceMatrixService
} from '@react-google-maps/api';
import { useLazyQuery } from '@apollo/client';
import { useScheduling } from '../../context/SchedulingContext';
import { basicTheme } from '../../../../map-themes/basicTheme';
import { fetchEnabledSectors } from '../../../sectors/queries/sectorsQueries';
import pin from '../../../../images/PuntoAzul.png';
import pin_selected from '../../../../images/PuntoAzulVacio.png';
import free_pin from '../../../../images/PuntoVerde.png';
import free_pin_selected from '../../../../images/PuntoVerdeVacio.png';
import currentLocationPin from '../../../../images/PuntoNaranjaVacio.png';
import finishPin from '../../../../images/PuntoMetaVacio.png';
import finishPin_selected from '../../../../images/PuntoMeta.png';
import truckPin from '../../../../images/IconoVehiculo.png';

const libraries = ["places"];
const mapContainerStyle = {
    width: '100%',
    height: '80vh'
};
const options = {
    styles: basicTheme,
    disableDefaultUI: true,
    zoomControl: false
};

export const MapScreen = (props) => {
    const { 
        ordersInVehicleTemp, 
        selectedUserVehicle, 
        orders, 
        onClickToLeftButton, 
        onItemToLeftButton,
        onItemToRightButton,
        onClickToRightButton, 
        fetching,
        setFetching,
        onCalcRoute } = useScheduling();
    const [getSectors, dataSectors] = useLazyQuery(fetchEnabledSectors, { fetchPolicy: 'no-cache' });

    const [markers, setMarkers] = useState([]);
    const [mapMarkers, setMapMarkers] = useState(null);

    const [polylines, setPolylines] = useState([]);
    const [selectedPin, setSelectedPin] = useState(null);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [loadingLocation, setLoadingLocation] = useState(true);
    const [locationError, setLocationError] = useState('');
    const [trafficLayer, setTrafficLayer] = useState(false);
    const [sectors, setSectors] = useState([]);

    const [selectionMode, setSelectionMode] = useState(false);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries
    });

    useEffect(() => {
        getSectors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (isLoaded) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    if (isMounted) {
                        setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
                        
                        setMarkers((current) => [...current, {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                            markerId: 'currentLocation',
                            icon: currentLocationPin,
                            scaledSize: new window.google.maps.Size(52, 52),
                        }]);
                        
                        setLoadingLocation(false);
                    }
                },
                function (error) {
                    if (error.code === 1) {
                        setLocationError('Ubicación denegada por el usuario.');
                    } else if (error.code === 2) {
                        setLocationError('Ubicación no disponible.');
                    } else if (error.code === 3) {
                        setLocationError('Tipo de espera agotado.');
                    }
                    setLoadingLocation(false);
                }
            );
/*
            const service = new window.google.maps.DistanceMatrixService();
        
        const origin1 = { lat: 55.93, lng: -3.118 };
        const origin2 = "Greenwich, England";
        const destinationA = "Stockholm, Sweden";
        const destinationB = { lat: 50.087, lng: 14.421 };
        const request = {
            origins: [origin1, origin2],
            destinations: [destinationA, destinationB],
            travelMode: window.google.maps.TravelMode.DRIVING,
            unitSystem: window.google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
        };
       
        service.getDistanceMatrix(request).then((response) => {
          
        });
        */
        }

        return () => { isMounted = false };
    }, [isLoaded])


    useEffect(() => {
        if (isLoaded) {
            let pathCoords = []
            let prevDestination = ''
            let isFirst = true;
            let allPolylines = [];
            let truckMarkerInfo = {};

            /*

            const service = new window.google.maps.DistanceMatrixService();
            // build request
            const origin1 = { lat: 55.93, lng: -3.118 };
            const origin2 = "Greenwich, England";
            const destinationA = "Stockholm, Sweden";
            const destinationB = { lat: 50.087, lng: 14.421 };
            const request = {
                origins: [origin1, origin2],
                destinations: [destinationA, destinationB],
                travelMode: window.google.maps.TravelMode.DRIVING,
                unitSystem: window.google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
            };

            service.getDistanceMatrix(request).then((response) => {
       
            });

            */

            setMarkers([]);
            setPolylines([]);

            if (Object.keys(selectedUserVehicle).length > 0 && selectedUserVehicle.schedulingStartLocation !== null) {
                const latLnt = selectedUserVehicle?.schedulingStartLocation?.split(',');
                if (latLnt?.length === 2) {
                    
                    setMarkers([{
                        lat: parseFloat(latLnt[0]),
                        lng: parseFloat(latLnt[1]),
                        markerId: 'schedulingStartLocation',
                        icon: truckPin,
                        scaledSize: new window.google.maps.Size(45, 52),
                    }]);
                    
                    truckMarkerInfo = {
                        destination: selectedUserVehicle.schedulingStartLocation,
                        markerId: 'schedulingStartLocation',
                    }

                    isFirst = false;
                    prevDestination = truckMarkerInfo.destination
                    allPolylines.push({ pathCoords: [], polylineId: 'schedulingStartLocation' });
                }
            }

            

            for (let index = 0; index < orders.length; index++) {
                const item = orders[index];

                if(ordersInVehicleTemp.filter(item2 => item2.orderNumber === item.orderNumber).length === 0){
                    
                    const isSelected = props.selectedRowKeys.filter(element => element === item.id).length > 0;

                    setMarkers((current) => [...current, {
                        order: index + 1,
                        orderType: 'ordersFree',
                        orderItem: item,
                        orderNumber:item.orderNumber,
                        lat: parseFloat(item.destination?.split(',')[0]),
                        lng: parseFloat(item.destination?.split(',')[1]),
                        markerId: item.id,
                        address: item.address,
                        addressComplement: item.addressComplement,
                        icon: isSelected ? free_pin_selected : free_pin,
                        isSelected,
                        scaledSize: new window.google.maps.Size(52, 52),
                    }]);
                    
                }
            }

            for (let index = 0; index < ordersInVehicleTemp.length; index++) {
                const item = ordersInVehicleTemp[index];

                const isSelected = props.selectedRowKeysOIV.filter(element => element === item.id).length > 0;
                 
                setMarkers((current) => [...current, {
                    order: index + 1,
                    orderType: 'ordersInVehicle',
                    orderItem: item,
                    orderNumber:item.orderNumber,
                    lat: parseFloat(item.destination?.split(',')[0]),
                    lng: parseFloat(item.destination?.split(',')[1]),
                    markerId: item.id,
                    address: item.address,
                    addressComplement: item.addressComplement,
                    icon: index !== ordersInVehicleTemp.length - 1 ? isSelected ? pin_selected : pin : isSelected ? finishPin_selected : finishPin,
                    isSelected,
                    scaledSize: index !== ordersInVehicleTemp.length - 1 ? new window.google.maps.Size(52, 52) : new window.google.maps.Size(52, 52),
                }]);
                
            }

            ordersInVehicleTemp.map((item) => {
                if (!isFirst) {
                    let pathFrom = {
                        lat: parseFloat(prevDestination?.split(',')[0]),
                        lng: parseFloat(prevDestination?.split(',')[1])
                    }

                    let pathTo = {
                        lat: parseFloat(item.destination?.split(',')[0]),
                        lng: parseFloat(item.destination?.split(',')[1])
                    }

                    let arr = [pathFrom, pathTo];
                    pathCoords = arr;
                    prevDestination = item.destination;
                } else {
                    pathCoords = [];
                    prevDestination = item.destination;
                    isFirst = false;
                }

                return allPolylines.push({ pathCoords, polylineId: item.id });
            });
            setPolylines(allPolylines);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersInVehicleTemp, orders, isLoaded, props.selectedRowKeysOIV, props.selectedRowKeys]);

    useEffect(() => {
        if (dataSectors.data && dataSectors.data.sectors) {
            const records = [];

            // eslint-disable-next-line
            dataSectors.data.sectors.map((item) => {
                const { id, name, color, sectorPolygons } = item;

                records.push({
                    id,
                    name,
                    color,
                    sectorPolygons,
                    options: {
                        fillColor: color,
                        fillOpacity: 0.4,
                        strokeColor: "red",
                        strokeOpacity: 1,
                        strokeWeight: 1.3,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        geodesic: false,
                        zIndex: 1
                    }
                })
            });

            setSectors(records);
        }
    }, [dataSectors]);

    useEffect(() => {
        return () => {
            mapRef.current = null;
        }
    }, []);

    const onLoadLocation = () => {
        setLoadingLocation(true);
        navigator.geolocation.getCurrentPosition(
            function (position) {
                const newMarkers = markers.filter((item) => item.markerId !== 'currentLocation');

                setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
                
                setMarkers([...newMarkers, {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    markerId: 'currentLocation',
                    icon: currentLocationPin,
                    scaledSize: new window.google.maps.Size(52, 52)
                }]);
                
                setLoadingLocation(false);
            },
            function (error) {
                if (error.code === 1) {
                    setLocationError('Ubicación denegada por el usuario.');
                } else if (error.code === 2) {
                    setLocationError('Ubicación no disponible.');
                } else if (error.code === 3) {
                    setLocationError('Tipo de espera agotado.');
                }
                setLoadingLocation(false);
            }
        );
    };

    const mapRef = useRef();

    const onMapClick = useCallback((e) => {
        /*setMarkers((current) => [...current, {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
            time: new Date()
        }])*/

        setSelectedPin(null);
    }, []);

    useEffect(() => {
        if(markers.length > 0){

            setMapMarkers(markers.map((marker, index) => {
            
                return (
                    <Marker
                        key={index}
                        //label={marker.orderNumber}
                        label={`${marker.isSelected ? '' : marker.order || ''}`}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        onClick={() => {
                            if(selectionMode){
                                if(marker.orderType === 'ordersInVehicle'){
                                    onItemToRightButton(marker.orderItem);
                                    props.removeItemOIV(marker.orderItem);
                                }else if(marker.orderType === 'ordersFree'){
                                    onItemToLeftButton(marker.orderItem);
                                    props.removeItem(marker.orderItem);
                                }
                            }else{
                                if(marker.orderType === 'ordersInVehicle'){
                                   
                                    if(marker.isSelected){
                                        props.removeItemOIV(marker.orderItem);
                                    }else{
                                        props.addItemOIV(marker.orderItem);
                                    }
                                    
                                }else if(marker.orderType === 'ordersFree'){
                                    
                                    if(marker.isSelected){
                                        props.removeItem(marker.orderItem);
                                    }else{
                                        props.addItem(marker.orderItem);
                                    }
                                }
                            }
                        }}
                        onRightClick={() => setSelectedPin(marker)}
                        icon={marker.icon !== null
                            ?
                            marker.markerId === 'schedulingStartLocation'
                                ?
                                {
                                    url: marker.icon,
                                    scaledSize: marker.scaledSize,
                                    //origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(15, 26)
                                }
                                :
                                {
                                    url: marker.icon,
                                    scaledSize: marker.scaledSize,
                                    //origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(7, 42)

                                } : null}
                       
                    />
                )
            }));
            }
    }, [markers, props.selectedRowKeysOIV, props.selectedRowKeys]);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const format_number = str => {
        str += '';
        let x = str.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    };

    if (loadError || locationError !== '') {
        return (
            <div style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Result
                    style={{
                        left: '50%',
                        top: '50%',

                    }}
                    status="warning"
                    title={locationError}
                    subTitle="Esto no impide seguir utilizando el sitio"
                    extra={[
                        <Button
                            type="primary"
                            shape="round"
                            size="large"
                            onClick={() => { setLocationError('') }}
                        >
                            {loadError ? 'Aceptar' : 'Cerrar y continuar'}
                        </Button>,
                    ]}
                />
            </div>
        );
    }


    if (!isLoaded) {
        return "Cargando mapas...";
    }
    

    return (
        <div className="mapa-google">
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={15}
                center={center}
                options={options}
                onClick={onMapClick}
                onLoad={onMapLoad}
                id="mapa-google-map"
            >
                <div>
                    <div style={{
                        float: 'right',
                        marginRight: '20px',
                        marginTop: '10px'
                    }}
                    >
                        <Button
                            type='primary'
                            shape='circle'
                            size='large'
                            loading={loadingLocation}
                            icon={<AimOutlined />}
                            onClick={() => { onLoadLocation() }}
                        />
                        <br />
                        <Button
                            type='primary'
                            shape='circle'
                            size='large'
                            icon={<SwapOutlined />}
                            style={{
                                marginTop: '5px'
                            }}
                            //icon={<EyeOutlined />}
                            onClick={() => { setTrafficLayer(!trafficLayer) }}
                        />
                        <br />
                        <Button
                            disabled={ordersInVehicleTemp.length <= 0 || fetching}
                            type='primary'
                            shape='circle'
                            size='large'
                            icon={<NodeIndexOutlined />}
                            style={{
                                marginTop: '5px'
                            }}
                            //icon={<EyeOutlined />}
                            onClick={() => { 
                                setFetching(true);
                                onCalcRoute();
                                setFetching(false);
                             }}
                        />
                        <br />
                        <Button
                            type='primary'
                            shape='circle'
                            size='large'
                            icon={selectionMode ? <HeatMapOutlined /> : <BorderOutlined />}
                            style={{
                                marginTop: '5px'
                            }}
                            onClick={() => { 
                                setSelectionMode(current => !current);
                                props.clearOIV();
                                props.clearOrders();
                             }}
                        />

                    </div>
                    {loadingLocation
                        ? <Spin
                            tip='Localizando...'
                            size="large"
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                WebkitTransform: 'translate(-50%, -50%)',
                                transform: 'translate(-50%, -50%)'
                            }}
                        />
                        : <div></div>
                    }
                    
                    {mapMarkers}

                
                    {polylines.length > 0
                        ? (polylines.map((polyline) => {
                            return (
                                <Polyline
                                    key={polyline.polylineId}
                                    path={polyline.pathCoords}
                                    options={{
                                        strokeColor: "#000000",
                                        strokeOpacity: 0.75,
                                        strokeWeight: 3,
                                    }}
                                />
                            )
                        }))
                        : null
                    }

                    {trafficLayer && <TrafficLayer />}

                    {props.children}

                </div>

                {selectedPin
                    ? <InfoWindow
                       
                        position={{ lat: selectedPin.lat, lng: selectedPin.lng }}
                        onCloseClick={() => {
                            setSelectedPin(null);
                        }}
                        on
                    >
                            <Col span={24}>
                                <Row gutter={[0, 0]}  className={`orders-list-screen-info-window-container`}>
                                    <Col span={11}>
                                        <Row className="orders-list-screen-list-item-label-1">{(selectedPin.orderItem.orderNumber || 'n/a').toUpperCase()}</Row>
                                        <Row className="orders-list-screen-list-item-label-2">{selectedPin.orderItem.address} {selectedPin.orderItem.addressComplement}</Row>
                                    </Col>
                                    <Col span={11}>
                                        <Row className="orders-list-screen-list-item-label-3">{selectedPin.orderItem.custFullName}</Row>
                                        <Row className="orders-list-screen-list-item-label-4">{format_number(selectedPin.orderItem.weight || selectedPin.orderItem.orderWeight)}Kg</Row>
                                        <Row className="orders-list-screen-list-item-label-5"><div className="orders-list-screen-list-state" style={{backgroundColor: selectedPin.orderItem.color, width: '16px', borderRadius: '4px', marginRight: '4px'}}></div>{selectedPin.orderItem.sector}</Row>
                                    </Col>
                                    <Col span={2}>
                                        <Row className="orders-list-screen-list-item-label-7">{selectedPin.order}</Row>
                                    </Col>
                                </Row>
                            </Col>
                         
                    </InfoWindow>
                    : null
                }

                {sectors.length > 0 && sectors.map((item) => {
                    
                    item.options.fillColor="transparent";
                    item.options.strokeColor="var(--primaryColor)";
                    return <Polygon key={item.id} paths={item.sectorPolygons} options={item.options} />
                })}

            </GoogleMap>
           
        </div>
    );
};

import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import es_ES from "antd/lib/locale/es_ES";

import { UserProvider } from "./context/UserContext";

import "antd/dist/antd.css";
import "./index.css";

import { AppRoute } from "./routers/AppRoute";

ReactDOM.render(
  <UserProvider>
    <ConfigProvider locale={es_ES}>
      <AppRoute />
    </ConfigProvider>
  </UserProvider>,
  document.getElementById("root")
);

import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Switch,
  Descriptions,
  Input,
  Card,
  Button,
  Space,
  Skeleton,
  Col,
  Row,
  Modal,
} from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  handleSave,
  handleDelete,
  handleEdit,
  handleClear,
  getColumnSearchPropsText,
} from "./UseCases";
import localStorageService from "../../../../../services/localStorageService";

import {
  fetchIncidents,
  insertIncident,
  updateIncident,
  deleteIncident,
} from "../../queries/incidentsQueries";

import { IncidentsScreenIndicator } from "./IncidentsScreenIndicator";
import { ConfigurationMoreSettings } from "../../../ConfigurationMoreSettings";

const companyId = localStorageService.get("companyId");

export const IncidentsScreen = () => {
  const [getIncidents, dataIncidents] = useLazyQuery(fetchIncidents, {
    variables: { companyId },
    fetchPolicy: "no-cache",
  });
  const [insIncident] = useMutation(insertIncident, {
    variables: { companyId },
    fetchPolicy: "no-cache",
  });
  const [delIncident] = useMutation(deleteIncident, {
    fetchPolicy: "no-cache",
  });
  const [updIncident] = useMutation(updateIncident, {
    fetchPolicy: "no-cache",
  });
  const [clickedRow, setClickedRow] = useState("");

  const [incidents, setIncidents] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getIncidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIncidents([]);

    if (
      !dataIncidents.loading &&
      dataIncidents.data &&
      dataIncidents.data.incidents.length > 0
    ) {
      dataIncidents.data.incidents.map((item) => {
        const deleteable = item.causes_aggregate.aggregate.count === 0;
        const { id, description, enabled } = item;

        return setIncidents((current) => [
          ...current,
          {
            id,
            description,
            enabled,
            deleteable,
          },
        ]);
      });
    }
  }, [dataIncidents.loading, dataIncidents.data]);

  const columns = [
    {
      title: "Descripción",
      key: "description",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      ...getColumnSearchPropsText("Descripción", "description"),
    },
    {
      title: "Habilitado",
      key: "enabled",
      dataIndex: "enabled",
      align: "center",
      sorter: {
        compare: (a, b) => a.enabled - b.enabled,
      },
      render: (value) => <Switch checked={value} disabled />,
    },
  ];
  const formItemLayout = {
    labelCol: {
      lg: { span: 5 },
    },
    wrapperCol: {
      lg: { span: 15 },
    },
  };
  return (
    <Col className="children-inner-container-with-bg-col">
      <ConfigurationMoreSettings />

      <Row className="children-inner-row">
        <Col span={5} className="children-inner-col">
          <IncidentsScreenIndicator
            value={incidents.length}
            label={"Total Incidentes"}
          >
            <CarOutlined
              style={{ backgroundColor: "var(--primaryColor)" }}
              className="report-indicator-icon"
            />
          </IncidentsScreenIndicator>
        </Col>
        <Col span={5} className="children-inner-col">
          <IncidentsScreenIndicator
            value={incidents.filter((item) => item.enabled).length}
            label={"Incidentes Activos"}
          >
            <CheckOutlined
              style={{ backgroundColor: "#9ccc65" }}
              className="report-indicator-icon"
            />
          </IncidentsScreenIndicator>
        </Col>
        <Col span={5} className="children-inner-col">
          <IncidentsScreenIndicator
            value={incidents.filter((item) => !item.enabled).length}
            label={"Incidentes Inactivos"}
          >
            <ClockCircleOutlined
              style={{ backgroundColor: "#ffba57" }}
              className="report-indicator-icon"
            />
          </IncidentsScreenIndicator>
        </Col>
        <Col span={3} offset={3} className="children-inner-col">
          <Button
            type="primary"
            size={"large"}
            className="children-inner-button"
            icon={<EditOutlined />}
            onClick={showModal}
          >
            {clickedRow.length === 0 ? "Crear" : "Editar"}
          </Button>
        </Col>
        <Col span={3} className="children-inner-col">
          <Button
            type="primary"
            size={"large"}
            className="children-inner-button"
            icon={<DeleteFilled />}
            onClick={() =>
              handleDelete(
                clickedRow,
                delIncident,
                incidents,
                setIncidents,
                setClickedRow
              )
            }
            disabled={clickedRow.length === 0}
          >
            Eliminar
          </Button>
        </Col>
      </Row>
      <Row>
        <Skeleton loading={dataIncidents.loading} active>
          <Table
            className="children-table-global"
            columns={columns}
            dataSource={incidents}
            size="small"
            rowKey="id"
            pagination={{ pageSize: 13 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (clickedRow.length === 0 || clickedRow !== record.id) {
                    setClickedRow(record.id);
                    handleEdit(record, setIsEditing, form);
                  } else {
                    handleClear(setIsEditing, form);
                    setClickedRow("");
                  }
                },
              };
            }}
            rowClassName={(record) => [
              "mouse-row-enter",
              record.id === clickedRow ? "table-row-color" : "",
            ]}
          />
        </Skeleton>
      </Row>
      <Modal
        className="children-modal"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[]}
        title={clickedRow.length === 0 ? "Crear Incidente" : "Editar Incidente"}
        width={500}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="configuration"
          onFinish={(values) => {
            handleSave(
              values,
              insIncident,
              updIncident,
              incidents,
              setIncidents,
              isEditing,
              setIsEditing,
              form
            );
            setClickedRow("");
          }}
          initialValues={{
            id: "",
            description: "",
            enabled: true,
            deleteable: true,
          }}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="Descripción"
            name="description"
            rules={[
              { required: true, message: "Por favor ingrese una descripción" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Habilitado" name="enabled" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                onClick={handleCancel}
              >
                {isEditing ? "Modificar" : "Agregar"}
              </Button>
              <Button
                type="ghost"
                shape="round"
                onClick={() => {
                  handleClear(setIsEditing, form);
                  setClickedRow("");
                }}
              >
                Cancelar
              </Button>
            </Space>
          </Form.Item>
          <Form.Item name="deleteable" hidden valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </Col>
  );
};

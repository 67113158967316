import React, { useState, useEffect } from 'react';
import { useLazyQuery  } from '@apollo/client';
import { Row, Col, Select, Form } from 'antd';

import { MapScreen } from './MapScreen';
import { UsersVehiclesList } from './UsersVehiclesList';
import { OrdersInVehicleList } from './OrdersInVehicleList';
import { OrdersList } from './OrdersList';

import { useScheduling }  from '../../context/SchedulingContext';
import { SchedulingProvider }  from '../../context/SchedulingContext';
import localStorageService from '../../../../services/localStorageService';
import '../styles/SchedulingScreen.css';

export const SchedulingScreen = (props) => {
    const { setSelectedUserVehicle, setOrdersInVehicleTemp, setOrders, setToRightButtonDisabled, setSelectedLeftRow, setToLeftButtonDisabled, setSelectedRightRow, setFlag } = useScheduling();
    const [form] = Form.useForm();
    const [collapseText, setCollapseText] = useState('Ocultar panel');
    const [viewMode, setViewMode] = useState(2);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [selectedRowKeysOIV, setSelectedRowKeysOIV] = useState([]);
    const [selectedRowsOIV, setSelectedRowsOIV] = useState([]);

    const [filter, setFilter] = useState('');
    const [searchComponent, setSearchComponent] = useState(false);

    const [activeFilter1, setActiveFilter1] = useState(true);
    const [activeFilter2, setActiveFilter2] = useState(false);
    const [activeFilter3, setActiveFilter3] = useState(false);

    useEffect(() => {
        setSelectedUserVehicle({});
        setOrdersInVehicleTemp([]);
        setOrders([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFlag(props.flag);
        setFilter(localStorage.getItem('searchTarget') && localStorage.getItem('searchTarget').length > 0 ? localStorage.getItem('searchTarget') : '');
        setSearchComponent(localStorage.getItem('searchComponent') && localStorage.getItem('searchComponent') === "true");
    }, [props.flag]);

    const removeItem = item => {
        let mSelectedRowKeys = selectedRowKeys.map(element => element);
        let mSelectedRows = selectedRows.map(element => element);

        const index = mSelectedRows.indexOf(item, 0);

        mSelectedRowKeys.splice(index, 1);
        mSelectedRows.splice(index, 1);

        setSelectedRowKeys(mSelectedRowKeys);
        setSelectedRows(mSelectedRows);

        if (mSelectedRows.length > 0) {
            setToLeftButtonDisabled(false);
            setSelectedRightRow(mSelectedRows);
        } else {
            setToLeftButtonDisabled(true);
            setSelectedRightRow([]);
        }
    };

    const addItem = item =>{
        console.log(selectedRows,item)
        let mSelectedRowKeys = selectedRowKeys.map(element => element);
        let mSelectedRows = selectedRows.map(element => element);
        console.log(selectedRows)
        mSelectedRowKeys.push(item.id);
        mSelectedRows.push(item);

        setSelectedRowKeys(mSelectedRowKeys);
        setSelectedRows(mSelectedRows);

        if (mSelectedRows.length > 0) {
            setToLeftButtonDisabled(false);
            setSelectedRightRow(mSelectedRows);
        } else {
            setToLeftButtonDisabled(true);
            setSelectedRightRow([]);
        }
    }

    const addAll = items =>{
        let mSelectedRowKeys = selectedRowKeys.map(element => element);
        let mSelectedRows = selectedRows.map(element => element);
        
        mSelectedRowKeys = items.map(item => item.id);
        mSelectedRows = items;

        setSelectedRowKeys(mSelectedRowKeys);
        setSelectedRows(mSelectedRows);

        if (mSelectedRows.length > 0) {
            setToLeftButtonDisabled(false);
            setSelectedRightRow(mSelectedRows);
        } else {
            setToLeftButtonDisabled(true);
            setSelectedRightRow([]);
        }
    }

    const removeItemOIV = item => {
        let mSelectedRowKeysOIV = selectedRowKeysOIV.map(element => element);
        let mSelectedRowsOIV = selectedRowsOIV.map(element => element);
        
        const index = mSelectedRowsOIV.indexOf(item, 0);

        mSelectedRowKeysOIV.splice(index, 1);
        mSelectedRowsOIV.splice(index, 1);

        setSelectedRowKeysOIV(mSelectedRowKeysOIV);
        setSelectedRowsOIV(mSelectedRowsOIV);

        if (mSelectedRowsOIV.length > 0) {
            setToRightButtonDisabled(false);
            setSelectedLeftRow(mSelectedRowsOIV);
        } else {
            setToRightButtonDisabled(true);
            setSelectedLeftRow([]);
        }
    };

    const addItemOIV = item =>{
        let mSelectedRowKeysOIV = selectedRowKeysOIV.map(element => element);
        let mSelectedRowsOIV = selectedRowsOIV.map(element => element);
        
        mSelectedRowKeysOIV.push(item.id);
        mSelectedRowsOIV.push(item);

        setSelectedRowKeysOIV(mSelectedRowKeysOIV);
        setSelectedRowsOIV(mSelectedRowsOIV);

        if (mSelectedRowsOIV.length > 0) {
            setToRightButtonDisabled(false);
            setSelectedLeftRow(mSelectedRowsOIV);
        } else {
            setToRightButtonDisabled(true);
            setSelectedLeftRow([]);
        }
    }

    const addAllOIV = items =>{
        let mSelectedRowKeysOIV = selectedRowKeysOIV.map(element => element);
        let mSelectedRowsOIV = selectedRowsOIV.map(element => element);
        
        mSelectedRowKeysOIV = items.map(item => item.id);
        mSelectedRowsOIV = items;

        setSelectedRowKeysOIV(mSelectedRowKeysOIV);
        setSelectedRowsOIV(mSelectedRowsOIV);

        if (mSelectedRowsOIV.length > 0) {
            setToRightButtonDisabled(false);
            setSelectedLeftRow(mSelectedRowsOIV);
        } else {
            setToRightButtonDisabled(true);
            setSelectedLeftRow([]);
        }
    }

    const clearOIV = () =>{
        setSelectedRowsOIV([]);
        setSelectedRowKeysOIV([]);
        setToRightButtonDisabled(true);
        setSelectedLeftRow([]);
    }

    const clearOrders = () =>{
        setSelectedRows([]);
        setSelectedRowKeys([]);
        setToLeftButtonDisabled(true);
        setSelectedRightRow([]);        
    }
    /*const DefaultCenterId = async () =>{
        form.setFieldsValue({
            CenterId: 
          });
    }
    */

    return (
        <div>
            <Row>
                <Col span={24}>
                    <MapScreen
                        removeItem={removeItem}
                        addItem={addItem}
                        removeItemOIV={removeItemOIV}
                        addItemOIV={addItemOIV}
                        selectedRowKeysOIV={selectedRowKeysOIV}
                        selectedRowKeys={selectedRowKeys}
                        selectedRowsOIV={selectedRowsOIV}
                        clearOIV={clearOIV}
                        clearOrders={clearOrders}
                        children={
                            <section className="scheduling-screen-container">
                                <Row gutter={[8, 8]}>
                                    <Col span={6} className={`scheduling-screen-inner-col-container1`}>
                                    {searchComponent ? <Row gutter={[24, 24]} style={{textAlign: 'center !important', marginBottom: '8px'}} >
                                            <Col span={8}><div className="orders-list-screen-list-state" style={{width:'100%', backgroundColor: activeFilter1 ? '#343a40' : 'transparent', color: activeFilter1 ? 'white' : 'black', border: activeFilter1 ? 'none' : 'solid 1px black', borderRadius: '30px'}} onClick={() => setActiveFilter1(current => !current)}>Vehículos</div></Col>
                                            <Col span={8}><div className="orders-list-screen-list-state" style={{width:'100%',backgroundColor: activeFilter2 ? '#9ccc65' : 'transparent', color: activeFilter2 ? 'white' : 'black', border: activeFilter2 ? 'none' : 'solid 1px black', borderRadius: '30px'}} onClick={() => setActiveFilter2(current => !current)}>En Vehículo</div></Col>
                                            <Col span={8}><div className="orders-list-screen-list-state" style={{width:'100%', backgroundColor: activeFilter3 ? 'var(--primaryColor)' : 'transparent', color: activeFilter3 ? 'white' : 'black', border: activeFilter3 ? 'none' : 'solid 1px black', borderRadius: '30px'}} onClick={() => setActiveFilter3(current => !current)}>Ordenes</div></Col>
                                        </Row> : null}
                                        <Row gutter={[0, 0]} className={`scheduling-screen-inner-container12 ${viewMode === 1 ? "scheduling-screen-inner-container14" : "scheduling-screen-inner-container151"}`} >
                                            <UsersVehiclesList 
                                                filter={filter} 
                                                clearOIV={clearOIV}
                                                clearOrders={clearOrders}
                                                onItemClickOn={() =>setViewMode(1)} 
                                                onItemClickOff={() =>setViewMode(2)}
                                                activeFilter1={activeFilter1}
                                                Flag = {props.flag}
                                            />
                                            
                                        </Row>
                                        <Row gutter={[0, 0]} className={`scheduling-screen-inner-container13 ${viewMode === 2 ? "scheduling-screen-inner-container16" : "scheduling-screen-inner-container152"}`} >
                                            <OrdersInVehicleList 
                                                onItemClick={() =>setViewMode(2)}
                                                selectedRowKeysOIV={selectedRowKeysOIV} 
                                                setSelectedRowKeysOIV={setSelectedRowKeysOIV}
                                                selectedRowsOIV={selectedRowsOIV}
                                                setSelectedRowsOIV={setSelectedRowsOIV}
                                                removeItemOIV={removeItemOIV}
                                                addItemOIV={addItemOIV}
                                                clearOIV={clearOIV}
                                                addAllOIV={addAllOIV}
                                                filter={filter}
                                                activeFilter2={activeFilter2}
                                            /> 
                                        </Row>
                                        <Row gutter={[0, 0]} className={`scheduling-screen-inner-container13 ${viewMode === 3 ? "scheduling-screen-inner-container17" : "scheduling-screen-inner-container153"}`} >
                                            <OrdersList 
                                                onItemClick={() =>setViewMode(3)}
                                                selectedRowKeys={selectedRowKeys} 
                                                setSelectedRowKeys={setSelectedRowKeys}
                                                selectedRows={selectedRows}
                                                setSelectedRows={setSelectedRows}
                                                removeItem={removeItem}
                                                addAll={addAll}
                                                addItem={addItem}
                                                clearOrders={clearOrders}
                                                filter={filter}
                                                activeFilter3={activeFilter3}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </section>
                        }
                    />
                </Col>
            </Row>
        </div>
    );
}
import React from "react";
import { useEffect } from "react";
import { Pagination, Stack, Select, MenuItem } from "@mui/material";
import LoadCard from "./CardCarga";
import "../../stylesTMS/styles-tms.css";
import { ReactComponent as FilterIcon } from "../../../../../../../images/filter_alt_off_FILL0_wght400_GRAD0_opsz24.svg";

const PaginatedComponent = ({
  totalRecords,
  currentPage,
  setCurrentPage,
  recordsPerPage,
  setRecordsPerPage,
  setPageData,
  handleSelectLoad,
  pageData,
  selecteLoadCarga,
  tmsLoad,
  setSelecteLoadCarga,
  setSelecteShipping,
  setSelectedShippingId,
  setShowDetail,
  setIsDetailExpanded,
}) => {
  const [totalPages, setTotalPages] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setSelecteLoadCarga(null);
    setSelecteShipping(null);
    setSelectedShippingId(null);
    setShowDetail(false);
    setIsDetailExpanded(false);
    setCurrentPage(newPage);
    console.log("newPage", newPage);
    tmsLoad("", "", recordsPerPage, newPage);
  };

  const handleChangeRecordsPerPage = (event) => {
    setRecordsPerPage(event.target.value);
    setCurrentPage(1);
    tmsLoad("", "", event.target.value, 1);
    console.log("event.target.value", event.target.value);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / recordsPerPage));
  }, [totalRecords, recordsPerPage]);
  console.log("totalPages", totalPages);

  return (
    <>
      <div className="my-pagination-controls">
        <h1 className="titulo">Listado de cargas</h1>
        <div className="pagination-container-padre">
          <Stack spacing={2} className="my-pagination" direction="row">
            <div className="pagination">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
                classes={{ ul: "my-pagination-ul" }}
              />
            </div>
            <div className="pagination-select">
              <Select
                value={recordsPerPage}
                onChange={handleChangeRecordsPerPage}
                displayEmpty
                className="my-select"
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </div>
          </Stack>
          <div
            className={`button-filter-icon-cargas ${
              selecteLoadCarga ? "" : "hidden"
            }`}
            onClick={handleSelectLoad}
          >
            <FilterIcon className="filter-icon-cargas" />
          </div>
        </div>
      </div>
      <div className="load-cards-container">
        {pageData.map((carga) => (
          <LoadCard
            key={carga.$id}
            carga={carga}
            onClick={() => handleSelectLoad(carga)}
            isSelected={carga.LoadId === selecteLoadCarga?.LoadId}
          />
        ))}
      </div>
    </>
  );
};

export default PaginatedComponent;
